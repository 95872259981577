import React from 'react';
import useMediaQuery from '@veneer/core/dist/scripts/utils/use_media_query';
import { WideCard } from '../WideCard';
import { YearlyPlansCard } from '../YearlyPlansCard';
import { useGetText } from '../../../common/hooks/useGetText';
import { Carousel } from '../Carousel';
import { PlanCardContainer, Title, BoldText } from './styles';
import { breakpointsHelper } from '../../../common/utils/breakpointsHelper';
import { useYearlyPlanSelectorState } from '../../hooks/useYearlyPlanSelectorState';

export const AllYearlyPlansSelector: React.FC = () => {
  const getText = useGetText();
  const { plans, yearlyPlans, correspondingYearlyPlan, currentPlan } =
    useYearlyPlanSelectorState();
  const isTablet = useMediaQuery(
    `(max-width: ${breakpointsHelper.medium_min_768px})`
  );
  const isMobile = useMediaQuery(
    `(max-width: ${breakpointsHelper.extra_small_575px})`
  );

  const findCorrespondingPlan = (pages) => {
    return plans.find((plan) => plan.pages * 12 === pages);
  };

  const PlanCards = () => {
    return (
      <PlanCardContainer data-testid="cards-container">
        {yearlyPlans.map((yearlyPlan, index) => {
          const monthlyPlan = findCorrespondingPlan(yearlyPlan.pages);
          if (monthlyPlan) {
            return (
              <YearlyPlansCard
                key={index}
                name={monthlyPlan?.frequency}
                numberOfCircles={index + 1}
                yearlyPlan={yearlyPlan}
                pricePerYear={monthlyPlan.yearlyPrice}
                hasTag={yearlyPlan.guid === correspondingYearlyPlan.guid}
              />
            );
          }
        })}
      </PlanCardContainer>
    );
  };

  return (
    <div data-testid="yearly-plan-selector-select-yearly-step">
      <Title>
        {getText('plans_selector.yearly.modals.all_yearly_plans.title')}
      </Title>
      <WideCard
        pagesPerMonth={currentPlan.plan.pages.toString()}
        pricePerMonth={currentPlan.plan.price}
      />
      {isTablet ? (
        <PlanCards />
      ) : (
        <Carousel>
          <PlanCards />
        </Carousel>
      )}
      <p>
        <BoldText $isMobile={isMobile}>
          {getText('plans_selector.yearly.modals.all_yearly_plans.print_more')}
          &nbsp;
        </BoldText>
        {getText('plans_selector.yearly.modals.all_yearly_plans.renew_plan')}
      </p>
    </div>
  );
};
