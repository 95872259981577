import React from 'react';
import { PlanCard } from '../PlanCard';
import { CompareCardContainer } from './styles';
import { useYearlyPlanSelectorState } from '../../hooks/useYearlyPlanSelectorState';

export const SwitchMonthlyToYearlySection: React.FC = () => {
  const { correspondingYearlyPlan, currentPlan } = useYearlyPlanSelectorState();

  return (
    <>
      <CompareCardContainer data-testid="yearly-plan-compare-card-container">
        <PlanCard
          planName={currentPlan?.plan?.frequency}
          pagesTotal={currentPlan?.plan?.pages}
          pricesPage={currentPlan?.plan?.price}
          circlesNumber={currentPlan?.index + 1}
        />
        <PlanCard
          planName={currentPlan?.plan?.frequency}
          pagesTotal={correspondingYearlyPlan?.pages}
          pricesPage={correspondingYearlyPlan?.price}
          circlesNumber={currentPlan?.index + 1}
          isYearly={true}
          yearlyPrice={currentPlan?.plan?.yearlyPrice}
        />
      </CompareCardContainer>
    </>
  );
};
