import { useSignupSession } from './useSignupSession'
import { useSignupState } from './useSignupState'
import { useDispatch } from './useDispatch'
import { updateStateData } from '../stateData/updateStateData'
import { useCallback } from 'react'

export const useConnectAndVerify = () => {
  const dispatch = useDispatch()
  const { partialSubscription } = useSignupState()
  const { instantInkService } = useSignupSession()

  return useCallback(async () => {
    try {
      const {
        data: {
          planMismatch,
          planTechnologyMismatch,
          paperMismatch,
          rafIneligible,
          mnsPoobeInfo,
          updatedPartialSubscription,
          updatedEnrollmentBenefits
        }
      } = await instantInkService.connectAndVerify(
        partialSubscription?.data?.id
      )

      await dispatch(
        updateStateData('partialSubscription', {
          loading: false,
          data: updatedPartialSubscription
        })
      )

      await dispatch(
        updateStateData('benefits', {
          loading: false,
          data: updatedEnrollmentBenefits
        })
      )

      return {
        planMismatch,
        planTechnologyMismatch,
        paperMismatch,
        rafIneligible,
        mnsPoobeInfo
      }
    } catch (e) {
      //
    }
  }, [dispatch, partialSubscription, instantInkService])
}
