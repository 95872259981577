import React from 'react'
import Lottie from 'react-lottie'
import lottieSpinner from './lottieSpinner.json'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
import { useGetText, useShippingFormState } from '../../hooks'
import * as Styled from './styles'

export function Spinner(): JSX.Element {
  const getText = useGetText()
  const { isHpx } = useShippingFormState()

  if (isHpx) {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: lottieSpinner,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }

    return (
      <Styled.LoadingHpx data-testid="lottie-spinner">
        <Lottie options={defaultOptions} height={72} width={72} />
        <span>{getText('shipping_form.loading')}</span>
      </Styled.LoadingHpx>
    )
  }

  return (
    <Styled.Loading data-testid="spinner">
      <ProgressIndicator />
      <span>{getText('shipping_form.loading')}</span>
    </Styled.Loading>
  )
}
