import React, { useState, useEffect, useRef } from 'react';
import * as S from './styles';
import SelectItems from './SelectItems';
import { listDevicePosition, listImageSize } from './listV1Types';
import { Button, CustomImage, IconDocument, TextBox } from '@veneer/core';
import { ImageDescriptor } from './interface';
import MessagePattern from '../MessagePattern';
import { NorthboundImageType } from './types';
import axios from 'axios';

const GetImageDescriptor: React.FC<NorthboundImageType> = ({ v1, v1Types }) => {
  const [dataNB, setDataNB] = useState<ImageDescriptor>();
  const [messageDB, setMessageDB] = useState<string>('');
  const [messageType, setMessageType] = useState<string>('');
  const [skuNB, setSkuNB] = useState<string>('');
  const [valueDevicePosition, setValueDevicePosition] = useState([]);
  const [valueImageSize, setValueImageSize] = useState([]);
  const isMounted = useRef(true);

  const linkToDoc =
    'https://pages.github.azc.ext.hp.com/jarvis-shell/shell-docs/nbapis/apis/imageNorthboundApi/';

  const verifyDeviceName = async (deviceName: string) => {
    const response = await axios({
      url: `https://static.hpsmart.com/clientos/assets/latest/devices/${deviceName}/descriptor.json`,
      method: 'GET',
      proxy: {
        protocol: 'http',
        host: 'manual-proxy.us.hpicorp.net',
        port: 8080
      },
      validateStatus(status) {
        return status >= 200 && status < 600;
      }
    });
    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  };

  const verifyFieldEmpty = () => {
    const skuTemp = skuNB == '';
    const positionTemp = valueDevicePosition[0] == undefined;
    const sizeTemp = valueImageSize[0] == undefined;
    let result: boolean = false;
    if (skuTemp || positionTemp || sizeTemp) {
      result = false;
    } else {
      result = true;
    }
    return result;
  };

  const getImageDescriptor = async () => {
    setDataNB(undefined);
    setMessageDB('');
    let response: ImageDescriptor;
    if (!verifyFieldEmpty()) {
      setMessageDB('Please, verify the fields empty');
      setMessageType('Warn');
    } else {
      const verifySku = await verifyDeviceName(skuNB);
      if (verifySku) {
        response = await v1?.getImageDescriptor({
          sku: String(skuNB).toUpperCase(),
          position: valueDevicePosition[0],
          size: valueImageSize[0]
        });
        if (isMounted.current) {
          setDataNB(response);
        }
      } else {
        if (isMounted.current) {
          setMessageDB('Please type the SKU correctly');
          setMessageType('Caution');
        }
      }
    }
  };

  const styleTemp = {
    width: `${dataNB?.data?.width}px`,
    height: `${dataNB?.data?.height}px`,
    right: `${dataNB?.data?.right}px`,
    top: `${dataNB?.data?.top}px`,
    'flex-wrap': 'wrap'
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <S.formContainer>
      <S.buttonActionContainer>
        <S.buttonAction>
          <Button
            onClick={getImageDescriptor}
            small
          >
            Get Image Descriptor
          </Button>
        </S.buttonAction>
      </S.buttonActionContainer>
      <S.container>
        <S.textConatiner>
          <TextBox
            label={'Type the sku'}
            helperText="Example: 1G5L3A"
            value={skuNB}
            onChange={(e) => setSkuNB(e)}
          />
        </S.textConatiner>
        <S.containerSelect>
          <S.selectFacingContainer>
            <SelectItems
              id="select-image-position"
              label="Facing"
              items={listDevicePosition(v1Types)}
              setMessage={setValueDevicePosition}
            />
          </S.selectFacingContainer>
          <S.selectSizeContainer>
            <SelectItems
              id="select-image-size"
              label="Size"
              items={listImageSize(v1Types)}
              setMessage={setValueImageSize}
            />
          </S.selectSizeContainer>
        </S.containerSelect>
      </S.container>
      {messageDB ? (
        <MessagePattern
          message={messageDB}
          messageType={messageType}
          setMessage={setMessageDB}
        />
      ) : (
        <></>
      )}
      <S.containerResult>
        {dataNB ? (
          <S.imageContainer>
            <p>Name: {dataNB?.data?.name}</p>
            <p>width: {dataNB?.data?.width}</p>
            <p>height: {dataNB?.data?.height}</p>
            <p>right: {dataNB?.data?.right}</p>
            <p>top: {dataNB?.data?.top}</p>
            <S.customImageContainer>
              <CustomImage
                style={styleTemp}
                src={dataNB?.data?.uri}
              />
            </S.customImageContainer>
          </S.imageContainer>
        ) : (
          <></>
        )}
      </S.containerResult>
      <S.documentContainer>
        <Button
          appearance="ghost"
          colorScheme="positive"
          leadingIcon={<IconDocument />}
          onClick={() => window.open(linkToDoc, '_blank')}
        >
          Image Northbound API Documentation
        </Button>
      </S.documentContainer>
    </S.formContainer>
  );
};

export default GetImageDescriptor;
