import Button from '@veneer/core/dist/scripts/button/button'
import React, { useContext } from 'react'
import useContentStack from '@/hooks/useContentStack'
import { ANALYTICS, CONTENT_STACK_TYPES, SMB_DOMAINS } from '@/store/Constants'
import {
  ButtonsFooter,
  PageHeader,
  PrinterView
} from '@/components/UI/Smallville'
import useAnalytics from '@/hooks/useAnalytics'
import { ConfigContext } from '@/store'
import { useNextStage } from '@/hooks/useNextStage'

import classes from './HPSmartAdminValuePropPage.module.scss'

export function HPSmartAdminValuePropPage() {
  const { stack, isBinaryApp } = useContext(ConfigContext)
  const { dispatchSuccessStage } = useNextStage()
  const { pageData } = useContentStack({
    content_type: CONTENT_STACK_TYPES.hp_smart_admin_value_prop_app
  })

  const { fireButtonClick, fireScreenDisplayed } = useAnalytics(
    ANALYTICS.SCREENS.SMART_ADMIN_VALUE_PROP
  )

  const handleOpenDashboard = () => {
    fireButtonClick(ANALYTICS.BUTTONS.HP_SMART_ADMIN)
    if (!isBinaryApp) {
      window.location.href = SMB_DOMAINS[stack]
    } else {
      window.open(SMB_DOMAINS[stack], '_blank')
      dispatchSuccessStage()
    }
  }

  if (!pageData) return <></>

  fireScreenDisplayed()

  return (
    <div data-testid="hp-smart-admin-vp-page">
      <PageHeader header={pageData.header} description={pageData.subheader} />
      <div className={classes.contentContainer}>
        <PrinterView checkmark />
      </div>
      <ButtonsFooter>
        <Button onClick={handleOpenDashboard}>{pageData.button_text}</Button>
      </ButtonsFooter>
    </div>
  )
}
