import { JarvisAuthProvider } from '@jarvis/web-http'
import { JarvisWebHttpClient } from '@jarvis/web-http/dist/jarvis-web-http-client'
import { BaseURLProvider, Stack } from '@jarvis/web-stratus-client'
import { AxiosResponse } from 'axios'
import {
  BillingAddress,
  BillingInfo,
  ShippingAddress,
  Settings,
  TaxpayerDetails,
  CriticalScopes
} from '../types'

export interface IBillingService {
  getCriticalScopes(): Promise<AxiosResponse<CriticalScopes>>
  getSettings(): Promise<AxiosResponse<Settings>>
  getBillingInfo(): Promise<AxiosResponse<BillingInfo>>
  getTaxpayerDetails(): Promise<AxiosResponse<TaxpayerDetails>>
  saveTaxpayerDetails(
    data: TaxpayerDetails
  ): Promise<AxiosResponse<TaxpayerDetails>>
  getBillingAddress(): Promise<AxiosResponse<BillingAddress>>
  saveBillingAddress(
    address: BillingAddress
  ): Promise<AxiosResponse<BillingAddress>>
  getShippingAddress(): Promise<AxiosResponse<ShippingAddress>>
  getPhcEvent(orderId?: string, pickupId?: string): Promise<AxiosResponse>
  createPhcEvent(): Promise<AxiosResponse>
  getPayPalToken(
    accountIdentifier?: string,
    appName?: string
  ): Promise<AxiosResponse>
  createPayPalAgreement(accountIdentifier?: string): Promise<AxiosResponse>
}

export class BillingService implements IBillingService {
  httpClient: JarvisWebHttpClient
  xCorrelationId?: string
  apiName = 'comfe'
  apiVersion = '1'
  criticalScopesEndpoint = '/critical_scopes'
  settingsEndpoint = '/settings'
  billingEndpoint = '/billing'
  taxpayerDetailsEndpoint = `${this.billingEndpoint}/taxpayer_details`
  billingAddressEndpoint = `${this.billingEndpoint}/address`
  phcEventEndpoint = `${this.billingEndpoint}/phc_event`
  payPalEndpoint = `${this.billingEndpoint}/paypal`
  shippingEndpoint = `/shipping`
  shippingAddressEndpoint = `${this.shippingEndpoint}/addresses/default`

  constructor(
    authProvider: JarvisAuthProvider,
    stack: Stack,
    baseURLProvider?: BaseURLProvider,
    xCorrelationId?: string
  ) {
    const baseURL = [
      'https://comfe-pie1.instantink.com',
      'https://comfe-pie1.instantink.com',
      'https://comfe-stage1.instantink.com',
      'https://comfe.instantink.com'
    ][stack]
    const defaultBaseURL = baseURLProvider
      ? async () => baseURLProvider(this.apiName, this.apiVersion)
      : `${baseURL}/api/${this.apiName}/v${this.apiVersion}`
    this.httpClient = new JarvisWebHttpClient({
      defaultBaseURL,
      defaultAuthProvider: authProvider
    })
    this.xCorrelationId = xCorrelationId
  }

  getCriticalScopes(): Promise<AxiosResponse<CriticalScopes>> {
    return this.httpClient.get({
      ...this.defaultHeaders(),
      url: this.criticalScopesEndpoint
    })
  }

  getSettings(): Promise<AxiosResponse<Settings>> {
    return this.httpClient.get({
      ...this.defaultHeaders(),
      url: this.settingsEndpoint
    })
  }

  getBillingInfo(): Promise<AxiosResponse<BillingInfo>> {
    return this.httpClient.get({
      ...this.defaultHeaders(),
      url: this.billingEndpoint
    })
  }

  getTaxpayerDetails(): Promise<AxiosResponse<TaxpayerDetails>> {
    return this.httpClient.get({
      ...this.defaultHeaders(),
      url: this.taxpayerDetailsEndpoint
    })
  }

  saveTaxpayerDetails(data: TaxpayerDetails): Promise<AxiosResponse> {
    return this.httpClient.put({
      ...this.defaultHeaders(),
      url: this.taxpayerDetailsEndpoint,
      data
    })
  }

  getBillingAddress(): Promise<AxiosResponse<BillingAddress>> {
    return this.httpClient.get({
      ...this.defaultHeaders(),
      url: this.billingAddressEndpoint
    })
  }

  saveBillingAddress(
    address: BillingAddress
  ): Promise<AxiosResponse<ShippingAddress>> {
    return this.httpClient.put({
      ...this.defaultHeaders(),
      url: this.billingAddressEndpoint,
      data: {
        address
      }
    })
  }

  getShippingAddress(): Promise<AxiosResponse<ShippingAddress>> {
    return this.httpClient.get({
      ...this.defaultHeaders(),
      url: this.shippingAddressEndpoint
    })
  }

  getPhcEvent(orderId?: string, pickupId?: string): Promise<AxiosResponse> {
    return this.httpClient.get({
      ...this.defaultHeaders(),
      url: `${this.phcEventEndpoint}/${orderId}`,
      params: {
        pickupId
      }
    })
  }

  createPhcEvent(): Promise<AxiosResponse> {
    return this.httpClient.post({
      ...this.defaultHeaders(),
      url: this.phcEventEndpoint
    })
  }

  getPayPalToken(
    accountIdentifier?: string,
    appName?: string
  ): Promise<AxiosResponse> {
    return this.httpClient.get({
      ...this.defaultHeaders(),
      url: `${this.payPalEndpoint}/token`,
      params: {
        account_identifier: accountIdentifier,
        app_name: appName
      }
    })
  }

  createPayPalAgreement(accountIdentifier?: string): Promise<AxiosResponse> {
    return this.httpClient.post({
      ...this.defaultHeaders(),
      url: `${this.payPalEndpoint}/agreement`,
      params: {
        account_identifier: accountIdentifier
      }
    })
  }

  private defaultHeaders() {
    if (this.xCorrelationId) {
      return {
        headers: {
          'X-Correlation-ID': this.xCorrelationId
        }
      }
    }
    return {}
  }
}
