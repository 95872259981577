import React, { Suspense, useContext, useEffect, useState } from 'react'
import {
  ConfigContext,
  ErrorContext,
  PrinterContext,
  STAGES,
  UserContext
} from '@/store'
import useDeviceAuthGrantApi from '@/hooks/useDeviceAuthGrantApi'
import PairingCode from '@/components/Pairing/PairingCode'
import TextHeader, { TextSubHeader } from '@/components/UI/TextHeader'
import classes from './PairingCodePage.module.scss'
import { LazyVeneerIcon, IconCheckCircle } from '@/styles/LazyVeneer'
import useAnalytics, { buildDataCollectionMetadata } from '@/hooks/useAnalytics'
import {
  ANALYTICS,
  BIZ_MODEL_HP_PLUS,
  CONTENT_STACK_TYPES
} from '@/store/Constants'
import NextStageButton from '@/components/UI/NextStageButton'
import useContentStack from '@/hooks/useContentStack'
import CantFindPairingCodeModal from '@/components/UI/Modals/CantFindPairingCodeModal'
import { useAnalyticsTrackEvent } from '@/hooks/useAnalyticsTrackEvent'

const { BUTTONS, SCREENS, LINKS } = ANALYTICS
const REDIRECT_TIMEOUT = 3000
const ATTEMPTS_BEFORE_WARNING = 4
const ATTEMPTS_BEFORE_TIMEOUT = 5

const PairingCodePage = ({ hidePairing = null }) => {
  const { onError, setError } = useContext(ErrorContext)
  const configCtx = useContext(ConfigContext)
  const printerCtx = useContext(PrinterContext)
  const { getSessionId } = useContext(UserContext)
  const {
    isFetching,
    isValid,
    errorCode,
    validate,
    clear
  } = useDeviceAuthGrantApi(configCtx.stack, configCtx.authProvider)
  const [code, setCode] = useState('')
  const [attempts, setAttempts] = useState(0)
  const [codeCompleted, setCodeCompleted] = useState(false)
  const [
    showCantFindPairingCodeModal,
    setShowCantFindPairingCodeModal
  ] = useState(false)

  const analyticsEnterPairCode = useAnalytics(SCREENS.ENTER_PAIRING_CODE)
  const analyticsPairCodeSuccess = useAnalytics(SCREENS.PAIRING_CODE_SUCCESS)
  const analyticsPairCodeInlineError = useAnalytics(
    SCREENS.PAIRING_CODE_INLINE_ERROR
  )
  const analyticsCantFindPairingCode = useAnalytics(
    SCREENS.CANT_FIND_PAIRING_CODE
  )

  const getPrinterType = () => {
    if (printerCtx.isSingleSku) return 'single_sku'
    const bizModelHint =
      configCtx.sessionContext.onboardingContext?.bizModelHint
    if (bizModelHint?.toUpperCase() === BIZ_MODEL_HP_PLUS) return 'e2e'
    return 'flex'
  }

  const parseCmsResponse = (data) => ({
    accepted: data.accepted_pairing_text,
    activated: data.activated_printer_text,
    continue: data.continue_button_text,
    subhead: data.description,
    header: data.header,
    notes: data.notes,
    modal: data.can_t_find_pairing_code_modal,
    modals: data.cant_find_pairing_code_modals,
    error_text: data.invalid_code_text,
    code_title: data.pairing_input_label,
    retry_warning: data.retry_warning_text,
    retry_limit: data.retry_limit_text,
    searching: data.searching_printer_text,
    benefits_section: data?.benefits_section
  })

  const { productFamily } = configCtx?.sessionContext?.onboardingContext

  const { pageData } = useContentStack({
    content_type: CONTENT_STACK_TYPES.pairing_code,
    parsing_function: parseCmsResponse,
    additional_params: {
      printer_type: getPrinterType(),
      product_family: productFamily || null
    }
  })

  analyticsEnterPairCode.fireScreenDisplayed()
  const setPairingCode = (pairingCode) => {
    clear()
    setCode(pairingCode)
  }

  const pairingCodeSubmitHandler = () => {
    attempts !== 0 && attempts % ATTEMPTS_BEFORE_TIMEOUT === 0
      ? setAttempts(0)
      : setAttempts((prevState) => prevState + 1)
    analyticsEnterPairCode.fireButtonClick(BUTTONS.CONTINUE)
    setError(null)
    validate(code)
  }

  const cantFindPairingCodeHandler = (e) => {
    const closestAnchor = e.target.closest('a')
    if (closestAnchor && e.currentTarget.contains(closestAnchor)) {
      analyticsEnterPairCode.fireHyperLinkClick(LINKS.CANT_FIND_PAIRING_CODE)
      analyticsCantFindPairingCode.fireModalWindowDisplayed()
      setShowCantFindPairingCodeModal(true)
    }
  }

  const closeCantFindPairingCodeModalHandler = () => {
    analyticsCantFindPairingCode.fireButtonClick(BUTTONS.CLOSE)
    setShowCantFindPairingCodeModal(false)
  }

  const { watchEventByTrackingIdentifier } = useAnalyticsTrackEvent()

  useEffect(() => {
    let timeout = null
    if (isValid) {
      const trackingIdentifier = `success-pairing-code-${new Date().getTime()}`
      watchEventByTrackingIdentifier(trackingIdentifier)
      const metadata = buildDataCollectionMetadata(
        printerCtx.uuid,
        printerCtx.productNumber,
        getSessionId(),
        trackingIdentifier
      )
      analyticsPairCodeSuccess.fireScreenDisplayed(
        {
          name: 'BizModel',
          detail: 'Factory_' + printerCtx.bizModel
        },
        metadata
      )
      timeout = setTimeout(() => {
        if (hidePairing) {
          hidePairing()
          return
        }
        configCtx.nextStage({
          result: 'success',
          output: {
            device: {
              uuid: printerCtx.uuid,
              serialNumber: printerCtx.serialNumber,
              modelName: printerCtx.modelName,
              productNumber: printerCtx.productNumber,
              bizModel: printerCtx.bizModel,
              highestResImgUrl: printerCtx.printerImage,
              offer: printerCtx.offer,
              pls: { deviceCapabilities: printerCtx.deviceCapabilities }
            }
          },
          xCorrelationId: getSessionId()
        })
      }, REDIRECT_TIMEOUT)
    } else if (isValid === false && errorCode) {
      if (errorCode.indexOf('E0888U0022') === -1) {
        // Error other than invalid code
        onError({
          err: errorCode,
          stg: STAGES.pairing,
          onErrorDismissCallback: () =>
            analyticsEnterPairCode.fireScreenDisplayed({ force: true })
        })
        clear()
        return
      }
      setError(errorCode)
      analyticsPairCodeInlineError.fireButtonClick('ContinueButton', {
        detail: errorCode
      })
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [
    isValid,
    configCtx,
    errorCode,
    onError,
    printerCtx,
    getSessionId,
    analyticsPairCodeSuccess,
    hidePairing,
    clear,
    analyticsPairCodeInlineError,
    setError,
    analyticsEnterPairCode
  ])

  if (!pageData) return <></>

  if (isValid) {
    return (
      <>
        <div className={classes.pageContainer}>
          <TextHeader>{pageData.accepted}</TextHeader>
          <Suspense fallback={<></>}>
            <IconCheckCircle
              color="hunterGreen7"
              filled={true}
              size={80}
              className={classes.success}
            />
          </Suspense>
          <TextSubHeader>{pageData.activated}</TextSubHeader>
        </div>
      </>
    )
  }

  let errorText = pageData.error_text
  if (attempts % ATTEMPTS_BEFORE_WARNING === 0) {
    errorText += ` ${pageData.retry_warning}`
  } else if (attempts % ATTEMPTS_BEFORE_TIMEOUT === 0) {
    errorText += ` ${pageData.retry_limit}`
  }

  return (
    <div data-testid="pairing-code-page">
      <CantFindPairingCodeModal
        showModal={showCantFindPairingCodeModal}
        onClose={closeCantFindPairingCodeModalHandler}
        copy={printerCtx.isSingleSku ? pageData.modals : pageData.modal}
      />
      <div className={classes.pageContainer} data-testid={'page-container'}>
        <TextHeader>{pageData.header}</TextHeader>
        <TextSubHeader
          className={classes.containerRoundedListNumberPrimary}
          dangerouslySetInnerHTML={{ __html: pageData.subhead }}
        />
        <div className={classes.pairing}>
          <PairingCode
            codeLength="8"
            onCodeChange={setPairingCode}
            onCodeEmpty={clear}
            setCodeComplete={setCodeCompleted}
            code={code}
            error={isValid === false}
            errorText={errorText}
            codeTitle={pageData.code_title}
          />
        </div>
        <TextSubHeader
          data-testid="cant-find-pairing-code-link"
          onClick={cantFindPairingCodeHandler}
          onContextMenu={(e) => e.preventDefault()}
          className={classes.cantFindPairingCodeText}
          dangerouslySetInnerHTML={{ __html: pageData.notes }}
        />
        {pageData?.benefits_section && (
          <div className={classes.benefitsSection}>
            <h2>{pageData.benefits_section?.header}</h2>
            {pageData.benefits_section?.benefits &&
              pageData.benefits_section.benefits.map((entry, index) => {
                return (
                  <div key={index} className={classes.benefit}>
                    <LazyVeneerIcon icon={entry.veneer_icon.icon} size={32} />
                    <p data-testid={`benefit-${index}`}>{entry.text}</p>
                  </div>
                )
              })}
          </div>
        )}
        {isFetching && (
          <p className={classes.searching}>{pageData.searching}</p>
        )}
      </div>
      <div className={classes.button}>
        <NextStageButton
          copy={pageData.continue}
          onClick={pairingCodeSubmitHandler}
          disabled={!codeCompleted}
          dataTestId="pairing-code-confirm"
        />
      </div>
    </div>
  )
}

export default PairingCodePage
