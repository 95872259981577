// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2m0dkTC8pTsRkd\\+I0ptwpg\\=\\=.P0CT08tA2KqL8bPj3Gd-fg\\=\\={max-width:133px}._2m0dkTC8pTsRkd\\+I0ptwpg\\=\\=.Sh-Y28lwNxUzQ0Hx\\+M1sVQ\\=\\=,._2m0dkTC8pTsRkd\\+I0ptwpg\\=\\=.vGDPiKcQ8cN7A-jmVL\\+j0A\\=\\={max-width:200px}", "",{"version":3,"sources":["webpack://./src/components/ProgressIndicator/AnimationLoader/styles.module.scss"],"names":[],"mappings":"AACC,yDACC,eAAA,CAGD,oHAEC,eAAA","sourcesContent":[".animation-loader {\n\t&.small {\n\t\tmax-width: 133px;\n\t}\n\n\t&.medium,\n\t&.large {\n\t\tmax-width: 200px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"animation-loader": "_2m0dkTC8pTsRkd+I0ptwpg==",
	"small": "P0CT08tA2KqL8bPj3Gd-fg==",
	"medium": "Sh-Y28lwNxUzQ0Hx+M1sVQ==",
	"large": "vGDPiKcQ8cN7A-jmVL+j0A=="
};
export default ___CSS_LOADER_EXPORT___;
