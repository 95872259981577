const baseL10nMap = {
  actionAssignResendInvitation: 'manageUsers.action.resendInvitation',
  actionAssignRole: 'manageUsers.action.assignRole',
  actionAssignSolutions: 'manageUsers.action.assignSolutions',
  actionRemoveUser: 'manageUsers.action.removeUser',
  actionTitle: 'manageUsers.action.title',
  addLabel: 'manageUsers.inviteOverlay.add',
  addUserSelectItem1: 'manageUsers.UserType.selectItem1',
  addUserSelectItem2: 'manageUsers.UserType.selectItem2',
  addUserSelectItem3: 'manageUsers.UserType.selectItem3',
  addUserSelectLabel: 'manageUsers.inviteOverlay.select',
  addUserTabLabel: 'manageUsers.inviteOverlay.tab-label',
  addUserText: 'manageUsers.inviteOverlay.copy_new',
  addUserTextAreaLabel: 'manageUsers.inviteOverlay.entryField',
  assignRoleAdmin: 'manageUsers.assignRole.admin',
  assignRoleCancel: 'manageUsers.assignRole.cancel',
  assignRoleConfirm: 'manageUsers.assignRole.confirm',
  assignRoleDescription: 'manageUsers.assignRole.description',
  assignRoleErrorToast: 'manageUsers.assignRole.errorToast',
  assignRoleErrorToastDefault: 'manageUsers.assignRole.errorToast1',
  assignRoleErrorToastPlural: 'manageUsers.assignRole.errorToastPlural',
  assignRoleErrorToastPluralDefault: 'manageUsers.assignRole.errorToastPlural1',
  assignRoleSelect: 'manageUsers.assignRole.selectRole',
  placeHolderSelect: 'manageUsers.assignRole.selectOption',
  assignRoleSelectSimple: 'manageUsers.assignRole.selectRoleSimple',
  assignRoleSuccessToast: 'manageUsers.assignRole.successToast',
  assignRoleSuccessToastPlural: 'manageUsers.assignRole.successToastPlural',
  assignRoleTitle: 'manageUsers.assignRole.title',
  assignRoleUser: 'manageUsers.assignRole.user',
  assignRoleUserSendLong: 'manageUsers.inviteOverlay.sendLong',
  assignRoleUserTypeAdmin: 'manageUsers.assignRole.userType.admin',
  assignRoleUserTypeUser: 'manageUsers.assignRole.userType.user',
  breadcrumbHome: 'manageUsers.topOfPage.home',
  breadcrumbInviteUsers: 'manageUsers.topOfPage.invite',
  breadcrumbUsers: 'manageUsers.topOfPage.allUsers',
  cancelButton: 'manageUsers.error.list.buttons.cancel',
  cancelLabel: 'manageUsers.error.cancelLabel',
  retryInvite: 'manageUsers.table.retry',
  clickRetry: 'manageUsers.table.clickToRetry',
  columnModalCancel: 'manageUsers.columnModal.cancel',
  columnModalContinue: 'manageUsers.columnModal.continue',
  columnModalReset: 'manageUsers.columnModal.reset',
  columnModalResetECP: 'manageUsers.columnModal.resetECP',
  columnModalRole: 'manageUsers.columnModal.role',
  columnModalSolutions: 'manageUsers.columnModal.solutions',
  columnModalStatus: 'manageUsers.columnModal.status',
  columnModalTitle: 'manageUsers.columnModal.title',
  columnModalUsers: 'manageUsers.columnModal.users',
  confirm: 'manageUsers.inviteOverlay.confirm',
  confirmModalText: 'manageUsers.modal.confirmModalText',
  confirmModalTitle: 'manageUsers.modal.confirmModalTitle',
  contextualFooterCancel: 'manageUsers.columnModal.cancel',
  contextualFooterContinue: 'manageUsers.columnModal.continue',
  contextualFooterItemSelect: 'manageUsers.contextualFooterLabels.itemSelectLabel',
  contextualFooterItemsSelect: 'manageUsers.contextualFooterLabels.itemsSelectLabel',
  contextualFooterRemoveUserSelect: 'manageUsers.contextualFooterLabels.removeUserSelect',
  contextualFooterSave: 'manageUsers.contextualFooterLabels.button.save',
  deleteUserErrorMessage: 'manageUsers.table.delete.deleteUserErrorMessage',
  editModalTitle: 'manageUsers.modal.editUser',
  editUserAlreadyAddedEmailError: 'manageUsers.inviteOverlay.alreadyInvitedError',
  editUserEmailError: 'manageUsers.modal.error.emailError',
  editUserSelectItem1: 'manageUsers.UserType.selectItem1',
  editUserSelectItem2: 'manageUsers.UserType.selectItem2',
  editUserSelectItem3: 'manageUsers.UserType.selectItem3',
  editUserSelectLabel: 'manageUsers.inviteOverlay.select',
  editUserTextBox: 'manageUsers.tableHeaders.header2',
  emailAddress: 'manageUsers.modal.emailAddress',
  emailAddressUpper: 'manageUsers.modal.emailAddressUpper',
  errorBlankText: 'manageUsers.modal.error.blankText',
  errorListBody: 'manageUsers.error.list.header',
  errorListHeader: 'manageUsers.error.list.header',
  errorMessage: 'manageUsers.inviteOverlay.errorMessage',
  invitationDescription: 'manageUsers.invitation.description',
  invitationFailureNotEligible: 'manageUsers.invitation.toast.failureEmailNotEligible',
  invitationFailureInvite: 'manageUsers.invitation.toast.failureEmail',
  invitationFailureInvitePlural: 'manageUsers.invitation.toast.failureEmailPlural',
  invitationFailureInvite1: 'manageUsers.invitation.toast.failureEmail',
  invitationFailureInvitePlural1: 'manageUsers.invitation.toast.failureEmailPlural',
  invitationFailureUsers: 'manageUsers.invitation.toast.failureGeneric',
  invitationFailureUsersPlural: 'manageUsers.invitation.toast.failureGenericPlural',
  invitationNotificationInformation: 'manageUsers.invitation.notification.usersAlreadyInvitedNotAdded',
  invitationNotificationWarning: 'manageUsers.invitation.notification.usersAlreadyInvitedRemoved',
  invitationSuccessInvite: 'manageUsers.invitation.toast.successGeneric',
  invitationSucessView: 'manageUsers.invitation.toast.successView',
  invitationSuccessInvitePlural: 'manageUsers.invitation.toast.successGenericPlural',
  invitationSuccessUsers: 'manageUsers.invitation.toast.successGeneric',
  invitationSuccessUsersPlural: 'manageUsers.invitation.toast.successGenericPlural',
  inviteOverlayDescription: 'manageUsers.topOfPage.title1',
  inviteOverlayEmailError: 'manageUsers.inviteOverlay.emailError',
  inviteOverlayNoEmail: 'manageUsers.inviteOverlay.noEmail',
  inviteOverlayRole: 'manageUsers.inviteOverlay.role',
  inviteOverlaySendInvite: 'manageUsers.inviteOverlay.sendLong',
  inviteOverlayTitle: 'manageUsers.inviteOverlay.header2',
  invitePageInvite: 'manageUsers.topOfPage.invite',
  lastUpdated: 'manageUsers.topOfPage.lastupdated',
  mocalSelectRole: 'manageUsers.modal.selectRole',
  modalCancel: 'manageUsers.modal.cancel',
  modalRemove: 'manageUsers.modal.remove',
  modalSave: 'manageUsers.modal.save',
  modalUnsavedChangesCancel: 'manageUsers.modal.unsavedChanges.cancel',
  modalUnsavedChangesDescription: 'manageUsers.modal.unsavedChanges.description',
  modalUnsavedChangesLeave: 'manageUsers.modal.unsavedChanges.leave',
  modalUnsavedChangesTitle: 'manageUsers.modal.unsavedChanges.title',
  navigationBlockerModalBodyCopy: 'manageUsers.navigationBlocker.bodyCopy',
  navigationBlockerModalBodyCopy2: 'manageUsers.navigationBlocker.bodyCopy2',
  navigationBlockerModalTitle: 'manageUsers.navigationBlocker.title',
  noItemsFound: 'manageUsers.table.noItemsFound',
  noLabel: 'manageUsers.navigationBlocker.no',
  pageHeader: 'manageUsers.topOfPage.mainHeaderV2',
  pageTitle: 'manageUsers.inviteOverlay.description2',
  paging: 'manageUsers.paging',
  recipient: 'manageUsers.inviteOverlay.recipient',
  removeModalContent: 'manageUsers.modal.removeRecipientContent',
  removeModalTitle: 'manageUsers.modal.removeRecipient',
  removeUser: 'manageUsers.removeUser.removeUser',
  removeUserCancel: 'manageUsers.removeUser.cancel',
  removeUserErrorToast: 'manageUsers.removeUser.errorToast',
  removeUserErrorToastPlural: 'manageUsers.removeUser.errorToastPlural',
  removeUserSuccessToast: 'manageUsers.table.delete.sucessMessageSingleUser',
  removeUserSuccessToastPlural: 'manageUsers.table.delete.sucessMessage',
  retry: 'manageUsers.table.clickToRetry',
  retryButton: 'manageUsers.error.list.buttons.retry',
  role: 'manageUsers.columnModal.role',
  saveLabel: 'manageUsers.error.saveLabel',
  searchLabel: 'manageUsers.searchLable.lableText',
  send: 'manageUsers.inviteOverlay.send',
  statusActive: 'manageUsers.table.status.active',
  statusExpired: 'manageUsers.table.status.expired',
  statusInvitation: 'manageUsers.table.status.invitation',
  statusPending: 'manageUsers.table.status.pending',
  successMessage: 'manageUsers.inviteOverlay.successMessage',
  tableColumnsLabel1: 'manageUsers.tableColumnsLabels.columnLabel1',
  tableColumnsLabel2: 'manageUsers.tableColumnsLabels.columnLabel2',
  tableColumnsLabel3: 'manageUsers.tableColumnsLabels.columnLabel3',
  tableColumnsLabel4: 'manageUsers.tableColumnsLabels.columnLabel4',
  tableColumnsLabel5: 'manageUsers.tableColumnsLabels.columnLabel5',
  tableColumnsLabel6: 'manageUsers.tableColumnsLabels.columnLabel6',
  tableColumnsLabel7: 'manageUsers.tableColumnsLabels.columnLabel7',
  tableColumnsLabel8: 'manageUsers.tableColumnsLabels.columnLabel8',
  titleLong: 'manageUsers.topOfPage.titleLong',
  toastErrorMessage: 'manageUsers.modal.error.toastErrorMessage',
  unableToLoad: 'manageUsers.table.unableToLoad',
  useRemoveModalContent: 'manageUsers.modal.removeUserContent',
  userRemoveModalContentMulti: 'manageUsers.modal.removeUserContentMulti',
  userRemoveModalContentSingle: 'manageUsers.modal.removeUserContentSingle',
  userRemoveModalTitle: 'manageUsers.modal.removeUser',
  userSettignsBreadCrumb: 'manageUsers.topOfPage.settings',
  userSettingsButton: 'manageUsers.topOfPage.settings',
  userSettingsErrorLabel: 'manageUsers.userSettingsOverlay.errorLabel',
  userSettingsFailureToast: 'manageUsers.userSettingsOverlay.failureToastNotification',
  userSettingsHeader: 'manageUsers.topOfPage.settings',
  userSettingsModalCancel: 'manageUsers.inviteOverlay.confirm',
  userSettingsModalContent: 'manageUsers.modal.userSettingsContent',
  userSettingsModalLeave: 'manageUsers.inviteOverlay.cancel',
  userSettingsModalTitle: 'manageUsers.modal.userSettingsTitle',
  userSettingsSelectBoxLabel: 'manageUsers.userSettingsOverlay.selectboxLabel',
  userSettingsSelectItem1: 'manageUsers.userSettingsOptions.selectItem1',
  userSettingsSelectItem2: 'manageUsers.userSettingsOptions.selectItem2',
  userSettingsSelectItem3: 'manageUsers.userSettingsOptions.selectItem3',
  userSettingsSuccessToast: 'manageUsers.userSettingsOverlay.successToastNotification',
  userSettingsTextbox2Label: 'manageUsers.userSettingsOverlay.textbox2',
  userSettingsTextboxLabel: 'manageUsers.userSettingsOverlay.textbox',
  usersRemoveModalTitle: 'manageUsers.modal.removeUsers',
  yesLabel: 'manageUsers.navigationBlocker.yes',
  selectRole: 'manageUsers.selectRole',
  inviteRole: 'manageUsers.inviteOverlay.role',
  noInvitationLabel: 'manageUsers.noInvitation',
  emailInviteLabel: 'manageUsers.emailInvite',
  duplicateEmailLabel: 'manageUsers.duplicateEmail',
  importLabel: 'manageUsers.import',
  userimportLabel: 'manageUsers.importUsers',
  uploadLabel: 'manageUsers.uploadCsv',
  browseLabel: 'manageUsers.browse',
  selectFile: 'manageUsers.selectFile',
  downloadFile: 'manageUsers.download',
  thirdPartyLabel: 'manageUsers.thirdParty',
  usersImportedTitle: 'manageUsers.usersImport',
  addUserTitle: 'manageUsers.addUser',
  addUsersTitle: 'manageUsers.addUsers',
  selectActionLabel: 'manageUsers.selectAction',
  userFilterLabel: 'manageUsers.filter',
  userSelectFilterLabel: 'manageUsers.selectFilter',
  noEmailLabel: 'manageUsers.noEmail',
  searchFilterLabel: 'manageUsers.searchFilter',
  builtInLabel: 'manageUsers.builtin',
  removeUserSubContent: 'manageUsers.modal.removeUserSubContent',
  searchKeywordContent: 'manageUsers.searchKeyword',
  noResults: 'manageUsers.noResults',
  invite: 'manageUsers.invite',
  noUserLabel: 'manageUsers.nouser',
  clearAllLabel: 'manageUsers.clearAll',
  itAdmin: 'manageUsers.itAdmin',
  endUser: 'manageUsers.endUser',
  statusKey: 'manageUsers.tableHeaders.header3',
  roleKey: 'manageUsers.tableHeaders.header4',
  noItems: 'manageUsers.noItems'
}

let l10nMap = { ...baseL10nMap }

export const mergel10nMap = (map = {}) => {
  l10nMap = {
    ...baseL10nMap,
    ...map
  }
}

export const getl10n = (key) => l10nMap[key] || key
