import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { path } from 'ramda';
import { SideMenuItem } from '@veneer/core';
import primitives from '@veneer/primitives';
import {
  ErrorWidget, LoaderWidget, useI18n, usePreferencesContext,
} from '@jarvis/react-portal-addons';
import useHelpCenterApiCall from '../../hooks/useHelpCenterApiCall';
import { SmbAndConsumerDuplicatedSlugs, helpCenterEvents, publishEvent } from '../../utils/analytics';
import { ErrorAndLoaderContainer, SideMenu, SideMenuContainer } from './styles';

const SideMenuNav = ({ setSideMenuData, ...props }) => {
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedSubItemId, setSelectedSubItemId] = useState(null);
  const [expandedTopics, setExpandedTopics] = useState([]);
  const [pageId, setPageId] = useState(null);
  const [isFirstInitialization, setIsFirstInitialization] = useState(true);
  const preferences = usePreferencesContext();
  const initializeWithMenuExpanded = preferences.content?.initializeWithMenuExpandedBasedOnPageId;

  const { stack } = props;
  const { t } = useI18n();

  const authProvider = path(['shell', 'v1', 'authProvider'], props);
  const orgSelector = path(['shell', 'v1', 'orgSelector'], props);
  const navigation = path(['shell', 'v1', 'navigation'], props);

  const helpCenterApiCall = useHelpCenterApiCall({
    accountId: orgSelector.getOrgTenantId(),
    authProvider,
    stack,
  });

  const updateSideMenuData = useCallback(({
    text, textsForSpecificEnvironments, title, topicClickLinkEvent, analyticsId,
  }) => {
    setSideMenuData({
      text,
      textsForSpecificEnvironments,
      title,
      topicClickLinkEvent,
      analyticsId,
    });
  }, [setSideMenuData]);

  const getSubItemData = useCallback(appHelpSectionItem => {
    if (!appHelpSectionItem) {
      return { subItemContent: null, subItemKey: null };
    }

    const subItemContent = appHelpSectionItem.helpSection || appHelpSectionItem.HelpLink || appHelpSectionItem;
    // Adding this exception since _metadata is a key comming in content stack response payload
    // eslint-disable-next-line no-underscore-dangle
    const subItemKey = subItemContent.id || subItemContent._metadata?.uid || subItemContent.HelpLink?._metadata.uid;
    return { subItemContent, subItemKey };
  }, []);

  const handleMainTopicsExpandAndCollapse = useCallback(item => {
    if (preferences.updateUrlOnMenuClick) {
      navigation.replace(`${preferences.path}${item.url}`);
    }

    let analyticsEventkey = helpCenterEvents[item.id] || helpCenterEvents[item.slug];
    // SMB and Consumer have the same slug, so we need to check the screenPath to differentiate them
    if (
      SmbAndConsumerDuplicatedSlugs.includes(item.slug)
        && props.properties.analyticsScreenPath === '/ReactConsumerHelpCenter/'
    ) {
      analyticsEventkey = helpCenterEvents[`${item.slug}-consumer`];
    }

    if (!analyticsEventkey) {
      return;
    }

    if (expandedTopics.length && expandedTopics.includes(item.title)) {
      setExpandedTopics(prevExtendedTopics => prevExtendedTopics.filter(i => i !== item.title));
      publishEvent(analyticsEventkey.collapsed);
    } else {
      setExpandedTopics(prevExtendedTopics => [
        ...prevExtendedTopics,
        item.title,
      ]);
      publishEvent(analyticsEventkey.expanded);
    }
  },
  [
    expandedTopics,
    navigation,
    preferences.path,
    preferences.updateUrlOnMenuClick,
    props.properties.analyticsScreenPath,
  ]);

  const handleClick = useCallback((itemId, analyticsEventkey, content) => {
    let correctItemId = itemId;

    if (
      SmbAndConsumerDuplicatedSlugs.includes(itemId)
        && props.properties.analyticsScreenPath === '/ReactConsumerHelpCenter/'
    ) {
      correctItemId = `${itemId}-consumer`;
    }

    const event = helpCenterEvents[correctItemId]?.[analyticsEventkey];

    if (event) {
      publishEvent(event);
    }

    if (content.isHelpLink && content.linkAddress) {
      window.open(content.linkAddress);
    } else {
      updateSideMenuData({
        text: content.text,
        textsForSpecificEnvironments: content.textsForSpecificEnvironments,
        title: content.title,
        topicClickLinkEvent: helpCenterEvents[itemId]?.link,
        analyticsId: content.analyticsId,
      });
    }
  },
  [props.properties.analyticsScreenPath, updateSideMenuData]);

  const selectSideMenuItem = useCallback((itemId, subItemId) => {
    setSelectedItemId(itemId);
    setSelectedSubItemId(subItemId);
  }, []);

  const scrollToElement = useCallback(() => {
    const element = window.innerWidth <= primitives.layout.breakpoint5 ? '#sideMenuContent' : '.mainContainer';

    document.querySelector(element)?.scrollIntoView({
      behavior: 'smooth',
    });
  }, []);

  const getAppText = useCallback(subItem => {
    if (preferences.content?.subItemsToCheckNoHplusText?.length) {
      const { subItemsToCheckNoHplusText } = preferences.content;
      const { isHpPlus } = helpCenterApiCall.programInfosCall;

      if (
        subItemsToCheckNoHplusText.find(
          item => item.analyticsId === subItem.analyticsId,
        )
          && !isHpPlus
          && subItem.helpForAllAppsNoHpPlus
      ) {
        return subItem.helpForAllAppsNoHpPlus;
      }
    }

    return (
      subItem.helpForAllApps
        || subItem.helpForAllAppsNoHpPlus
        || subItem.helpForAllAppsHpplusOnly
        || subItem.helpForAllAppsIiOnly
    );
  },
  [helpCenterApiCall.programInfosCall, preferences.content]);

  const getTextsToSpecificEnvironments = useCallback(
    subItem => {
      if (
        preferences.dataSource?.contentStack?.environmentSectionTitles?.fetch
      ) {
        const {
          androidAppSectionTitle,
          desktopAppSectionTitle,
          iosAppSectionTitle,
          mobileAppSectionTitle,
        } = helpCenterApiCall.sectionsTitles?.entries?.[0]
          .smartHelpAppSectionTitles?.[0].smartTitles || {};

        return [
          {
            title: androidAppSectionTitle,
            text: subItem.helpForAndroid,
          },
          {
            title: desktopAppSectionTitle,
            text: subItem.helpForDesktop,
          },
          {
            title: iosAppSectionTitle,
            text: subItem.helpForIos,
          },
          {
            title: mobileAppSectionTitle,
            text: subItem.helpForMobile,
          },
        ].filter(subItemText => subItemText.text !== '');
      }

      return [];
    },
    [
      helpCenterApiCall.sectionsTitles?.entries,
      preferences.dataSource?.contentStack?.environmentSectionTitles?.fetch,
    ],
  );

  useEffect(() => {
    if (initializeWithMenuExpanded) {
      setPageId(navigation?.location?.pathname?.split('/').pop() || '');
    }
  }, [navigation?.location?.pathname, initializeWithMenuExpanded]);

  useEffect(() => {
    if (
      initializeWithMenuExpanded
      && pageId
      && helpCenterApiCall.data
      && !helpCenterApiCall.isFetching
      && isFirstInitialization
    ) {
      const menuItemOfPageId = helpCenterApiCall.data.find(
        item => item.url === `/${pageId}`,
      );
      const menuKey = menuItemOfPageId?.id || menuItemOfPageId?.slug;

      if (!menuKey) {
        return;
      }

      const firstAppHelpSection = menuItemOfPageId.appHelpSection[0];

      const {
        subItemContent: firstSubMenuContent,
        subItemKey: firstSubMenuKey,
      } = getSubItemData(firstAppHelpSection);

      if (!firstSubMenuKey) {
        return;
      }

      selectSideMenuItem(menuKey, firstSubMenuKey);
      scrollToElement();
      setIsFirstInitialization(false);

      const isLink = 'HelpLink' in firstAppHelpSection;

      if (!isLink) {
        updateSideMenuData({
          text: getAppText(firstSubMenuContent),
          textsForSpecificEnvironments:
            getTextsToSpecificEnvironments(firstSubMenuContent),
          title:
            firstSubMenuContent.helpTopic || firstSubMenuContent.helpLinkText,
          topicClickLinkEvent: helpCenterEvents[menuKey]?.link,
          analyticsId: firstSubMenuContent.analyticsId,
        });
      }
    }
  }, [
    getAppText,
    getTextsToSpecificEnvironments,
    helpCenterApiCall.data,
    helpCenterApiCall.isFetching,
    initializeWithMenuExpanded,
    pageId,
    scrollToElement,
    selectSideMenuItem,
    updateSideMenuData,
    isFirstInitialization,
    getSubItemData,
  ]);

  return (
    <SideMenuContainer>
      {helpCenterApiCall.data && !helpCenterApiCall.isFetching && (
        <SideMenu
          appName={t('breadcrumb.helpCenter')}
          position="relative"
          expanded
        >
          {helpCenterApiCall.data.map(item => {
            const itemKey = item.id || item.slug;
            return (
              <SideMenuItem
                data-testid={itemKey}
                label={item.title}
                key={itemKey}
                selected={
                  selectedItemId === item.id || selectedItemId === item.slug
                }
                onClick={() => handleMainTopicsExpandAndCollapse(item)}
                expanded={
                  initializeWithMenuExpanded
                  && pageId
                  && item.url === `/${pageId}`
                }
              >
                {item.appHelpSection.map(el => {
                  const { subItemContent, subItemKey } = getSubItemData(el);

                  return (
                    <SideMenuItem
                      data-testid={subItemKey}
                      className="subMenuItem"
                      label={
                        subItemContent.helpTopic || subItemContent.helpLinkText
                      }
                      key={subItemKey}
                      selected={selectedSubItemId === subItemKey}
                      onClick={e => {
                        e.stopPropagation();
                        handleClick(
                          itemKey,
                          subItemContent.analyticsID
                            || subItemContent.analyticsId,
                          {
                            title:
                              subItemContent.helpTopic
                              || subItemContent.helpLinkText,
                            text: getAppText(subItemContent),
                            textsForSpecificEnvironments:
                              getTextsToSpecificEnvironments(subItemContent),
                            isHelpLink: 'HelpLink' in el,
                            linkAddress: el.HelpLink?.helpLinkAddress,
                            analyticsId: subItemContent.analyticsId,
                          },
                        );
                        selectSideMenuItem(itemKey, subItemKey);
                        scrollToElement();
                      }}
                    />
                  );
                })}
              </SideMenuItem>
            );
          })}
        </SideMenu>
      )}

      {helpCenterApiCall.isFetching && (
        <ErrorAndLoaderContainer>
          <LoaderWidget />
        </ErrorAndLoaderContainer>
      )}

      {helpCenterApiCall.error && !helpCenterApiCall.isFetching && (
        <ErrorAndLoaderContainer>
          <ErrorWidget
            labelRetry={t('errorWidget.retry')}
            message={t('errorWidget.unableToLoadData')}
            onRetry={() => helpCenterApiCall.forceFetch()}
          />
        </ErrorAndLoaderContainer>
      )}
    </SideMenuContainer>
  );
};

SideMenuNav.defaultProps = {
  setSideMenuData: null,
  stack: '',
};

SideMenuNav.propTypes = {
  setSideMenuData: PropTypes.func,
  stack: PropTypes.number,
  properties: PropTypes.shape({
    analyticsScreenPath: PropTypes.string,
  }).isRequired,
};

export default SideMenuNav;
