import React, { useCallback, useEffect, useState } from 'react'
import Button from '@veneer/core/dist/scripts/button'
import RadioButton from '@veneer/core/dist/scripts/radio_button'
import {
  MOBILE_CONTAINER,
  AsyncDispatch,
  useBillingAddress,
  useBillingAddressError,
  useBillingAddressSaving,
  useBillingFormState,
  useBillingInfo,
  useBillingInfoError,
  useContainerSize,
  useDispatch,
  useErrorFields,
  useGetText,
  useOnCancel,
  useOnSave,
  useOnUpdate,
  usePaymentType,
  useSettings,
  useSettingsError,
  useShippingAddress,
  useStateOptions,
  useTaxpayerDetails,
  useTaxpayerDetailsError,
  useTaxpayerDetailsSaving,
  useUserAccount,
  useUserAccountError
} from '../../hooks'
import {
  BillingFlowSteps,
  DataType,
  ErrorType,
  PaymentType,
  BillingFormAction,
  BillingFormState
} from '../../types'
import { Spinner } from '../Spinner'
import {
  fetchBillingAddressAction,
  fetchBillingInfoAction,
  fetchSettingsAction,
  fetchTaxpayerDetailsAction,
  fetchUserAccountAction,
  pushCurrentStepAction,
  saveBillingAddressAction,
  saveTaxpayerDetailsAction,
  setBillingAddressAction,
  setPaymentTypeAction,
  validateBillingAddressAction,
  validateTaxpayerDetailsAction
} from '../../actions'
import { ErrorMessagePage } from '../ErrorMessagePage'
import { AddressSection } from './addressSection'
import { AccountTypeSelector } from './accountTypeSelector'
import { getRequiredFields } from '../Helpers/helpers'
import { PaymentIcon } from './paymentIcon'
import { SubPaymentIcons } from './subPaymentIcons'
import { ErrorMessageInline } from '../ErrorMessageInline'
import {
  StyledBillingAndAddress,
  StyledBillingTypeSelector,
  StyledTypeSelectorCards,
  StyledSubtitle,
  StyledTypeSelectorCard,
  StyledCardContent,
  StyledVeneerRadio,
  StyledVeneerRadioContent,
  StyledTypeSelectorIcon,
  StyledAddressSectionContainer,
  StyledPayPalShipping
} from './styles'
import {
  StyledStep,
  StyledStepTitle,
  StyledStepNumber,
  StyledButtonSection
} from '../Shared/styles'
import { ThemeProvider as VeneerThemeProvider } from '@veneer/theme'

const StepOneSubTitles = () => {
  const getText = useGetText()

  return (
    <span>
      <StyledStepNumber>
        {getText('billing_form.step_title.1')}:{' '}
      </StyledStepNumber>
      <StyledStepTitle>{getText('billing_address.sub_title')}</StyledStepTitle>
    </span>
  )
}

export const StepOne = () => {
  const getText = useGetText()
  const containerSize = useContainerSize()
  const settings = useSettings()
  const settingsError = useSettingsError()
  const taxpayerDetails = useTaxpayerDetails()
  const taxpayerDetailsSaving = useTaxpayerDetailsSaving()
  const taxpayerDetailsError = useTaxpayerDetailsError()
  const userAccount = useUserAccount()
  const userAccountError = useUserAccountError()
  const billingInfo = useBillingInfo()
  const billingInfoError = useBillingInfoError()
  const billingAddress = useBillingAddress()
  const billingAddressError = useBillingAddressError()
  const billingAddressSaving = useBillingAddressSaving()
  const shippingAddress = useShippingAddress()
  const errorFields = useErrorFields()
  const onBillingFormSave = useOnSave()
  const onUpdate = useOnUpdate()
  const onCancel = useOnCancel()
  const dispatch = useDispatch()
  const stateOptions = useStateOptions()
  const showStateDropdown = stateOptions.length > 0
  const paymentType = usePaymentType()
  const {
    nativeApp,
    originalBillingAddress,
    enablePaypalInNativeApp,
    enableAllPaymentLayout,
    separateStepOneButtons
  } = useBillingFormState()
  const [ready, setReady] = useState(false)
  const selectPaymentType = useCallback(
    (paymentType) => dispatch(setPaymentTypeAction(paymentType)),
    [dispatch]
  )
  const paymentMethods =
    ((nativeApp && !enablePaypalInNativeApp) || enableAllPaymentLayout) &&
    billingInfo?.paymentMethods
      ? billingInfo.paymentMethods.filter(
          (paymentMethod) => paymentMethod !== PaymentType.pay_pal
        )
      : billingInfo?.paymentMethods
  const isDisabled =
    errorFields.size > 0 || billingAddressSaving || taxpayerDetailsSaving

  useEffect(() => {
    if (!ready) {
      if (
        billingInfo !== undefined &&
        billingAddress !== undefined &&
        settings !== undefined &&
        userAccount !== undefined
      ) {
        if (
          !settings.enableBillingAccountType ||
          taxpayerDetails !== undefined
        ) {
          setReady(true)
        }
      }
    }
  }, [
    ready,
    billingInfo,
    billingAddress,
    shippingAddress,
    settings,
    taxpayerDetails,
    userAccount
  ])

  const findPaymentType = useCallback(
    (paymentType: PaymentType | undefined) => {
      if (
        paymentType === PaymentType.pay_pal &&
        ((!enablePaypalInNativeApp && nativeApp) || enableAllPaymentLayout)
      ) {
        return PaymentType.credit_card
      }

      return paymentType || PaymentType.credit_card
    },
    [enableAllPaymentLayout, enablePaypalInNativeApp, nativeApp]
  )

  useEffect(() => {
    if (billingInfo && !paymentType) {
      ;(async () => {
        await selectPaymentType(findPaymentType(billingInfo.paymentType))
      })()
    }
  }, [billingInfo, findPaymentType, paymentType, selectPaymentType])

  if (!ready) {
    if (
      billingInfoError ||
      billingAddressError ||
      settingsError ||
      taxpayerDetailsError ||
      userAccountError
    ) {
      const onRetry = async () => {
        const promises: Promise<unknown>[] = []
        const errors = {
          billingInfo: {
            error: billingInfoError,
            fetchAction: fetchBillingInfoAction
          },
          billingAddress: {
            error: billingAddressError,
            fetchAction: fetchBillingAddressAction
          },
          settings: {
            error: settingsError,
            fetchAction: fetchSettingsAction
          },
          taxpayerDetails: {
            error: taxpayerDetailsError,
            fetchAction: fetchTaxpayerDetailsAction
          },
          userAccount: {
            error: userAccountError,
            fetchAction: fetchUserAccountAction
          }
        }

        Object.values(errors).forEach((error) => {
          if (error.error) {
            promises.push(dispatch(error.fetchAction()))
          }
        })

        try {
          await Promise.all(promises)
        } catch {
          // do nothing
        }
      }

      return (
        <>
          <StepOneSubTitles />
          <ErrorMessagePage onRetry={onRetry} />
        </>
      )
    }
    return <Spinner />
  }

  if (!paymentMethods) {
    return null
  }

  const BillingAndAddress = settings?.enableBillingUpdatedLayout
    ? StyledBillingAndAddress
    : React.Fragment

  const enableBillingAccountType = Boolean(settings?.enableBillingAccountType)
  const onContinue = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: AsyncDispatch<BillingFormAction, BillingFormState, any>,
    getState: () => BillingFormState
  ) => {
    const saveBillingAddress =
      paymentType != undefined &&
      [PaymentType.credit_card, PaymentType.direct_debit].includes(paymentType)
    try {
      if (saveBillingAddress) {
        await dispatch(
          validateBillingAddressAction(getRequiredFields(showStateDropdown))
        )
      }
      if (enableBillingAccountType) {
        await dispatch(validateTaxpayerDetailsAction())
      }
      if (getState().errorFields.size > 0) return

      if (saveBillingAddress) {
        await dispatch(saveBillingAddressAction())
        onUpdate(DataType.billingAddress)
      }
      if (enableBillingAccountType) {
        await dispatch(saveTaxpayerDetailsAction())
        onUpdate(DataType.accountType)
      }

      await dispatch(pushCurrentStepAction(BillingFlowSteps.STEP_TWO))
    } catch (error) {
      const { response } = error
      if (response?.status === 401) {
        onBillingFormSave(ErrorType.expired_token)
      } else if (response?.status === 403) {
        onBillingFormSave(ErrorType.expired_critical_scope)
      }
    }
  }

  const showMobilePaymentTypeSelector =
    containerSize && [MOBILE_CONTAINER].includes(containerSize)

  const shouldCheckBySubPaymentOption = (paymentMethod: PaymentType) => {
    return (
      enableAllPaymentLayout &&
      paymentType === PaymentType.pay_pal &&
      paymentMethod === PaymentType.credit_card
    )
  }

  return (
    <StyledStep>
      <StepOneSubTitles />
      <AccountTypeSelector />
      <BillingAndAddress>
        <StyledBillingTypeSelector>
          <StyledSubtitle>
            {getText('billing_type_selector.sub_title')}
          </StyledSubtitle>
          <VeneerThemeProvider shape="round">
            <StyledTypeSelectorCards>
              {paymentMethods.map((paymentMethod) => (
                <StyledTypeSelectorCard
                  key={`paymentMethod-${paymentMethod}`}
                  data-testid={`${paymentMethod}-payment-box`.replace('_', '-')}
                  appearance="dropShadow"
                  content={
                    <StyledCardContent>
                      <StyledVeneerRadio>
                        <RadioButton
                          label={
                            <>
                              <StyledVeneerRadioContent>
                                {!showMobilePaymentTypeSelector ? (
                                  <StyledTypeSelectorIcon>
                                    <PaymentIcon
                                      paymentMethod={paymentMethod}
                                    />
                                  </StyledTypeSelectorIcon>
                                ) : null}
                                {getText(
                                  `billing_type_selector.${paymentMethod}.title`
                                )}
                                <SubPaymentIcons
                                  paymentMethod={paymentMethod}
                                />
                              </StyledVeneerRadioContent>
                            </>
                          }
                          data-testid={`${paymentMethod}-radio-button`.replace(
                            '_',
                            '-'
                          )}
                          data-analyticsid={`${paymentMethod}-radio-button`}
                          checked={
                            paymentType === paymentMethod ||
                            shouldCheckBySubPaymentOption(paymentMethod)
                          }
                          onChange={async () => {
                            if (paymentMethod === PaymentType.pay_pal) {
                              await dispatch(
                                setBillingAddressAction(originalBillingAddress)
                              )
                            }
                            await selectPaymentType(paymentMethod)
                          }}
                        />
                      </StyledVeneerRadio>
                      {showMobilePaymentTypeSelector ? (
                        <StyledTypeSelectorIcon>
                          <PaymentIcon paymentMethod={paymentMethod} />
                        </StyledTypeSelectorIcon>
                      ) : null}
                    </StyledCardContent>
                  }
                />
              ))}
            </StyledTypeSelectorCards>
          </VeneerThemeProvider>
        </StyledBillingTypeSelector>
        <StyledAddressSectionContainer>
          {paymentType === PaymentType.pay_pal ? (
            <StyledPayPalShipping>
              <StyledSubtitle>
                {getText('billing_address.title')}
              </StyledSubtitle>
              <span>
                {getText('billing_type_selector.your_pay_pal_billing')}
              </span>
            </StyledPayPalShipping>
          ) : (
            <div data-testid="address-section">
              <AddressSection showStateDropdown={showStateDropdown} />
            </div>
          )}
        </StyledAddressSectionContainer>
      </BillingAndAddress>
      <ErrorMessageInline />
      <StyledButtonSection $separateStepOneButtons={separateStepOneButtons}>
        <Button
          data-testid="cancel-button"
          data-analyticsid="CancelButton"
          appearance="secondary"
          onClick={onCancel}
          expanded={containerSize === MOBILE_CONTAINER}
        >
          {getText('billing_form.cancel')}
        </Button>
        <Button
          className={isDisabled ? 'instant-ink-enroll-hpx-button-override' : ''}
          data-testid="continue-button"
          data-analyticsid="ContinueButton"
          disabled={isDisabled}
          loading={billingAddressSaving || taxpayerDetailsSaving}
          onClick={() => dispatch(onContinue)}
          expanded={containerSize === MOBILE_CONTAINER}
        >
          {getText('billing_form.continue')}
        </Button>
      </StyledButtonSection>
    </StyledStep>
  )
}
