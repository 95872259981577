// Colors are used in order, by the current MFEs loaded. Which means, every color only show if the previous one is already loaded.
const defaultColor = '#000000';
const colors = [
  '#800000',
  '#00008B',
  '#006400',
  '#4B0082',
  '#FF00FF',
  '#00FF00',
  '#696969',
  '#0000FF',
  '#FFFF00',
  '#FF7F50',
  '#B8860B',
  '#FF0000',
  '#FFD700',
  '#00FFFF',
  '#A0522D',
  '#32CD32',
  '#FF4500',
  '#FF6347',
  '#FF8C00',
  '#FFA500',
  '#F4A460',
  '#B22222',
  '#FFC0CB',
  '#FFB6C1',
  '#F08080',
  '#DEB887',
  '#EEE8AA',
  '#FFF8DC',
  '#228B22',
  '#008000',
  '#4682B4',
  '#8A2BE2',
  '#800080',
  '#EE82EE',
  '#C71585',
  '#DB7093',
  '#FF1493',
  '#FFDAB9',
  '#FFE4B5',
  '#FFE4E1',
  '#D3D3D3',
  '#A9A9A9'
];

type ColorListIndexType = {
  color: string;
  assetReference?: string;
  count: number;
};
const colorList: ColorListIndexType[] = colors.map((color) => ({
  color,
  assetReference: undefined,
  count: 0
}));

type ColorInstanceType = { color: string; clear: () => void };

export function createColorInstance(assetReference: string): ColorInstanceType {
  const color =
    colorList.find((color) => color.assetReference === assetReference) ||
    colorList.find((color) => color.assetReference === undefined);

  if (!color) {
    console.warn(
      'HOC - No more colors available, using defaultColor: ',
      defaultColor
    );
    return { color: defaultColor, clear: () => undefined };
  }

  color.assetReference = assetReference;
  color.count += 1;

  return {
    color: color.color,
    clear: () => {
      color.count -= 1;

      if (color.count === 0) {
        color.assetReference = undefined;
      }
    }
  };
}
