import React, { useEffect, useRef } from 'react'
import lottie from 'lottie-web/build/player/lottie_light'
import circularAnimation from './circular.json'
import linearAnimation from './linear.json'
import classes from './styles.module.scss'

const AnimationLoader = ({ file, variant }) => {
  const container = useRef(null)
  const animation = useRef(null)

  useEffect(() => {
    animation.current = lottie.loadAnimation({
      container: container.current,
      animationData: file,
      rendererSettings: {
        progressiveLoad: true
      }
    })
    return () => {
      animation.current && animation.current.destroy()
    }
  }, [file])

  return (
    <div
      ref={container}
      data-testid="setup-and-onboarding-animation-loader"
      className={`${classes['animation-loader']} ${classes[`${variant}`]}`}
    />
  )
}

export const CircularAnimation = ({ size }) => {
  return (
    <AnimationLoader
      file={circularAnimation}
      variant={size}
    />
  )
}

export const LinearAnimation = ({ size }) => {
  return (
    <AnimationLoader
      file={linearAnimation}
      variant={size}
    />
  )
}

export default AnimationLoader
