import React from 'react'
import VProgressIndicator from '@veneer/core/dist/esm/scripts/progress_indicator/progress_indicator'
import useMultiUiBrandContext from '../../contexts/MultiUiBrand/useMultiUiBrandContext'
import { CircularAnimation, LinearAnimation } from './AnimationLoader'
import { UiTheme } from '../../config/constants'

const BEHAVIOR = {
  INDETERMINATE: 'indeterminate'
}

const APPEARANCE = {
  CIRCULAR: 'circular'
}

const SIZE = {
  SMALL: 'small'
}

const ProgressIndicator = ({ appearance, behavior, size, ...props }) => {
  const { uiBrand } = useMultiUiBrandContext()
  if (!appearance) appearance = APPEARANCE.CIRCULAR
  if (!behavior) behavior = BEHAVIOR.INDETERMINATE
  if (!size) size = SIZE.SMALL

  if (uiBrand === UiTheme.hpx && behavior === BEHAVIOR.INDETERMINATE)
    return appearance === APPEARANCE.CIRCULAR ? (
      <CircularAnimation size={size} />
    ) : (
      <LinearAnimation size={size} />
    )

  return (
    <VProgressIndicator
      data-testid="setup-and-onboarding-veneer-progress-indicator"
      appearance={appearance}
      behavior={behavior}
      size={size}
      {...props}
    />
  )
}

export default ProgressIndicator
