import {
  CriticalScopesData,
  DispatchFunction,
  ShippingFormAction,
  ShippingFormDispatchHandler,
  ShippingFormState
} from '../types'
import { ShippingFormActionType } from '../constants/shippingFormAction'

export const fetchCriticalScopesAction = (): ShippingFormAction => ({
  type: ShippingFormActionType.FETCH_CRITICAL_SCOPES
})

export const fetchCriticalScopesSuccessAction = (
  data: CriticalScopesData
): ShippingFormAction => ({
  type: ShippingFormActionType.FETCH_CRITICAL_SCOPES_SUCCESS,
  data
})

export const fetchCriticalScopesFailedAction = (
  error: Record<string, unknown>
): ShippingFormAction => ({
  type: ShippingFormActionType.FETCH_CRITICAL_SCOPES_FAILED,
  error
})

export const handleFetchCriticalScopesAction =
  (): ShippingFormDispatchHandler =>
  async (dispatch: DispatchFunction, getState: () => ShippingFormState) => {
    try {
      await dispatch(fetchCriticalScopesAction())

      const shippingService = getState().shippingService
      const { data } = await shippingService.getCriticalScopes()
      await dispatch(fetchCriticalScopesSuccessAction(data))
    } catch (error) {
      await dispatch(fetchCriticalScopesFailedAction(error))
    }
  }
