/* eslint-disable camelcase */
import bg_BG from './bg_BG.json'
import cs_CZ from './cs_CZ.json'
import da_DK from './da_DK.json'
import de_AT from './de_AT.json'
import de_CH from './de_CH.json'
import de_DE from './de_DE.json'
import el_CY from './el_CY.json'
import el_GR from './el_GR.json'
import en_AU from './en_AU.json'
import en_CA from './en_CA.json'
import en_GB from './en_GB.json'
import en_HK from './en_HK.json'
import en_IE from './en_IE.json'
import en_MT from './en_MT.json'
import en_NZ from './en_NZ.json'
import en_PR from './en_PR.json'
import en_SG from './en_SG.json'
import en_US from './en_US.json'
import es_ES from './es_ES.json'
import et_EE from './et_EE.json'
import fi_FI from './fi_FI.json'
import fr_BE from './fr_BE.json'
import fr_CA from './fr_CA.json'
import fr_CH from './fr_CH.json'
import fr_FR from './fr_FR.json'
import fr_LU from './fr_LU.json'
import hr_HR from './hr_HR.json'
import hu_HU from './hu_HU.json'
import it_CH from './it_CH.json'
import it_IT from './it_IT.json'
import lt_LT from './lt_LT.json'
import lv_LV from './lv_LV.json'
import nl_BE from './nl_BE.json'
import nl_NL from './nl_NL.json'
import no_NO from './no_NO.json'
import pl_PL from './pl_PL.json'
import pt_PT from './pt_PT.json'
import ro_RO from './ro_RO.json'
import sk_SK from './sk_SK.json'
import sl_SI from './sl_SI.json'
import sv_SE from './sv_SE.json'
import zh_HK from './zh_HK.json'

const SupportedTranslations = {
  en_US,
  en_CA,
  fr_CA,
  en_GB,
  de_DE,
  fr_FR,
  es_ES,
  it_IT,
  en_IE,
  nl_NL,
  fr_BE,
  nl_BE,
  fr_LU,
  de_AT,
  pt_PT,
  sv_SE,
  da_DK,
  no_NO,
  fi_FI,
  de_CH,
  fr_CH,
  it_CH,
  cs_CZ,
  pl_PL,
  hu_HU,
  el_GR,
  ro_RO,
  sk_SK,
  hr_HR,
  bg_BG,
  lv_LV,
  lt_LT,
  et_EE,
  en_NZ,
  en_MT,
  el_CY,
  sl_SI,
  en_AU,
  en_PR,
  en_SG,
  zh_HK,
  en_HK
}

export const SupportedLocales = Object.keys(SupportedTranslations)

export const SupportedCountries = SupportedLocales.reduce(
  (acc, key) => acc.add(key.split('_')[1]),
  new Set<string>()
)

export const getTranslations = async (language: string, country: string) => {
  const locale = `${language}_${country}`
  let translations = SupportedTranslations[locale]
  if (typeof translations === 'string') {
    try {
      translations = (await fetch(translations)).json()
    } catch {
      translations = {}
    }
  }
  return translations
}
