/* eslint-disable @typescript-eslint/no-unused-vars */
import { YearlyPlanSelectorState } from '../types/yearlyPlanSelectorState';
import { YearlyPlanSelectorProps } from '../types/yearlyPlanSelectorProps';
import { YearlyPlanSelectorAction } from '../types/yearlyPlanSelectorAction';
import { DashboardPlan } from '../../common/types/dashboardPlan';
import { COMPARE_YEARLY_MONTHLY_STEP } from '../../common/constants';

const defaultPlan = {
  id: 0,
  guid: '',
  price: '',
  pages: 0,
  overageBlockPrice: '',
  overageBlockSize: 0,
  program: '',
  rollover: 0,
  frequency: '',
  yearlyPrice: '',
  yearlyPlan: false
};

const findIdByCurrentPlanGUID = (
  plans: DashboardPlan[],
  currentPlanGUID: string
) => {
  const index = plans.findIndex((plan) => plan.guid === currentPlanGUID);
  const plan = index !== -1 ? plans[index] : defaultPlan;
  return { plan, index };
};

export const YearlyPlanSelectorInitialState = ({
  plans,
  currentPlanGUID,
  isStandAloneMode = false,
  trackClickEvent = (linkId: string, linkPlacement: string): null => null,
  onARNConfirmClick = () => null,
  onClose = () => null,
  yearlyPlans = null,
  correspondingYearlyPlan = null,
  renderBillingForm = () => null,
  creditCard = {
    cardTypeKey: '',
    cardNumber: 'string',
    displayExpirationDate: 'string',
    cardExpirationDate: 'string',
    expirationStatus: 'string'
  },
  paymentType = 'string',
  fetchBillingInfo = () => ({}),
  disableCvvFormForYearlyPlan = false,
  onSubmitCVV = () => null,
  cvvStatus = null,
  showModal = false,
  initialStep = COMPARE_YEARLY_MONTHLY_STEP,
  currentCycleEndDate = '',
  nextCycleStartDate = '',
  nextCycleEndDate = '',
  tosLink = ''
}: YearlyPlanSelectorProps): YearlyPlanSelectorState => {
  const currentPlan = findIdByCurrentPlanGUID(plans, currentPlanGUID);

  return {
    plans,
    currentPlan: {
      id: currentPlan.plan.id,
      index: currentPlan.index,
      guid: currentPlanGUID,
      plan: currentPlan.plan
    },
    trackClickEvent,
    isStandAloneMode,
    onARNConfirmClick,
    onCloseModal: onClose,
    yearlyPlans,
    correspondingYearlyPlan,
    renderBillingForm,
    creditCard,
    paymentType,
    fetchBillingInfo,
    disableCvvFormForYearlyPlan,
    onSubmitCVV,
    cvvStatus,
    showModal,
    initialStep,
    currentStep: initialStep,
    currentCycleEndDate,
    nextCycleStartDate,
    nextCycleEndDate,
    selectedYearlyPlan: correspondingYearlyPlan,
    tosLink
  };
};

export const yearlyPlanSelectorReducer = (
  state: YearlyPlanSelectorState,
  action: YearlyPlanSelectorAction
) => {
  let newState = state;

  switch (action.type) {
    case 'SET_YEARLY_PLANS':
      if (action.yearlyPlans) {
        newState = {
          ...state,
          yearlyPlans: action.yearlyPlans
        };
      }
      break;
    case 'SET_CORRESPONDING_YEARLY_PLAN':
      if (action.correspondingYearlyPlan) {
        newState = {
          ...state,
          correspondingYearlyPlan: action.correspondingYearlyPlan
        };
      }
      break;
    case 'SET_SHOW_MODAL':
      if (action.showModal !== undefined) {
        newState = {
          ...state,
          showModal: action.showModal
        };
      }
      break;
    case 'SET_CURRENT_STEP':
      if (action.currentStep) {
        newState = {
          ...state,
          currentStep: action.currentStep
        };
      }
      break;
    case 'SET_CREDIT_CARD':
      if (action.creditCard) {
        newState = {
          ...state,
          creditCard: action.creditCard
        };
      }
      break;
    case 'SET_PAYMENT_TYPE':
      if (action.paymentType) {
        newState = {
          ...state,
          paymentType: action.paymentType
        };
      }
      break;
    case 'SET_DISABLE_CVV_FOR_YEARLY_PLANS':
      if (action.disableCvvFormForYearlyPlan) {
        newState = {
          ...state,
          disableCvvFormForYearlyPlan: action.disableCvvFormForYearlyPlan
        };
      }
      break;
  }
  return newState;
};
