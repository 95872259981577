import React, { useContext, useEffect, useState, useMemo } from 'react'
import classes from './ValuePropPage.module.scss'
import { ConfigContext, PrinterContext, UserContext } from '@/store'
import NextStageButton from '@/components/UI/NextStageButton'
import {
  ANALYTICS,
  BIZ_MODEL_HP_PLUS,
  BIZ_MODEL_STANDARD,
  CONTENT_STACK_TYPES,
  HP_PLUS_PROGRAM_LEVEL,
  OFFER_HINT_SINGLE_SKU,
  VENEER_PRIMARY_BUTTON_APPEARANCE,
  VENEER_SECONDARY_BUTTON_APPEARANCE
} from '@/store/Constants'
import useAnalytics from '@/hooks/useAnalytics'
import Modal from '@veneer/core/dist/scripts/modal'
import Button from '@veneer/core/dist/scripts/button'
import useContentStack from '@/hooks/useContentStack'
import Stepper from '@veneer/core/dist/scripts/stepper'
import { replaceFragments } from '@/utils/Functions'
import { LazyVeneerIcon } from '@/styles/LazyVeneer'

const { BUTTONS, SCREENS, LINKS, MODES } = ANALYTICS

const ValuePropPage = () => {
  const configCtx = useContext(ConfigContext)
  const { isScanSetup, isLfp } = useContext(ConfigContext)
  const { setBizModel, resetPrinterData, isSingleSku } = useContext(
    PrinterContext
  )
  const { getSessionId, resetUserData, isCompanyAccount } = useContext(
    UserContext
  )

  const [showTheseFeatures, setShowTheseFeatures] = useState(false)
  const [continueWithoutBenefits, setContinueWithoutBenefits] = useState(false)

  const {
    programLevel,
    bizModelHint,
    offerHint,
    productFamily,
    entry
  } = configCtx.sessionContext.onboardingContext

  const { screen, modalScreen } = useMemo(() => {
    const isE2E =
      (programLevel &&
        programLevel.toLowerCase() === HP_PLUS_PROGRAM_LEVEL.toLowerCase()) ||
      bizModelHint?.toUpperCase() === BIZ_MODEL_HP_PLUS

    if (isE2E) {
      return {
        screen: SCREENS.ACTIVATE,
        modalScreen: SCREENS.FEATURE_MODAL_ACTIVATE
      }
    }
    if (isSingleSku || offerHint === OFFER_HINT_SINGLE_SKU) {
      return {
        screen: SCREENS.SINGLE_SKU,
        modalScreen: SCREENS.FEATURE_MODAL_SINGLE_SKU
      }
    }
    if (isScanSetup) {
      return {
        screen: SCREENS.SCAN_SETUP,
        modalScreen: SCREENS.FEATURE_MODAL_SCAN_SETUP
      }
    }
    if (isLfp) {
      return {
        screen: SCREENS.LFP,
        modalScreen: SCREENS.FEATURE_MODAL_CONNECT
      }
    }
    return {
      screen: SCREENS.CONNECT,
      modalScreen: SCREENS.FEATURE_MODAL_CONNECT
    }
  }, [bizModelHint, isScanSetup, isLfp, isSingleSku, offerHint, programLevel])

  const getPrinterType = () => {
    if (screen === SCREENS.SINGLE_SKU) {
      return 'single_sku'
    } else if (screen === SCREENS.ACTIVATE) {
      return 'e2e'
    }
    return 'flex'
  }

  let { pageData } = useContentStack({
    content_type: CONTENT_STACK_TYPES.new_value_prop,
    additional_params: {
      printer_type: getPrinterType(),
      experience: configCtx.experience?.toUpperCase(),
      product_family: productFamily,
      entry: entry
    }
  })

  const {
    fireScreenDisplayed,
    fireButtonClick,
    fireHyperLinkClick
  } = useAnalytics(screen)

  const featureModal = useAnalytics(modalScreen)
  const basicSetupAnalytics = useAnalytics(SCREENS.BASIC_SETUP_CONNECT)
  const personalSetupAnalytics = useAnalytics(SCREENS.PERSONAL_SETUP_SINGLE_SKU)

  const dispatchNextStage = (options = undefined) => {
    let nextStage = configCtx.nextStage.bind(undefined, {
      result: 'success',
      xCorrelationId: getSessionId()
    })

    if (options) nextStage = nextStage.bind(undefined, options)
    nextStage()
  }

  const openFeaturesModalClickHandler = (e) => {
    const closestAnchor = e.target.closest('a')
    if (closestAnchor && e.currentTarget.contains(closestAnchor)) {
      setShowTheseFeatures(true)
      fireHyperLinkClick(LINKS.FEATURE_LINK)
      featureModal.fireModalWindowDisplayed()
    }
  }

  const closeFeaturesModalClickHandler = () => {
    setShowTheseFeatures(false)
    featureModal.fireButtonClick(BUTTONS.CLOSE)
  }

  const hpEasyStartClickHandler = (e) => {
    const closestAnchor = e.target.closest('a')
    if (closestAnchor && e.currentTarget.contains(closestAnchor)) {
      basicSetupAnalytics.fireHyperLinkClick(LINKS.HP_EASY_START_LINK)
    }
  }

  const clickHandlerContinue = () => {
    fireButtonClick(BUTTONS.CONTINUE)
    dispatchNextStage()
  }

  const clickHandlerSignIn = () => {
    fireHyperLinkClick(LINKS.SIGN_IN)
    dispatchNextStage({
      authentication: { loginType: 'default' }
    })
  }

  const clickHandlerCreateAccount = () => {
    fireButtonClick(BUTTONS.CREATE_ACCOUNT)
    dispatchNextStage({
      authentication: { loginType: 'createAccount' }
    })
  }

  const withoutBenefitsOpenModal = () => {
    if (screen === SCREENS.SINGLE_SKU) {
      fireHyperLinkClick(LINKS.SETUP_FOR_PERSONAL_USE)
      personalSetupAnalytics.fireModalWindowDisplayed()
    } else {
      fireHyperLinkClick(LINKS.SETUP_WITHOUT_HP_CLOUD_LINK)
      basicSetupAnalytics.fireModalWindowDisplayed()
    }
    setContinueWithoutBenefits(true)
  }

  const withoutBenefitsCloseButton = () => {
    setContinueWithoutBenefits(false)
    if (screen === SCREENS.SINGLE_SKU) {
      personalSetupAnalytics.fireButtonClick(BUTTONS.BACK)
    } else {
      basicSetupAnalytics.fireButtonClick(BUTTONS.CLOSE)
    }
  }

  const withoutBenefitsConfirmButton = () => {
    window.open(pageData.hp_smart_download_link?.href)
    personalSetupAnalytics.fireButtonClick(BUTTONS.HP_SMART_INSTALL_BUTTON)
  }

  useEffect(() => {
    resetPrinterData()
    resetUserData()
    setBizModel(
      screen === SCREENS.ACTIVATE ? BIZ_MODEL_HP_PLUS : BIZ_MODEL_STANDARD
    )
  }, [])

  if (!pageData) return <></>

  if (screen === SCREENS.SINGLE_SKU && isCompanyAccount) {
    fireScreenDisplayed({ mode: MODES.SCREEN.COMPANY })
  } else {
    fireScreenDisplayed()
  }

  const rightSection = pageData.right_section.content
    .filter((item) => item.user_is_logged_in === configCtx.userIsLoggedIn)
    .pop()

  return (
    <div className={classes.valueProp} data-testid={'value-prop-page'}>
      <div className={classes.leftSide} data-testid={'left-side-panel'}>
        <div>
          <img
            className={classes.hpLogo}
            alt={pageData.header}
            src={pageData.hp_logo?.url}
          />
        </div>
        <h3 data-testid={'header'}>{pageData.header}</h3>
        <div
          onClick={openFeaturesModalClickHandler}
          data-testid={'these-features-link'}
          dangerouslySetInnerHTML={{
            __html: replaceFragments(pageData.subheader)
          }}
        />
        <b>{pageData.stepper.next_steps}</b>
        <div className={classes.steps}>
          <Stepper
            data-testid={'stepper'}
            direction={'vertical'}
            steps={(pageData?.stepper?.steps || []).map((step, index) => {
              return {
                label: (
                  <>
                    <div data-testid={`step-text-${index}`}>{step.text}</div>
                    {step?.additional_text && (
                      <div
                        className={classes.additionalText}
                        data-testid={`additional-text-${index}`}
                      >
                        {step?.additional_text}
                      </div>
                    )}
                  </>
                ),
                step: step?.veneer_icon ? (
                  <LazyVeneerIcon icon={step.veneer_icon.icon} />
                ) : (
                  <img alt={step.text} src={step?.icon?.url} />
                )
              }
            })}
          />
        </div>
        {pageData.bottom_page_link && (
          <div className={classes.completeWithoutFeatures}>
            <a
              data-testid={'continue-without-link'}
              onClick={withoutBenefitsOpenModal}
              href="javascript:void(0);"
            >
              {pageData.bottom_page_link}
            </a>
          </div>
        )}
      </div>
      <div className={classes.rightSide} data-testid={'right-side-panel'}>
        <div className={classes.printerImage}>
          <img
            alt={'Gray Printer Image'}
            src={pageData.right_section.printer_image?.url}
          />
        </div>
        <section className={classes.getStarted}>
          {rightSection?.header && (
            <h5
              data-testid={'right-section-header'}
              dangerouslySetInnerHTML={{
                __html: rightSection.header
              }}
            />
          )}
          <p
            data-testid={'right-section-block-text'}
            dangerouslySetInnerHTML={{
              __html: rightSection?.block_text
            }}
          />
        </section>
        <div className={classes.flowOptions}>
          {rightSection?.primary_button && (
            <NextStageButton
              copy={rightSection.primary_button}
              onClick={
                configCtx.userIsLoggedIn
                  ? clickHandlerContinue
                  : clickHandlerCreateAccount
              }
              dataTestId="value-prop-primary"
            />
          )}
          {rightSection?.tertiary_button && (
            <NextStageButton
              copy={rightSection.tertiary_button}
              onClick={clickHandlerSignIn}
              dataTestId="value-prop-tertiary"
              appearance={'ghost'}
            />
          )}
        </div>
      </div>
      <Modal show={showTheseFeatures}>
        <div
          data-testid={'these-features-modal'}
          className={classes.valuePropShowBenefitsModal}
        >
          <h3 data-testid={'these-features-modal-header'}>
            {pageData.these_features_modal?.header}
          </h3>
          <div
            data-testid={'these-features-modal-description'}
            dangerouslySetInnerHTML={{
              __html: pageData.these_features_modal?.description
            }}
          />
          <ul>
            {(pageData?.these_features_modal?.features || []).map(
              (feature, featureIndex) => {
                return (
                  <li key={featureIndex}>
                    <img alt={feature.text} src={feature.icon.url} />
                    <span data-testid={`features-text-${featureIndex}`}>
                      {feature?.text}
                    </span>
                    {feature?.rich_text && (
                      <span
                        data-testid={`features-rich-text-${featureIndex}`}
                        dangerouslySetInnerHTML={{
                          __html: feature.rich_text
                        }}
                      />
                    )}
                  </li>
                )
              }
            )}
          </ul>
          {pageData.these_features_modal?.asterisk_observation && (
            <p
              className={classes.asterisk}
              data-testid={'asterisk-observation'}
            >
              {pageData.these_features_modal?.asterisk_observation}
            </p>
          )}
          {pageData.these_features_modal?.observations && (
            <p
              className={classes.observations}
              data-testid={'observations'}
              dangerouslySetInnerHTML={{
                __html: pageData.these_features_modal?.observations
              }}
            />
          )}
          <Button
            onClick={closeFeaturesModalClickHandler}
            data-testid={'these-features-close-button'}
          >
            {pageData.these_features_modal?.close_button}
          </Button>
        </div>
      </Modal>
      <Modal show={continueWithoutBenefits}>
        <div
          data-testid={'continue-without-modal'}
          className={classes.continueWithoutBenefitsModal}
        >
          <h3 data-testid={'complete-without-features-modal-header'}>
            {pageData.complete_without_features_modal?.header}
          </h3>
          <p
            onClick={hpEasyStartClickHandler}
            data-testid={'hp-easy-start-link'}
            dangerouslySetInnerHTML={{
              __html: pageData.complete_without_features_modal?.content
            }}
          />
          <div className={classes.modalOptions}>
            <Button
              data-testid={'continue-without-close-button'}
              onClick={withoutBenefitsCloseButton}
              appearance={
                pageData.complete_without_features_modal?.confirm_button
                  ? VENEER_SECONDARY_BUTTON_APPEARANCE
                  : VENEER_PRIMARY_BUTTON_APPEARANCE
              }
            >
              {pageData.complete_without_features_modal?.close_button}
            </Button>
            {pageData.complete_without_features_modal?.confirm_button && (
              <Button
                onClick={withoutBenefitsConfirmButton}
                data-testid={'continue-without-confirm-button'}
              >
                {pageData.complete_without_features_modal?.confirm_button}
              </Button>
            )}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ValuePropPage
