import { OptionObject } from '../types'

export function supportedCountries(
  locale: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  locales: Record<string, any>
): OptionObject[] | string[] {
  const countriesAsOption: OptionObject[] = []

  Object.keys(locales).forEach(function (key) {
    const countryParts = key.split('_')
    const country = countryParts[1]

    if (!countriesAsOption.some((option) => option.value === country)) {
      countriesAsOption.push({
        value: country,
        label: locales?.[locale]?.['locales'][key]?.country
      })
    }
  })

  return countriesAsOption
}

export function supportedLanguages(
  locale: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  locales: Record<string, any>
): Record<string, OptionObject[]> {
  const languagesAsOption: Record<string, OptionObject[]> = {}

  Object.keys(locales).forEach(function (key) {
    const [language, country] = key.split('_')

    if (!languagesAsOption[country]) {
      languagesAsOption[country] = [
        {
          value: language,
          label: locales?.[locale]?.['locales'][key]?.language
        }
      ]
    } else if (
      !languagesAsOption[country].some((option) => option.value === language)
    ) {
      languagesAsOption[country].push({
        value: language,
        label: locales?.[locale]?.['locales'][key]?.language
      })
    }
  })

  return languagesAsOption
}

export const supportedIILocales = [
  'us/en',
  'ca/en',
  'ca/fr',
  'uk/en',
  'de/de',
  'fr/fr',
  'es/es',
  'it/it',
  'ie/en',
  'nl/nl',
  'be/fr',
  'be/nl',
  'lu/fr',
  'at/de',
  'pt/pt',
  'se/sv',
  'dk/da',
  'no/no',
  'fi/fi',
  'ch/de',
  'ch/fr',
  'ch/it',
  'cz/cs',
  'pl/pl',
  'hu/hu',
  'gr/el',
  'ro/ro',
  'sk/sk',
  'hr/hr',
  'bg/bg',
  'lv/lv',
  'lt/lt',
  'ee/et',
  'nz/en',
  'mt/en',
  'cy/el',
  'si/sl',
  'au/en',
  'pr/en',
  'sg/en',
  'hk/zh',
  'hk/en',
  'gb/en'
]

const supportedIICountries = supportedIILocales.map(
  (locale) => locale.split('/')[0]
)

export const validateSelectedLocale = (locale: string) => {
  const [language, country] = locale.split('_')
  const countryCode = country.toLowerCase()
  if (supportedIILocales.includes(`${countryCode}/${language}`)) {
    return locale
  }

  if (supportedIICountries.includes(countryCode)) {
    const localeCode = supportedIILocales.find((locale) =>
      countryCode === locale.split('/')[0] ? locale : undefined
    )
    if (localeCode) {
      const [cc, lang] = localeCode.split('/')
      return `${lang}_${cc.toUpperCase()}`
    }
  }

  return 'en_US'
}
