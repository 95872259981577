import { ISessionService } from '../../../services/session';
import type { ITenantHandlerService } from '../../../services/tenantHandler';
import bindAllMethods from '../../../utils/bindAllMethods';
import { getServices } from '../../../infra/commonInitializer';
import * as T from './types';
import {
  AuthContextEnum,
  IAuthTokenService
} from '../../../services/authTokenService';

class SessionInterfaceV1 {
  private _sessionService: ISessionService;
  private _tenantHandler: ITenantHandlerService;
  private _authTokenService: IAuthTokenService;

  constructor() {
    const services = getServices();
    this._sessionService = services.sessionService;
    this._tenantHandler = services.tenantHandlerService;
    this._authTokenService = services.authTokenService;
    bindAllMethods(this);
  }

  public getInterface(): T.SessionInterfaceV1Type {
    return {
      logout: this._sessionService.logout,
      refreshToken: this._refreshToken,
      isLoggedIn: () => this._sessionService.isLoggedIn(),
      getIdToken: this._sessionService.getIdToken,
      getProviderList: this._sessionService.getProviderList,
      generateAuthenticationUrl: this._sessionService.generateAuthenticationUrl,
      signIn: this._sessionService.signIn,
      /**
       *  It checks if the user is loggedIn but not necessarily ready to use the application. For that use the isLoggedInWithTenant().
       */
      isLoggedInWithTenant: () => {
        try {
          const isLoggedIn = this._sessionService.isLoggedIn();
          const tenantId = this._authTokenService.getTenantIdFromToken();
          if (isLoggedIn && tenantId) {
            return true;
          }
          return false;
        } catch (error) {
          console.error('Error in isLoggedInWithTenant', error);
          return false;
        }
      }
    };
  }

  private _refreshToken(): Promise<void> {
    const tenantsIdMap = this._tenantHandler.getTenantIdsMap();
    return this._sessionService.refresh({ tenantsIdMap });
  }
}

export default SessionInterfaceV1;
