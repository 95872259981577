import React, { useCallback, useMemo } from 'react'
import { FETCH_STATUS } from '@/store/Constants'
import TextHeader, { TextSubHeader } from '@/components/UI/TextHeader'
import { InputsPartnerLink } from '../InputsPartnerLink'
import { LazyVeneerIcon, VeneerIcons } from '@/styles/LazyVeneer'

import classes from './ContentSectionPartnerLink.module.scss'

export function ContentSectionPartnerLink({
  pageData,
  status,
  accountId,
  setAccountId
}) {
  const isSuccess = useMemo(() => status === FETCH_STATUS.SUCCESS, [status])
  const isFailure = useMemo(() => status === FETCH_STATUS.FAILURE, [status])

  const isConnectionPending = useMemo(() => !isSuccess && !isFailure, [
    isSuccess,
    isFailure
  ])

  const finishSetupCopy = useMemo(
    () =>
      isSuccess ? pageData.finish_setup : pageData.finish_setup.connect_later,
    [pageData, isSuccess]
  )

  const getFinishSetupHeaderIcon = useCallback(() => {
    const iconProps = {
      filled: true,
      size: 40,
      className: classes.iconCheckmark
    }
    return (
      !isConnectionPending &&
      (isSuccess ? (
        <LazyVeneerIcon
          {...iconProps}
          icon={VeneerIcons.CheckmarkCircle}
          color="hunterGreen7"
        />
      ) : (
        <LazyVeneerIcon
          {...iconProps}
          icon={VeneerIcons.XCircle}
          color="red6"
        />
      ))
    )
  }, [isConnectionPending, isSuccess])

  const getFinishSetupHeader = useCallback(
    () => (
      <div className={classes.headerContainer}>
        <span>{finishSetupCopy.header}</span>
        {getFinishSetupHeaderIcon()}
      </div>
    ),
    [finishSetupCopy, getFinishSetupHeaderIcon]
  )

  const header = useMemo(
    () => (isConnectionPending ? pageData.header : getFinishSetupHeader()),
    [isConnectionPending, getFinishSetupHeader, pageData]
  )

  const subheaderHtml = useMemo(
    () => (isConnectionPending ? pageData.body : finishSetupCopy.subheader),
    [isConnectionPending, finishSetupCopy, pageData]
  )

  return (
    <div className={classes.contentSectionPartnerLink}>
      <div>
        <TextHeader>{header}</TextHeader>
        <TextSubHeader dangerouslySetInnerHTML={{ __html: subheaderHtml }} />
      </div>
      {isConnectionPending && (
        <InputsPartnerLink
          pageData={pageData.inputs}
          status={status}
          setAccountId={setAccountId}
          accountId={accountId}
        />
      )}
    </div>
  )
}
