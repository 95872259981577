import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useDimensions from 'react-use-dimensions'
import Button from '@veneer/core/dist/scripts/button/button'
import IconTrash from '@veneer/core/dist/scripts/icons/icon_trash'
import IconPencil from '@veneer/core/dist/scripts/icons/icon_pencil'
import Table, { TableSortBy, ColumnIndexTypes } from '@veneer/core/dist/scripts/table'
import Tooltip from '@veneer/core/dist/scripts/tooltip'
import NoItemsWarning from '../NoItemsWarning'
import { InviteUser, InviteUserConfig, SendInvitesState } from '../../interfaces/inviteUsersInterface'
import RemoveUserModal from '../../shared-components/Modals/RemoveUserModal'
import { sort } from '../../utils/commonMethods'
import { MODAL_TYPE } from '../../utils/enum'
import { applyCustomRoleAndStatusNames } from '../../utils/customRoles'
import useRootContext from '../../contexts/Root/useRootContext'
import usePreferencesContext from '../../contexts/Preferences/usePreferencesContext'
import EditUser from './editUser'
import { getl10n } from '../../utils/localizationMap'
import { PermissionSet } from '../../utils/permissionSet'
import { InviteItemTooltip } from './styles'
import { ActiveUser } from '../../interfaces/manageUsersInterface'

export type SendInvitesProps = {
  disableTableActions: boolean
  onUserDelete: (id: number) => void
  onUsersUpdate: (newEmail: string, newRole: string, id: number) => void
  usersToInvite: InviteUser[]
  permissionSet: PermissionSet
  activeUser: ActiveUser
  rolesDataSource: string
}

const SendInvites = ({
  disableTableActions,
  onUserDelete,
  onUsersUpdate,
  usersToInvite,
  permissionSet,
  activeUser,
  rolesDataSource
}: SendInvitesProps) => {
  const { shell } = useRootContext()
  const { localization } = shell
  const { t } = localization.useReactTranslatorHook()
  const [ref] = useDimensions()

  const { inviteUsers: inviteUsersPreferences } = usePreferencesContext()
  const { inviteList: inviteListPreferences } = inviteUsersPreferences || {}
  const { displaySimplifiedNoEmailWarning } = inviteListPreferences || {}

  const [sendInvitesState, setSendInvitesState] = useState<SendInvitesState>({
    emailToEdit: '',
    id: null,
    roleToEdit: '',
    showDeleteModal: false,
    showEditModal: false
  })
  const [usersData, setUsersData] = useState<Array<InviteUserConfig>>([])

  const handleModal = useCallback(
    (type: MODAL_TYPE, email: string, rolePermId = null, id?: number) => {
      setSendInvitesState({
        ...sendInvitesState,
        emailToEdit: email,
        id: id,
        roleToEdit: rolePermId,
        showDeleteModal: type === MODAL_TYPE.Delete,
        showEditModal: type === MODAL_TYPE.Edit
      })
    },
    [sendInvitesState]
  )

  const editButton = useCallback(
    (email: string, rolePermId: string, id: number, disableTableActions: boolean) => (
      <Button
        appearance="ghost"
        data-testid="edit-button"
        disabled={disableTableActions}
        id="edit-button"
        leadingIcon={<IconPencil />}
        onClick={() => handleModal(MODAL_TYPE.Edit, email, rolePermId, id)}
        small
      />
    ),
    [handleModal]
  )

  const deleteButton = useCallback(
    (email: string, rolePermId: string, id: number, disableTableActions: boolean) => (
      <Button
        appearance="ghost"
        data-testid="delete-button"
        disabled={disableTableActions}
        id="delete-button"
        leadingIcon={<IconTrash />}
        onClick={() => handleModal(MODAL_TYPE.Delete, email, rolePermId, id)}
        small
      />
    ),
    [handleModal]
  )

  const setTooltip = useCallback(
    (email: string) => (
      <Tooltip
        arrow
        content={
          <div>
            <label>{email}</label>
          </div>
        }
        contentHideDelayOnHover={0}
        id={email && email.length > 32 ? 'tootip' : 'notooltip'}
        placement="bottom"
        portal
        ref={ref}
        useJsPositioning
      >
        <p>
          <InviteItemTooltip>{email}</InviteItemTooltip>
        </p>
      </Tooltip>
    ),
    [ref]
  )

  useEffect(() => {
    const inviteUserData: InviteUserConfig[] = usersToInvite.map((user: InviteUserConfig) => {
      return {
        ...user,
        edit: editButton(user.email, user.rolePermId, user.id, disableTableActions),
        emailwithtooltip: setTooltip(user.email),
        trash: deleteButton(user.email, user.rolePermId, user.id, disableTableActions)
      }
    })

    setUsersData(applyCustomRoleAndStatusNames(permissionSet, inviteUserData))
  }, [usersToInvite, disableTableActions, t, editButton, setTooltip, deleteButton, permissionSet])

  const onUserUpdate = (newEmail: string, newRolePermId: string, id: number) => {
    setSendInvitesState({
      ...sendInvitesState,
      showEditModal: !sendInvitesState.showEditModal
    })
    onUsersUpdate(newEmail, newRolePermId, id)
  }

  const deleteAddedEmail = () => {
    setSendInvitesState({ ...sendInvitesState, showDeleteModal: false })
    onUserDelete(sendInvitesState.id)
  }

  const customNoItems = useMemo(
    () =>
      displaySimplifiedNoEmailWarning ? (
        <p className="label">{t(getl10n('noItems'))}</p>
      ) : (
        <NoItemsWarning data-testid="no-items" />
      ),
    [displaySimplifiedNoEmailWarning, t]
  )

  const columns = useMemo(
    () =>
      [
        {
          id: 'emailwithtooltip',
          label: t(getl10n('emailAddress')),
          index: 'visible' as ColumnIndexTypes,
          sortable: true
        },
        {
          id: 'roleLabel',
          label: t(getl10n('role')),
          sortable: false,
          index: 'visible' as ColumnIndexTypes
        },
        {
          id: 'edit',
          label: '',
          index: 'visible' as ColumnIndexTypes,
          sortable: true
        },
        {
          id: 'trash',
          label: '',
          index: 'visible' as ColumnIndexTypes,
          sortable: false
        }
      ].map(({ label, ...rest }) => ({ ...rest, label: label.toUpperCase() })),
    [t]
  )

  return (
    <div>
      <Table
        customNoItems={customNoItems}
        columns={columns}
        data-testid="invite-user-table"
        data={usersData}
        onSort={(_: React.MouseEvent<HTMLElement, MouseEvent>, { type }: TableSortBy) => {
          sort(usersData, 'email', type)
        }}
        preferences={{
          sortBy: {
            id: 'emailwithtooltip',
            type: 'ascending'
          },
          width: [
            {
              columnId: 'emailwithtooltip',
              width: 240
            },
            {
              columnId: 'roleLabel',
              width: 100
            },
            {
              columnId: 'edit',
              maxWidth: 60,
              width: 60
            },
            {
              columnId: 'trash',
              maxWidth: 60,
              width: 60
            }
          ]
        }}
      />

      {sendInvitesState.showEditModal && (
        <EditUser
          email={sendInvitesState.emailToEdit}
          handleClose={() => setSendInvitesState({ ...sendInvitesState, showEditModal: false })}
          id={sendInvitesState.id}
          onUserUpdate={onUserUpdate}
          rolePermId={sendInvitesState.roleToEdit}
          permissionSet={permissionSet}
          activeUser={activeUser}
          rolesDataSource={rolesDataSource}
        />
      )}

      {sendInvitesState.showDeleteModal && (
        <RemoveUserModal
          data-testid="remove-user-modal"
          forFilterScreen={false}
          hideContentModalRemoveUser={false}
          modalSubcontent
          modalContent={t(getl10n('removeModalContent'))}
          modalTitle={t(getl10n('removeModalTitle'))}
          onClose={() => {
            setSendInvitesState({ ...sendInvitesState, showDeleteModal: false })
          }}
          onDelete={deleteAddedEmail}
          showModal={sendInvitesState.showDeleteModal}
          isLoading={false}
        />
      )}
    </div>
  )
}

export default SendInvites
