import React, { useContext } from 'react'
import { UserContext } from '@/store'
import classes from './FeaturesList.module.scss'
import { LazyVeneerIcon } from '@/styles/LazyVeneer'

const FeaturesList = ({ copy, isModal = false }) => {
  const { isHpPlusCountry } = useContext(UserContext)

  return (
    <div
      className={classes.benefitsGroup}
      data-testid={`features-list${isModal ? '-modal' : ''}`}
    >
      <ul>
        {copy
          .filter(
            (feature) =>
              (feature.show_when_is_hp_plus_country && isHpPlusCountry) ||
              (feature.show_when_is_not_hp_plus_country && !isHpPlusCountry)
          )
          .map((feature, featureIndex) => {
            return (
              <li key={featureIndex} data-testid="feature-item">
                <i>
                  {feature?.veneer_icon?.icon ? (
                    <LazyVeneerIcon icon={feature?.veneer_icon.icon} size={36} />
                  ) : (
                    <img
                      data-testid={`feature-${featureIndex}-icon`}
                      alt={feature.text}
                      src={feature.icon.url}
                    />
                  )}
                </i>
                <p data-testid={`feature-${featureIndex}-text`}>
                  {feature.text}
                </p>
              </li>
            )
          })}
      </ul>
    </div>
  )
}

export default FeaturesList
