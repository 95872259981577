import React from 'react'
import { TranslationProvider } from '../../contexts/TranslationProvider'
import { GapHandlerModal } from './GapHandlerModal/GapHandlerModal'
import { validateSelectedLocale } from '../../helpers'

export interface GapHandlerProps {
  /* showGapHandlerModal: Open or close the Gap Handler modal */
  showGapHandlerModal: boolean

  /* setShowGapHandlerModal: Set the state of the Gap Handler modal to close it */
  setShowGapHandlerModal?: (showGapHandlerModal: boolean) => void

  /* selectedLocale: The selected locale
   * Should be like 'en_US', 'fr_CA'
   * */
  selectedLocale: string

  /* accountLocale: The locale registered on the account
   * Should be like 'en_US', 'fr_CA'
   * */
  accountLocale: string

  /* onCreateNewAccount: Function to redirect to create a new account
   * Will be send the locale code as parameter.
   * i.e. 'en_US', 'fr_CA'
   * */
  onCreateNewAccount: (locale: string) => void

  /* onExitFlow: Function to exit the flow and cancel the enrollment */
  onExitFlow?: () => void

  /* onContinueEnroll: Function to continue the enrollment */
  onContinueEnrollment?: () => void

  /* isUnsupportedCountry: Flag to show the unsupported country message */
  isUnsupportedCountry?: boolean
}

export const GapHandler = (props: GapHandlerProps) => {
  const locale = validateSelectedLocale(props.selectedLocale)
  const [selectedLanguage, selectedCountry] = locale.split('_')
  return (
    <TranslationProvider
      language={selectedLanguage}
      country={selectedCountry}
      componentName="GapHandler"
    >
      <GapHandlerModal {...props} />
    </TranslationProvider>
  )
}

GapHandler.defaultProps = {
  showGapHandlerModal: false,
  setShowGapHandlerModal: () => null,
  selectedLocale: 'en_US',
  accountLocale: 'en_CA',
  onCreateNewAccount: () => null,
  onExitFlow: () => null,
  onContinueEnrollment: () => null,
  isUnsupportedCountry: false
}
