import {
  BillingAddress,
  BillingFormAction,
  BillingFormActionType,
  BillingFormState,
  BillingFlowSteps,
  PaymentType,
  TaxpayerDetails
} from '../types'
import { AsyncAction, AsyncDispatch } from '../hooks/useAsyncReducer'
import { AxiosResponse } from 'axios'

const {
  FETCH_CRITICAL_SCOPES,
  FETCH_CRITICAL_SCOPES_SUCCESS,
  FETCH_CRITICAL_SCOPES_FAIL,
  FETCH_SETTINGS,
  FETCH_SETTINGS_SUCCESS,
  FETCH_SETTINGS_FAIL,
  FETCH_BILLING_INFO,
  FETCH_BILLING_INFO_SUCCESS,
  FETCH_BILLING_INFO_FAIL,
  FETCH_TAXPAYER_DETAILS,
  FETCH_TAXPAYER_DETAILS_SUCCESS,
  FETCH_TAXPAYER_DETAILS_FAIL,
  SAVE_TAXPAYER_DETAILS,
  SAVE_TAXPAYER_DETAILS_SUCCESS,
  SAVE_TAXPAYER_DETAILS_FAIL,
  FETCH_BILLING_ADDRESS,
  FETCH_BILLING_ADDRESS_SUCCESS,
  FETCH_BILLING_ADDRESS_FAIL,
  SAVE_BILLING_ADDRESS,
  SAVE_BILLING_ADDRESS_SUCCESS,
  SAVE_BILLING_ADDRESS_FAIL,
  FETCH_SHIPPING_ADDRESS,
  FETCH_SHIPPING_ADDRESS_SUCCESS,
  FETCH_SHIPPING_ADDRESS_FAIL,
  FETCH_PHC_EVENT_STATUS,
  FETCH_PHC_EVENT_STATUS_SUCCESS,
  FETCH_PHC_EVENT_STATUS_FAIL,
  CREATE_PHC_EVENT_STATUS,
  CREATE_PHC_EVENT_STATUS_SUCCESS,
  CREATE_PHC_EVENT_STATUS_FAIL,
  FETCH_USER_ACCOUNT,
  FETCH_USER_ACCOUNT_SUCCESS,
  FETCH_USER_ACCOUNT_FAIL,
  SET_ASSETS_PROVIDER,
  SET_PAYMENT_TYPE,
  SET_BILLING_ADDRESS,
  SET_CONTAINTER_SIZE,
  SET_ITALY_TAX_FIELD,
  PUSH_CURRENT_STEP,
  POP_CURRENT_STEP
} = BillingFormActionType

export const setAssetsProviderAction = (
  language: string,
  country: string
): BillingFormAction => ({
  type: SET_ASSETS_PROVIDER,
  language,
  country
})

export const setPaymentTypeAction = (
  paymentType: PaymentType
): BillingFormAction => ({
  type: SET_PAYMENT_TYPE,
  paymentType
})

export const setBillingAddressAction = (address?: BillingAddress) => ({
  type: SET_BILLING_ADDRESS,
  address
})

export const setItalyTaxFieldTypeAction = (
  italyTaxFieldType: 'taxId' | 'nonProfitTaxId'
) => ({
  type: SET_ITALY_TAX_FIELD,
  italyTaxFieldType
})

export const setContainerSizeAction = (containerSize: string) => ({
  type: SET_CONTAINTER_SIZE,
  containerSize
})

export const pushCurrentStepAction = (currentStep: BillingFlowSteps) => ({
  type: PUSH_CURRENT_STEP,
  currentStep
})

export const popCurrentStepAction = () => ({
  type: POP_CURRENT_STEP
})

export const validateBillingAddressAction = ({
  field,
  value,
  requiredFields
}: {
  field?: string
  value?: string
  requiredFields?: string[]
}) => ({
  type: BillingFormActionType.VALIDATE_BILLING_ADDRESS,
  field,
  value,
  requiredFields
})

export const validateTaxpayerDetailsAction = (
  {
    ignoreEmptyFields
  }: {
    ignoreEmptyFields: boolean
  } = {
    ignoreEmptyFields: false
  }
) => ({
  type: BillingFormActionType.VALIDATE_TAXPAYER_DETAILS,
  ignoreEmptyFields
})

export const updateTaxpayerDetailAccountTypeAction = (
  taxpayerDetails: TaxpayerDetails
) => ({
  type: BillingFormActionType.UPDATE_TAXPAYER_DETAIL_ACCOUNT_TYPE,
  taxpayerDetails
})

export const updateTaxpayerDetailAction = ({
  field,
  value
}: {
  field: string
  value?: string
}) => ({
  type: BillingFormActionType.UPDATE_TAXPAYER_DETAIL,
  field,
  value
})

export const clearItalyTaxpayerDetailAction = () => ({
  type: BillingFormActionType.CLEAR_ITALY_TAXPAYER_DETAILS
})

function createAsyncAction<T>(
  action: BillingFormActionType,
  successAction: BillingFormActionType,
  failAction: BillingFormActionType,
  getResponse: (state: BillingFormState) => Promise<AxiosResponse<T>>
): AsyncAction<BillingFormAction, BillingFormState, T> {
  return async (
    dispatch: AsyncDispatch<BillingFormAction, BillingFormState, T>,
    getState: () => BillingFormState
  ) => {
    try {
      await dispatch({ type: action })
      const response = await getResponse(getState())
      await dispatch({ type: successAction, response })
      return response.data
    } catch (error) {
      await dispatch({ type: failAction, error })
      throw error
    }
  }
}

export const fetchCriticalScopesAction = () =>
  createAsyncAction(
    FETCH_CRITICAL_SCOPES,
    FETCH_CRITICAL_SCOPES_SUCCESS,
    FETCH_CRITICAL_SCOPES_FAIL,
    ({ billingService }) => billingService.getCriticalScopes()
  )
export const fetchSettingsAction = () =>
  createAsyncAction(
    FETCH_SETTINGS,
    FETCH_SETTINGS_SUCCESS,
    FETCH_SETTINGS_FAIL,
    ({ billingService }) => billingService.getSettings()
  )

export const fetchBillingInfoAction = () =>
  createAsyncAction(
    FETCH_BILLING_INFO,
    FETCH_BILLING_INFO_SUCCESS,
    FETCH_BILLING_INFO_FAIL,
    ({ billingService }) => billingService.getBillingInfo()
  )

export const fetchTaxpayerDetailsAction = () =>
  createAsyncAction(
    FETCH_TAXPAYER_DETAILS,
    FETCH_TAXPAYER_DETAILS_SUCCESS,
    FETCH_TAXPAYER_DETAILS_FAIL,
    ({ billingService }) => billingService.getTaxpayerDetails()
  )

export const saveTaxpayerDetailsAction = () =>
  createAsyncAction(
    SAVE_TAXPAYER_DETAILS,
    SAVE_TAXPAYER_DETAILS_SUCCESS,
    SAVE_TAXPAYER_DETAILS_FAIL,
    ({ billingService, taxpayerDetails }) =>
      billingService.saveTaxpayerDetails(taxpayerDetails as TaxpayerDetails)
  )

export const fetchBillingAddressAction = () =>
  createAsyncAction(
    FETCH_BILLING_ADDRESS,
    FETCH_BILLING_ADDRESS_SUCCESS,
    FETCH_BILLING_ADDRESS_FAIL,
    ({ billingService }) => billingService.getBillingAddress()
  )

export const saveBillingAddressAction = () =>
  createAsyncAction(
    SAVE_BILLING_ADDRESS,
    SAVE_BILLING_ADDRESS_SUCCESS,
    SAVE_BILLING_ADDRESS_FAIL,
    ({ billingService, billingAddress }) =>
      billingService.saveBillingAddress(billingAddress as BillingAddress)
  )

export const fetchShippingAddressAction = () =>
  createAsyncAction(
    FETCH_SHIPPING_ADDRESS,
    FETCH_SHIPPING_ADDRESS_SUCCESS,
    FETCH_SHIPPING_ADDRESS_FAIL,
    ({ billingService }) => billingService.getShippingAddress()
  )

export const fetchPhcEventStatusAction = ({
  orderId,
  pickupId
}: { orderId?: string; pickupId?: string } = {}) =>
  createAsyncAction(
    FETCH_PHC_EVENT_STATUS,
    FETCH_PHC_EVENT_STATUS_SUCCESS,
    FETCH_PHC_EVENT_STATUS_FAIL,
    ({ billingService, billingInfo }) =>
      billingService.getPhcEvent(orderId || billingInfo?.orderId, pickupId)
  )

export const createPhcEventStatusAction = () =>
  createAsyncAction(
    CREATE_PHC_EVENT_STATUS,
    CREATE_PHC_EVENT_STATUS_SUCCESS,
    CREATE_PHC_EVENT_STATUS_FAIL,
    ({ billingService }) => billingService.createPhcEvent()
  )

export const fetchUserAccountAction = () =>
  createAsyncAction(
    FETCH_USER_ACCOUNT,
    FETCH_USER_ACCOUNT_SUCCESS,
    FETCH_USER_ACCOUNT_FAIL,
    ({ stratusService }) => stratusService.getUserAccount()
  )
