import { createGlobalStyle, css } from 'styled-components'

export const HpxGlobalStyleOverride = createGlobalStyle`
  && {
    ${({ theme: { isHpx } }) =>
      isHpx &&
      css`
        body {
          .instant-ink-enroll-hpx-button-override {
            &[disabled] {
              color: #ffffff;
            }
          }
        }
      `}
  }
`
