import AuthenticationProviderEnum from '../../config/authenticationProviderEnum';
import { Stack } from '../../types/stratus';

import {
  getPlatform as getPlatformJWeb,
  isNative as isNativeJWeb
} from '../../services/JWeb';
import { TenantStrategyEnum } from '../../services/tenantHandler/strategy/strategy';

import { container } from 'tsyringe';
import { AnalyticsServiceInputType } from '../../services/AnalyticsService/types';
import { TenantHandlerParamsType } from '../../services/tenantHandler/strategy/external-types';
import { PlatformEnum } from './enums';
import * as T from './types';
import proccessWebManifestIntoAppManifest from './proccessWebManifestIntoAppManifest';
import { ApplicationContextEnum } from '../../services/applicationService/enums';

export default class ManifestToCommonsAdapter {
  public static convertToInterfaces(
    manifest: any
  ): T.CommonsInitializeInterfaceType {
    const adapter: T.AdapterInputType = manifest?.adapter;

    // TODO: Update const analytics to remove duplicated code.
    const analytics: AnalyticsServiceInputType = {
      isNative: false, // At this point, I'm faking this value (it wont be used) but should be fixed.
      userActivity: manifest?.services?.userActivity,
      publishErrors: !!manifest?.services?.analytics?.publishErrors,
      enabled: !!manifest?.services?.analytics?.enable,
      batchEnabled: manifest?.services?.analytics?.batchEnabled,
      batchConfiguration: manifest?.services?.analytics?.batchConfiguration,
      queueConfiguration: manifest?.services?.analytics?.queueConfiguration,
      telemetryAPIkey: manifest?.services?.analytics?.telemetryAPIkey,
      customAnalyticsSubsystem:
        manifest?.services?.analytics?.customAnalyticsSubsystem,
      valveControllerAPIkey:
        manifest?.services?.analytics?.valveControllerAPIkey,
      stack: manifest?.portal?.stack as string
    };

    const fallbackConfiguration = {
      defaultFallbackKey: manifest?.fallback?.defaultFallbackKey,
      defaultFallback: {
        strategy: 'redirect',
        redirectTo: manifest?.portal?.fallbackRoute || '/',
        key: undefined
      },
      fallbackList: manifest?.fallback?.fallbackList
    };

    const breadcrumbInput: T.BreadcrumbInputType =
      manifest?.services?.breadcrumb;

    // @ts-ignore
    const stack: T.Stack = Stack[manifest?.portal?.stack];

    const accessControl: T.AccessControlInputType = {
      enabled: !!manifest?.services?.accessControl?.enabled
    };

    const entitlements: T.EntitlementsInputType = {
      enabled: !!manifest?.services?.entitlements?.enabled,
      stack: manifest?.portal?.stack
    };

    const onboarding: T.OnboardingInputType = {
      userOnboardingPath: manifest?.services?.onboarding?.userOnboardingPath,
      enable: !!manifest?.services?.onboarding?.enable,
      stack: manifest?.portal?.stack,
      ...manifest?.services?.onboarding
    };

    const optimizely: T.OptimizelyInputType = {
      enable: !!manifest?.services?.optimizely?.enable,
      snippetId: manifest?.services?.optimizely?.snippetId
    };

    const userOnboarding: T.UserOnboardingInputType = {
      enable: manifest?.services?.userOnboarding?.enable,
      path: manifest?.services?.userOnboarding?.path,
      forceUserOnboardingCriterionKey:
        manifest?.services?.userOnboarding?.forceUserOnboardingCriterionKey
    };

    const logger: T.LoggerInputType = manifest?.services?.logger;

    const criterions: T.CriterionInputType<any>[] = manifest?.criterions;

    const localization: T.LocalizationServiceInputType =
      manifest?.services?.localization;

    const authenticationProvider = manifest?.services?.login
      ?.authenticationProvider
      ? AuthenticationProviderEnum[
          manifest?.services?.login?.authenticationProvider
        ]
      : AuthenticationProviderEnum.authz;

    const login: T.LoginInputType = {
      enabled: !!manifest?.services?.login?.enable,
      assetReference: manifest?.services?.login?.assetReference,
      authenticationProvider,
      emailGathering: manifest?.services?.login?.emailGathering,
      properties: manifest?.services?.login?.properties,
      postLoginRedirect: manifest?.services?.login?.postLoginRedirect,
      preLoginValidation: manifest?.services?.login?.preLoginValidation,
      additionalAuthorizationParameters:
        manifest?.services?.login?.additionalAuthorizationParameters
    };

    const navigation: T.NavigationServiceInputType = {
      useLegacyNavigation: manifest?.navigation?.useLegacyNavigation,
      removeLocaleUrl: manifest?.services?.localization?.removeLocaleUrl,
      blockNavigation: {
        pathList: manifest?.services?.navigation?.blockNavigation?.pathList
      }
    };

    const event: T.EventServiceInputType = {
      removeSelfFilterByContainerInstanceId:
        manifest?.services?.event?.removeSelfFilterByContainerInstanceId
    };

    const mock: T.CommonsMockType = {
      ...manifest?.mock,
      enable: !!manifest?.mock?.enable
    };

    return {
      mock,
      accessControl,
      adapter,
      analytics,
      breadcrumb: breadcrumbInput,
      criterions,
      entitlements,
      fallbackConfiguration,
      localization,
      logger,
      login,
      navigation,
      onboarding,
      optimizely,
      userOnboarding,
      stack,
      event
    };
  }

  public static async convert(manifest: any): Promise<T.CommonsInitializeType> {
    const appName = manifest?.portal?.appName;

    const basePath = manifest?.portal?.basePath;

    const clientId = manifest?.portal?.clientId;

    const emailGathering = manifest?.services?.login?.emailGathering || false;

    const isNative = await isNativeJWeb();

    if (isNative) {
      await proccessWebManifestIntoAppManifest(manifest);
    }

    const applicationContext = (() => {
      if (manifest?.portal?.platform === PlatformEnum.nativeOnly)
        return ApplicationContextEnum.NATIVE_APP;
      if (manifest?.portal?.clientId)
        return isNative
          ? ApplicationContextEnum.NATIVE_PORTAL
          : ApplicationContextEnum.WEB_PORTAL;
    })();

    const jWebPlatform = await getPlatformJWeb();

    const abTesting: T.ABTestingInputType = {
      enabled: !!manifest?.services?.abTesting?.enabled,
      clientName: manifest?.services?.abTesting?.clientName,
      snippetId: manifest?.services?.abTesting?.snippetId
    };

    const consent: T.ConsentServiceInputType = {
      isEmailGathering: emailGathering,
      isNative: isNative
    };

    const localization: T.LocalizationServiceInputType =
      manifest?.services?.localization;

    const navigation: T.NavigationServiceInputType = {
      useLegacyNavigation: manifest?.navigation?.useLegacyNavigation,
      removeLocaleUrl: manifest?.services?.localization?.removeLocaleUrl,
      blockNavigation: {
        pathList: Array.isArray(
          manifest?.services?.navigation?.blockNavigation?.pathList
        )
          ? manifest?.services?.navigation?.blockNavigation?.pathList
          : []
      }
    };

    container.register('NavigationServiceProps', {
      useValue: navigation
    });

    const serviceWorker: T.ServiceWorkerInputType =
      manifest?.services?.serviceWorker;

    // @ts-ignore
    const stack: T.Stack = Stack[manifest?.portal?.stack];

    const theme: T.ThemeInputType = manifest?.theme;

    container.register('IsNative', {
      useValue: isNative
    });

    // TODO: Fix the retrocompatibility with orgSelector
    ManifestToCommonsAdapter.adaptOrgAndCustomerSelectorIntoTenantHandler(
      manifest
    );

    const analytics: AnalyticsServiceInputType = {
      isNative: isNative,
      userActivity: manifest?.services?.userActivity,
      publishErrors: !!manifest?.services?.analytics?.publishErrors,
      enabled: !!manifest?.services?.analytics?.enable,
      batchEnabled: manifest?.services?.analytics?.batchEnabled,
      batchConfiguration: manifest?.services?.analytics?.batchConfiguration,
      queueConfiguration: manifest?.services?.analytics?.queueConfiguration,
      telemetryAPIkey: manifest?.services?.analytics?.telemetryAPIkey,
      customAnalyticsSubsystem:
        manifest?.services?.analytics?.customAnalyticsSubsystem,
      valveControllerAPIkey:
        manifest?.services?.analytics?.valveControllerAPIkey,
      stack: manifest?.portal?.stack as string
    };

    const userActivity: T.UserActivityInputType =
      manifest?.services?.userActivity;

    const authenticationProvider = manifest?.services?.login
      ?.authenticationProvider
      ? AuthenticationProviderEnum[
          manifest?.services?.login?.authenticationProvider
        ]
      : AuthenticationProviderEnum.authz;

    const authProviders: T.AuthProvidersPropsType = {
      initialAuthenticationProvider:
        manifest?.services?.login?.initialAuthenticationProvider ||
        authenticationProvider,
      authenticationProvider: authenticationProvider
    };

    container.register('AuthProviderProps', {
      useValue: authProviders
    });

    const login: T.LoginInputType = {
      enabled: !!manifest?.services?.login?.enable,
      assetReference: manifest?.services?.login?.assetReference,
      authenticationProvider,
      emailGathering: emailGathering,
      additionalAuthorizationParameters:
        manifest?.services?.login?.additionalAuthorizationParameters
    };

    const defaultLayoutKey = manifest?.navigation?.defaultLayoutKey;

    const defaultCriterionKey = manifest?.navigation?.defaultCriterionKey;

    const ast: T.AstInputType = {
      enabled: !!manifest?.services?.ast?.enable
    };

    const featureFlags: T.FeatureFlagsInputType = {
      enabled: !!manifest?.services?.featureFlags?.enable,
      projectSettingsList: manifest?.services?.featureFlags?.clients
    };

    const routes: T.RoutesServiceDependenciesInputType = {
      defaultLayoutKey,
      routes: manifest?.navigation?.routes,
      defaultCriterionKey
    };

    const manifestTenantHandler = manifest?.services?.tenantHandler;

    const tenantHandlerProps: T.TenantHandlerInputType = {
      enabled: !!manifestTenantHandler?.enable,
      tenantHandlerList: manifestTenantHandler?.tenantHandlerList,
      globalTenantHandler: manifestTenantHandler?.globalTenantHandler,
      tenantSelectorAssetReference: manifestTenantHandler?.globalTenantHandler,
      stack: stack,
      isNative: isNative
    };

    const monitoring: T.MonitoringInputType = {
      enabled: !!manifest?.services?.splunk?.enabled
    };

    const backgroundTasks: T.BackgroundTasksInputType = {
      enabled: !!manifest?.services?.backgroundTasks?.enable,
      backgroundTaskRegistrarList:
        manifest?.services?.backgroundTasks?.backgroundTaskRegistrarList
    };

    const grants: T.GrantsInputType = {
      enabled: manifest?.services?.grants?.enabled
    };
    const grantsHistory: T.GrantHistoryInputType = {
      enabled: manifest?.services?.grantsHistory?.enabled
    };

    const graphql: T.GraphQLServiceInputType = {
      mock: !!manifest?.services?.graphql?.mock,
      platform: manifest?.services?.graphql?.platform,
      isShellPortal: !!manifest?.services?.graphql?.isShellPortal,
      serverUrl: manifest?.services?.graphql?.serverUrl,
      stack: manifest?.portal?.stack,
      XApiKey: manifest?.services?.graphql?.XApiKey
    };

    const layouts: T.LayoutInputType[] = manifest?.layouts;

    const urlService: T.URLServiceInputType = {
      languageMap: localization?.languages,
      manifestBasePath: basePath,
      removeLocaleUrl: localization?.removeLocaleUrl
    };

    const onboarding: T.OnboardingInputType = {
      userOnboardingPath: manifest?.services?.onboarding?.userOnboardingPath,
      enable: !!manifest?.services?.onboarding?.enable,
      stack: manifest?.portal?.stack,
      ...manifest?.services?.onboarding
    };

    const userOnboarding: T.UserOnboardingInputType = {
      enable: manifest?.services?.userOnboarding?.enable,
      path: manifest?.services?.userOnboarding?.path,
      forceUserOnboardingCriterionKey:
        manifest?.services?.userOnboarding?.forceUserOnboardingCriterionKey,
      userOnboardingPath: manifest?.services?.onboarding?.userOnboardingPath // for backward compatibility
    };

    const shellFlags: T.ShellFlagsInputType = {
      oaLooseLoad: manifest?.services?.webPortalsFlags?.oaLooseLoad ?? true,
      nativeRefreshListener:
        manifest?.services?.webPortalsFlags?.nativeRefreshListener ?? true
    };

    container.register<T.ShellFlagsInputType>('ShellFlagsProps', {
      useValue: shellFlags
    });

    container.register('SupportSessionProps', {
      useValue: { initialEnablementStatus: ast.enabled }
    });

    container.register('TenantHandlerProps', {
      useValue: tenantHandlerProps
    });

    container.register('LoginProps', {
      useValue: login
    });

    const platform: PlatformEnum =
      manifest?.portal?.platform || PlatformEnum.auto;

    container.register('Platform', { useValue: platform });

    const event: T.EventServiceInputType = {
      removeSelfFilterByContainerInstanceId:
        manifest?.services?.event?.removeSelfFilterByContainerInstanceId
    };
    container.register('EventServiceProps', { useValue: event });

    return {
      abTesting,
      platform,
      analytics,
      appName,
      ast,
      backgroundTasks,
      basePath,
      clientId,
      consent,
      defaultCriterionKey,
      defaultLayoutKey,
      featureFlags,
      grants,
      grantsHistory,
      jWebPlatform,
      isNative,
      layouts,
      localization,
      login,
      monitoring,
      navigation,
      routes,
      serviceWorker,
      stack,
      theme,
      urlService,
      userActivity,
      graphql,
      event,
      applicationContext,
      onboarding,
      userOnboarding
    };
    // TODO: we should remove this "as" to force casting here.
  }

  // TODO: Retrocompatibility behavior
  public static adaptOrgAndCustomerSelectorIntoTenantHandler(
    manifest: any
  ): void {
    const DEFAULT_TENANT_HANDLER_KEY = 'default';
    const isTenantHandlerActive = !!manifest?.services?.tenantHandler?.enable;

    if (isTenantHandlerActive) return;

    const orgSelectorSettings = manifest?.navigation?.header?.domainSelector;

    if (orgSelectorSettings?.enable) {
      const orgSelectorToTenantHandlerSettings: TenantHandlerParamsType = {
        key: DEFAULT_TENANT_HANDLER_KEY,
        strategy: TenantStrategyEnum.stratusOrganization,
        options: {
          fallback: orgSelectorSettings?.fallback,
          filter: orgSelectorSettings?.filter
        }
      };

      manifest.services['tenantHandler'] = {
        enable: true,
        globalTenantHandler: orgSelectorToTenantHandlerSettings.key,
        tenantHandlerList: [orgSelectorToTenantHandlerSettings]
      };

      // TODO: This is very fragile, the portal could change the assetReference to any other name.
      const customerSelectorSettings =
        manifest?.navigation?.header?.widgets?.find(
          (w) =>
            w.assetReference === '@jarvis/react-shell-customer-selector' &&
            w.enable
        );

      // Customer Selector Behavior
      if (customerSelectorSettings?.enable) {
        const customerSelectorToTenantHandlerSettings: TenantHandlerParamsType =
          {
            strategy: TenantStrategyEnum.stratusCustomer
          };

        orgSelectorToTenantHandlerSettings['subTenant'] =
          customerSelectorToTenantHandlerSettings;
      }
    }
  }
}
