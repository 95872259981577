import { requestStateData } from './requestStateData'
import { useStateData } from './useStateData'
import { SignupState } from '../types'

export const fetchBanner = () =>
  requestStateData(
    'banner',
    ({
      signupSession: { instantInkService },
      isLoggedIn,
      localization,
      partialSubscription
    }: SignupState) =>
      instantInkService.fetchBanner(
        partialSubscription?.data?.id,
        ...(isLoggedIn
          ? []
          : [
              localization?.language,
              localization?.country?.toLowerCase() === 'gb'
                ? 'uk'
                : localization?.country?.toLowerCase()
            ])
      )
  )

export const useBanner = () =>
  useStateData(
    'banner',
    fetchBanner,
    ({ partialSubscription }) => partialSubscription?.data?.id !== undefined
  )
