import { FinishSetupActionList } from '@/components/UI/ECP'
import React, { useMemo } from 'react'
import { FETCH_STATUS } from '@/store/Constants'
import { ButtonsPartnerLink } from './ButtonsPartnerLink'

export function FooterSectionPartnerLink({
  status,
  pageData,
  accountId,
  setStatus
}) {
  const isConnectionPending = useMemo(
    () => ![FETCH_STATUS.SUCCESS, FETCH_STATUS.FAILURE].includes(status),
    [status]
  )
  return (
    <div>
      {!isConnectionPending ? (
        <FinishSetupActionList
          actionListCopy={pageData.finish_setup.action_list}
          buttonsCopy={pageData.buttons}
        />
      ) : (
        <ButtonsPartnerLink
          status={status}
          setStatus={setStatus}
          accountId={accountId}
          footerCopy={pageData.footer}
          buttonsCopy={pageData.buttons}
        />
      )}
    </div>
  )
}
