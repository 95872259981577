import { PlanSelectorActionType } from '../../common/types/planSelectorAction';

export const setCreditCardAction = (creditCard: {
  cardTypeKey: string;
  cardNumber: string;
  displayExpirationDate: string;
  cardExpirationDate: string;
  expirationStatus: string;
}) => ({
  type: PlanSelectorActionType.SET_CREDIT_CARD,
  creditCard
});
