import { CriticalScopesData } from '../types'
import { useShippingFormState } from './useShippingFormState'
import { useDispatch } from './useDispatch'
import { useEffect } from 'react'
import { handleFetchCriticalScopesAction } from '../actions/fetchCriticalScopes'

export const useCriticalScopes = (): CriticalScopesData => {
  const {
    criticalScopesData,
    criticalScopesDataLoading,
    criticalScopesDataError
  } = useShippingFormState()

  const dispatch = useDispatch()

  useEffect(() => {
    if (
      criticalScopesData === undefined &&
      !criticalScopesDataLoading &&
      !criticalScopesDataError
    ) {
      ;(async () => {
        await dispatch(handleFetchCriticalScopesAction())
      })()
    }
  }, [
    criticalScopesData,
    criticalScopesDataLoading,
    criticalScopesDataError,
    dispatch
  ])

  return criticalScopesData
}
