import React, { useState } from 'react';
import { AutomaticRenewalNotice } from '@jarvis/react-instant-ink-automatic-renewal-notice';
import { useGetText } from '../../../../../common/hooks/useGetText';
import { TOSCheckbox, ButtonContainer, EnrollButton } from './styles';
import { useYearlyPlanSelectorState } from '../../../../../YearlyPlanSelector/hooks/useYearlyPlanSelectorState';
import { useDispatch } from '../../../../hooks/useDispatch';
import { setCurrentStepAction } from '../../../../actions/setCurrentStepAction';

export const JarvisARN: React.FC = () => {
  const {
    onARNConfirmClick,
    onCloseModal,
    initialStep,
    selectedYearlyPlan,
    tosLink
  } = useYearlyPlanSelectorState();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleCancelButtonClick = () => {
    onCloseModal();
    dispatch(setCurrentStepAction(initialStep));
  };

  const handleEnrollButtonClick = async () => {
    try {
      setLoading(true);
      await onARNConfirmClick(selectedYearlyPlan);
      onCloseModal();
      dispatch(setCurrentStepAction(initialStep));
    } catch {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const [acceptedTermsOfService, setAcceptedTermsOfService] =
    React.useState(false);
  const getText = useGetText();
  return (
    <div data-testid="jarvis-arn-container">
      <AutomaticRenewalNotice
        language="en"
        country="US"
        hasPrepaidBalance
        prepaidBalanceFormatted={'$10'}
        totalFreeMonths={10}
        waiverPages={10}
        cancelUrl={'cancelurl.com'}
        dashboardUrl={'dashbord_url.com'}
        trackClickEvent={(linkId, linkPlacement) =>
          console.log('Tracking Click Event: ', linkId, linkPlacement)
        }
        isV3
      />
      <TOSCheckbox
        id="terms-of-service"
        data-testid="terms-agreement"
        data-linkid="ii_plans"
        name="termsOfService"
        checked={acceptedTermsOfService}
        onChange={() => setAcceptedTermsOfService((prev) => !prev)}
        label={
          <p
            dangerouslySetInnerHTML={{
              __html: getText(
                'plans_selector.yearly_confirmation.modals.i_have_read_and_agree',
                { hpTermsOfService: tosLink }
              )
            }}
          />
        }
      />
      <ButtonContainer>
        <EnrollButton
          appearance={'secondary'}
          onClick={handleCancelButtonClick}
          data-testid="cancel-terms-agreement-button"
          data-linkid="ii_plans"
        >
          {getText('plans_selector.cancel')}
        </EnrollButton>
        <EnrollButton
          disabled={!acceptedTermsOfService}
          onClick={handleEnrollButtonClick}
          data-testid="confirm-terms-agreement-button"
          data-linkid="ii_plans"
          loading={loading}
        >
          {getText('plans_selector.confirm_plan')}
        </EnrollButton>
      </ButtonContainer>
    </div>
  );
};
