import { requestStateData } from './requestStateData'
import { useStateData } from './useStateData'
import { SignupState } from '../types'

export const fetchPaths = () =>
  requestStateData(
    'paths',
    ({
      signupSession: { instantInkService },
      isLoggedIn,
      localization
    }: SignupState) =>
      instantInkService.fetchPaths(
        ...(isLoggedIn
          ? []
          : [
              localization?.language,
              localization?.country?.toLowerCase() === 'gb'
                ? 'uk'
                : localization?.country?.toLowerCase()
            ])
      )
  )

export const usePaths = () => useStateData('paths', fetchPaths, () => true)
