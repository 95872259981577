import React, { useState } from 'react';
import { CompareYearlyAndMonthlyPlan } from '../../components/CompareYearlyAndMonthlyPlan';
import {
  ANALYTICS_EVENT_LABEL,
  ARN_STEP,
  BILLING_FORM_STEP,
  COMPARE_YEARLY_MONTHLY_STEP,
  YEARLY_PLAN_SELECTOR_STEP
} from '../../../common/constants';
import { ConfirmYearlyChange } from '../../components/ConfirmYearlyChange';
import { AllYearlyPlansSelector } from '../AllYearlyPlansSelector';
import { useYearlyPlanSelectorState } from '../../hooks/useYearlyPlanSelectorState';
import { setCurrentStepAction } from '../../actions/setCurrentStepAction';
import { useDispatch } from '../../hooks/useDispatch';
import { BillingErrorType } from '../../../YearlyPlanSelector/types/billingErrorType';
import { YearlyModal } from './styles';

export const YearlyPlanSelectorContent: React.FC = () => {
  const dispatch = useDispatch();
  const { currentStep, initialStep } = useYearlyPlanSelectorState();
  const {
    renderBillingForm,
    isStandAloneMode,
    trackClickEvent,
    onCloseModal,
    showModal,
    fetchBillingInfo
  } = useYearlyPlanSelectorState();
  const [paymentMethodIsNotUpdated, setPaymentMethodIsNotUpdated] =
    useState(true);

  const handleYearlyModalClose = () => {
    trackClickEvent(ANALYTICS_EVENT_LABEL, 'yearly-card-close-modal');
    onCloseModal();

    if (dispatch) {
      dispatch(setCurrentStepAction(initialStep));
    }
  };

  const onSave = (error: BillingErrorType) => {
    if (error) {
      return;
    }
    if (dispatch) {
      fetchBillingInfo();
      setPaymentMethodIsNotUpdated(false);
      dispatch(setCurrentStepAction(ARN_STEP));
    }
  };

  const onCancel = () => {
    if (dispatch) {
      dispatch(setCurrentStepAction(ARN_STEP));
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case COMPARE_YEARLY_MONTHLY_STEP:
        return <CompareYearlyAndMonthlyPlan />;
      case ARN_STEP:
        return (
          <ConfirmYearlyChange
            paymentMethodIsNotUpdated={paymentMethodIsNotUpdated}
          />
        );
      case YEARLY_PLAN_SELECTOR_STEP:
        return <AllYearlyPlansSelector />;
      case BILLING_FORM_STEP:
        return renderBillingForm(onSave, onCancel);
      default:
        return <CompareYearlyAndMonthlyPlan />;
    }
  };

  if (isStandAloneMode) {
    return renderStep();
  }

  return (
    <YearlyModal
      $currentStep={currentStep}
      show={showModal}
      closeButton
      onClose={handleYearlyModalClose}
    >
      {renderStep()}
    </YearlyModal>
  );
};
