import React, { useContext, useEffect, useState } from 'react'
import Modal from '@veneer/core/dist/scripts/modal'
import classes from './HpPlusBenefitsPage.module.scss'
import {
  ANALYTICS,
  CONTENT_STACK_TYPES,
  HP_PLUS_PROGRAM_LEVEL,
  VENEER_SECONDARY_BUTTON_APPEARANCE,
  VENEER_TERTIARY_BUTTON_APPEARANCE
} from '@/store/Constants'
import { ConfigContext, PrinterContext, UserContext } from '@/store'
import Button from '@veneer/core/dist/scripts/button'
import useContentStack from '@/hooks/useContentStack'
import GenericModal, {
  GENERIC_MODAL_TYPES
} from '@/components/UI/Modals/GenericModal'
import useAnalytics from '@/hooks/useAnalytics'
import { LazyVeneerIcon } from '@/styles/LazyVeneer'
const { BUTTONS, SCREENS } = ANALYTICS

const BenefitsContent = ({ cmsData }) => {
  const buildBenefitsItems = (data) => {
    const items = []
    data.forEach((item, i) => {
      items.push(
        <div key={i}>
          {item?.veneer_icon?.icon !== null ? (
            <div className={classes.veneerIcon}>
              <LazyVeneerIcon
                icon={item?.veneer_icon?.icon}
                data-testid={`image-${item.text}`}
                size={36}
              />
            </div>
          ) : (
            <div className={classes.imageFile}>
              <img src={item.image.url} data-testid={`image-${item.text}`} />
            </div>
          )}
          <p>{item.text}</p>
        </div>
      )
    })

    return items
  }
  return (
    <>
      <div className={classes.header}>{cmsData.header}</div>
      <div className={classes.subheader}>{cmsData.subheader}</div>
      {cmsData?.benefits?.right?.length ? (
        <div className={classes.benefits}>
          <section>{buildBenefitsItems(cmsData.benefits.left)}</section>
          <section>{buildBenefitsItems(cmsData.benefits.right)}</section>
        </div>
      ) : (
        <div className={classes.leftBenefitsOnly}>
          <section>{buildBenefitsItems(cmsData.benefits.left)}</section>
        </div>
      )}
    </>
  )
}

const HpPlusBenefitsPage = ({
  asModal = false,
  showModal = false,
  toggleModal = null
}) => {
  const {
    setProgramLevel,
    isHpPlusCountry,
    accountType,
    setHpPlusOfferAccepted
  } = useContext(UserContext)
  const { isSingleSku } = useContext(PrinterContext)
  const { navigation } = useContext(ConfigContext)
  const [showDeclineModal, setShowDeclineModal] = useState(false)
  const [showLearnMoreModal, setShowLearnMoreModal] = useState(false)
  const { fireScreenDisplayed, fireButtonClick } = useAnalytics(
    SCREENS.HP_PLUS_VALUE_PROP
  )

  fireScreenDisplayed()

  const parseCmsData = (data) => {
    const parsedData = {}
    parsedData.header = data.header
    parsedData.subheader = data.subheader
    parsedData.benefits = {
      left: data.left_benefits_group,
      right: data.right_benefits
    }
    parsedData.tertiary_button_text = data.tertiary_button_text
    parsedData.secondary_button_text = data.secondary_button_text
    parsedData.primary_button_text = data.primary_button_text

    return parsedData
  }

  const { pageData } = useContentStack({
    content_type: CONTENT_STACK_TYPES.hp_plus_offer_benefits,
    parsing_function: parseCmsData,
    additional_params: {
      tenant_type: accountType
    }
  })

  useEffect(() => {
    if (isSingleSku) {
      isHpPlusCountry
        ? setProgramLevel(HP_PLUS_PROGRAM_LEVEL)
        : navigation.push('/activating')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSingleSku, isHpPlusCountry])

  const toggleDeclineModal = (value) => {
    fireButtonClick(BUTTONS.DECLINE_HP_PLUS_BUTTON)
    setShowDeclineModal(value)
  }

  const toggleLearnMoreModal = (value) => {
    fireButtonClick(BUTTONS.LEARN_MORE)
    setShowLearnMoreModal(value)
  }

  if (!pageData) return <></>
  return (
    <div
      className={classes.hpPlusBenefits}
      data-testid="hp-plus-benefits"
      id="hp-plus-benefits"
    >
      <GenericModal
        type={GENERIC_MODAL_TYPES.DECLINE_HP_PLUS_MODAL}
        visible={showDeclineModal}
        setVisibility={toggleDeclineModal}
      />
      <GenericModal
        type={GENERIC_MODAL_TYPES.LEARN_MORE_MODAL}
        visible={showLearnMoreModal}
        setVisibility={toggleLearnMoreModal}
      />
      {asModal && (
        <Modal
          show={showModal}
          onClose={() => {
            toggleModal()
          }}
          closeButton={true}
          portalContainer={document.getElementById('hp-plus-benefits')}
        >
          <div className={classes.modal} data-testid="modal-benefits">
            <BenefitsContent cmsData={pageData} />
          </div>
        </Modal>
      )}
      {!asModal && (
        <>
          <BenefitsContent cmsData={pageData} />
          <div className={classes.footer}>
            <div>
              <Button
                appearance={VENEER_TERTIARY_BUTTON_APPEARANCE}
                onClick={toggleLearnMoreModal.bind(null, true)}
                data-testid="learn-more"
              >
                {pageData?.tertiary_button_text}
              </Button>
              <Button
                appearance={VENEER_SECONDARY_BUTTON_APPEARANCE}
                onClick={toggleDeclineModal.bind(null, true)}
                data-testid="decline"
              >
                {pageData?.secondary_button_text}
              </Button>
              <Button
                onClick={() => {
                  fireButtonClick(BUTTONS.CONTINUE)
                  navigation.push('/activation')
                  setHpPlusOfferAccepted(true)
                }}
                data-testid="continue"
              >
                {pageData?.primary_button_text}
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
export default HpPlusBenefitsPage
