import React, { useEffect, useState } from 'react'
import {
  useShippingFormState,
  useShippingData,
  useCriticalScopes
} from '../../hooks'
import { SelectAddress } from '../SelectAddress'
import { ShippingContent } from '../ShippingContent'
import { Spinner } from '../Spinner'
import { OnSaveError } from '../../types'

const ShippingView = (): JSX.Element => {
  const shippingData = useShippingData()
  const { shippingDataLoading, disableListView, hasExistingAddress } =
    useShippingFormState()
  const [newAddressView, setNewAddressView] = useState(false)

  if (shippingDataLoading) {
    return <Spinner />
  }

  if (shippingData === undefined) {
    return null
  }

  const { supportMultiShipping } = shippingData

  if (
    !newAddressView &&
    !disableListView &&
    supportMultiShipping &&
    hasExistingAddress
  ) {
    return <SelectAddress setNewAddressView={setNewAddressView} />
  }

  return <ShippingContent newAddressView={newAddressView} />
}

export const ShippingViewContainer = (): JSX.Element => {
  const criticalScopesData = useCriticalScopes()
  const { criticalScopesDataError, onSave } = useShippingFormState()

  const isCriticalScopesExpired =
    criticalScopesDataError?.response?.status === 403

  useEffect(() => {
    if (isCriticalScopesExpired && onSave) {
      onSave(OnSaveError.expiredCriticalScope)
    }
  }, [isCriticalScopesExpired, onSave])

  return !criticalScopesData || isCriticalScopesExpired ? (
    <Spinner />
  ) : (
    <ShippingView />
  )
}
