import { SupportedLocales, SupportedCountries } from '../assets/locale'
import { UserLocale } from '../types'

export function getUserLocale(language: string, country: string): UserLocale {
  if (SupportedLocales.includes(`${language}_${country}`)) {
    return { language, country }
  }

  if (SupportedCountries.has(country)) {
    const locale = SupportedLocales.find((locale) =>
      country === locale.split('_')[1] ? locale : undefined
    )
    if (locale) {
      const [lang, cc] = locale.split('_')
      return { language: lang, country: cc }
    }
  }

  return { language: 'en', country: 'US' }
}

export const validateSelectedLocale = (
  localization: { language: string; country: string },
  supportedLocale: { supportedCountry: string; supportedLanguage: string },
  shouldFallbackLocalization?: boolean
) => {
  const findLocale = (language: string) =>
    getUserLocale(language, supportedLocale.supportedCountry)
  const selectedLocalizationLocale = findLocale(localization.language)
  const isLocaleSupported =
    selectedLocalizationLocale.language === localization.language &&
    selectedLocalizationLocale.country === supportedLocale.supportedCountry
  const defaultLocale = shouldFallbackLocalization
    ? localization
    : findLocale(supportedLocale.supportedLanguage)
  return isLocaleSupported ? selectedLocalizationLocale : defaultLocale
}
