// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1m6H_iXhQ7DXailaR-zW8X{height:100%;display:flex;flex-direction:column}._1m6H_iXhQ7DXailaR-zW8X ._1l_UpG_9jCTU7WKa3s_jkf{display:flex;min-height:375px}._1m6H_iXhQ7DXailaR-zW8X ._1l_UpG_9jCTU7WKa3s_jkf section{flex-grow:1}._1m6H_iXhQ7DXailaR-zW8X ._1l_UpG_9jCTU7WKa3s_jkf ._2NthKUAq6XrG1oX2AiyfnX h6{padding:32px 0 20px;font-size:20px;font-weight:400;line-height:28px}._1m6H_iXhQ7DXailaR-zW8X ._1l_UpG_9jCTU7WKa3s_jkf ._2NthKUAq6XrG1oX2AiyfnX ul{list-style:none}._1m6H_iXhQ7DXailaR-zW8X ._1l_UpG_9jCTU7WKa3s_jkf ._2NthKUAq6XrG1oX2AiyfnX ul li{display:flex;align-items:center;gap:8px}._1m6H_iXhQ7DXailaR-zW8X ._1l_UpG_9jCTU7WKa3s_jkf ._2NthKUAq6XrG1oX2AiyfnX ul li+li{margin-top:20px}._1m6H_iXhQ7DXailaR-zW8X ._1l_UpG_9jCTU7WKa3s_jkf ._1uuDCWhXlbOt62Hx90o5Tv{display:flex;align-items:center;justify-content:center;padding:0 40px}\n", "",{"version":3,"sources":["webpack://./src/pages/App/ValueProp/CloudValuePropPage.module.scss"],"names":[],"mappings":"AAAA,yBAAuB,WAAW,CAAC,YAAY,CAAC,qBAAqB,CAAC,kDAAgC,YAAY,CAAC,gBAAgB,CAAC,0DAAwC,WAAW,CAAC,8EAA6C,mBAAmB,CAAC,cAAc,CAAC,eAAe,CAAC,gBAAgB,CAAC,8EAA6C,eAAe,CAAC,iFAAgD,YAAY,CAAC,kBAAkB,CAAC,OAAO,CAAC,oFAAmD,eAAe,CAAC,2EAA8C,YAAY,CAAC,kBAAkB,CAAC,sBAAsB,CAAC,cAAc","sourcesContent":[".landingPageSmallville{height:100%;display:flex;flex-direction:column}.landingPageSmallville .content{display:flex;min-height:375px}.landingPageSmallville .content section{flex-grow:1}.landingPageSmallville .content .features h6{padding:32px 0 20px;font-size:20px;font-weight:400;line-height:28px}.landingPageSmallville .content .features ul{list-style:none}.landingPageSmallville .content .features ul li{display:flex;align-items:center;gap:8px}.landingPageSmallville .content .features ul li+li{margin-top:20px}.landingPageSmallville .content .printerImage{display:flex;align-items:center;justify-content:center;padding:0 40px}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"landingPageSmallville": "_1m6H_iXhQ7DXailaR-zW8X",
	"content": "_1l_UpG_9jCTU7WKa3s_jkf",
	"features": "_2NthKUAq6XrG1oX2AiyfnX",
	"printerImage": "_1uuDCWhXlbOt62Hx90o5Tv"
};
export default ___CSS_LOADER_EXPORT___;
