import { useCallback, useContext, useMemo } from 'react'
import { ConfigContext, UserContext } from '@/store'

export function useNextStage() {
  const { nextStage } = useContext(ConfigContext)
  const { getSessionId } = useContext(UserContext)

  const dispatchNextStage = useCallback(
    (result, additionalPayload = undefined) =>
      nextStage({
        ...additionalPayload,
        result,
        xCorrelationId: getSessionId()
      }),
    [nextStage, getSessionId]
  )

  const dispatchDeclinedStage = useCallback(
    (output = undefined) => dispatchNextStage('declined', output && { output }),
    [dispatchNextStage]
  )

  const dispatchSuccessStage = useCallback(
    (output = undefined) => dispatchNextStage('success', output && { output }),
    [dispatchNextStage]
  )

  const dispatchFailureStage = useCallback(
    (errorInfo = undefined) =>
      dispatchNextStage('failure', errorInfo && { errorInfo }),
    [dispatchNextStage]
  )

  return useMemo(
    () => ({
      dispatchNextStage,
      dispatchSuccessStage,
      dispatchDeclinedStage,
      dispatchFailureStage
    }),
    [
      dispatchSuccessStage,
      dispatchDeclinedStage,
      dispatchNextStage,
      dispatchFailureStage
    ]
  )
}
