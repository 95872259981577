// import { errorResponse } from './../../fixtures/shippingData'
import { IShippingService } from '../../lib/shippingService'
import { AxiosResponse } from 'axios'
import { settingsDataFixture, shippingDataFixture } from '../fixtures'
import { MockFixtures } from './mockFixtures'
import { CriticalScopesData, SettingsData, ShippingFormData } from '../../types'

export class MockShippingService implements IShippingService {
  private mockFixtures?: MockFixtures
  private defaultDelay: number

  private mockFixtureOverride(name: string) {
    const errorData = this.mockFixtures?.getFixture(name + 'Error')
    const errorStatus = this.mockFixtures?.getFixture(name + 'ErrorStatus')
    return {
      data: this.mockFixtures?.getFixture(name) || {},
      error:
        errorData || errorStatus
          ? {
              data: errorData,
              status: errorStatus || 400
            }
          : undefined
    }
  }

  private resolve<T>(result: T, delay: number = this.defaultDelay): Promise<T> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(result), delay)
    })
  }

  private reject<T>(error: T, delay: number = this.defaultDelay): Promise<T> {
    return new Promise((_, reject) => {
      setTimeout(() => reject(error), delay)
    })
  }

  constructor(mockFixtures?: MockFixtures, defaultDelay = 2000) {
    this.mockFixtures = mockFixtures
    this.defaultDelay = defaultDelay
  }

  getAddresses() {
    const { data, error } = this.mockFixtureOverride('getAddresses')
    if (error) {
      return this.reject({ response: error } as unknown as AxiosResponse<
        ShippingFormData[]
      >)
    }
    return this.resolve({
      data: [
        {
          ...shippingDataFixture({ id: '34567', isDefaultAddress: true }),
          ...(data[0] || {})
        },
        {
          ...shippingDataFixture({ id: '12345', isDefaultAddress: false }),
          ...(data[1] || {})
        }
      ],
      status: 200,
      statusText: 'ok',
      headers: {},
      config: {}
    } as AxiosResponse<ShippingFormData[]>)
  }

  getAddress({ id }: { id: string }) {
    const { data, error } = this.mockFixtureOverride('getAddress')
    if (error) {
      return this.reject({
        response: error
      } as unknown as AxiosResponse<ShippingFormData>)
    }
    return this.resolve({
      data: {
        ...shippingDataFixture({ id }),
        ...((data as Record<string, unknown>) ?? {})
      },
      status: 200,
      statusText: 'ok',
      headers: {},
      config: {}
    } as AxiosResponse<ShippingFormData>)
  }

  getAddressByCloudId({ cloudId }: { cloudId: string }) {
    const { data, error } = this.mockFixtureOverride('getAddressByCloudId')
    if (error) {
      return this.reject({
        response: error
      } as unknown as AxiosResponse<ShippingFormData>)
    }
    return this.resolve({
      data: {
        ...shippingDataFixture({ id: '34567' }),
        ...((data as Record<string, unknown>) ?? {})
      },
      status: 200,
      statusText: 'ok',
      headers: {},
      config: {}
    } as AxiosResponse<ShippingFormData>)
  }

  getSettings() {
    const { data, error } = this.mockFixtureOverride('getSettings')
    if (error) {
      return this.reject({
        response: error
      } as unknown as AxiosResponse<SettingsData>)
    }
    return this.resolve({
      data: {
        ...settingsDataFixture(),
        ...((data as Record<string, unknown>) ?? {})
      },
      status: 200,
      statusText: 'ok',
      headers: {},
      config: {}
    } as AxiosResponse<SettingsData>)
  }

  saveAddress() {
    const { data, error } = this.mockFixtureOverride('saveAddress')
    if (error) {
      return this.reject({
        response: error
      } as unknown as AxiosResponse<ShippingFormData>)
    }
    return this.resolve({
      data: {
        ...shippingDataFixture(),
        ...((data as Record<string, unknown>) || {})
      },
      status: 200,
      statusText: 'ok',
      headers: {},
      config: {}
    } as AxiosResponse<ShippingFormData>)
  }

  updateAddress({ id }: { id: string }) {
    const { data, error } = this.mockFixtureOverride('updateAddress')
    if (error) {
      return this.reject({
        response: error
      } as unknown as AxiosResponse<ShippingFormData>)
    }
    return this.resolve({
      data: {
        ...shippingDataFixture({ id }),
        ...((data as Record<string, unknown>) ?? {})
      },
      status: 200,
      statusText: 'ok',
      headers: {},
      config: {}
    } as AxiosResponse<ShippingFormData>)
  }

  associateAddressWithPrinter({ id }: { id: string }) {
    const { data, error } = this.mockFixtureOverride(
      'associateAddressWithPrinter'
    )
    if (error) {
      return this.reject({
        response: error
      } as unknown as AxiosResponse<ShippingFormData>)
    }
    return Promise.resolve({
      data: {
        ...shippingDataFixture({ id }),
        ...((data as Record<string, unknown>) ?? {})
      },
      status: 200,
      statusText: 'ok',
      headers: {},
      config: {}
    } as AxiosResponse<ShippingFormData>)
  }

  getCriticalScopes(): Promise<AxiosResponse<CriticalScopesData>> {
    const { error } = this.mockFixtureOverride('getCriticalScopes')
    if (error) {
      return this.reject({
        response: error
      } as unknown as AxiosResponse<CriticalScopesData>)
    }
    return this.resolve({
      data: {
        hasCriticalScopes: true,
        remainingTime: 850
      },
      status: 200,
      statusText: 'ok',
      headers: {},
      config: {}
    } as AxiosResponse<CriticalScopesData>)
  }
}
