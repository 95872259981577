import {
  Printer,
  PrinterSignupState,
  PrinterReplacementEligibility
} from '../../types'

export function printerFixture({
  id = 1,
  deviceId = 'cloud-printer-1',
  deviceUuid = 'device-uuid-1',
  email = 'printer-factory-1@example.com',
  displayName = 'printer 1',
  imageUrl = 'https://ssl-product-images.www8-hp.com/digmedialib/prodimg/lowres/c07002320.png',
  serial = 'VMC345X037',
  sku = 'M00001',
  genType = 'genY',
  technology = 'Ink',
  accountIdentifier = '',
  signupState = 'subscribable' as PrinterSignupState,
  isAllowedToEnroll = true,
  replacementEligibility = 'eligible' as PrinterReplacementEligibility,
  isFlipTrial = false,
  isRegistered = true,
  programType = 'i_toner'
} = {}): Printer {
  return {
    id,
    deviceId,
    deviceUuid,
    email,
    displayName,
    imageUrl,
    serial,
    sku,
    genType,
    technology,
    accountIdentifier,
    signupState,
    isAllowedToEnroll,
    replacementEligibility,
    isFlipTrial,
    isRegistered,
    programType
  }
}
