import React, { useState, useEffect, FC } from 'react'
import { UserLocaleContext } from '../../../contexts'
import { UserLocale } from '../../../types'
import {
  useTranslation,
  useSignupSession,
  useSettings,
  useSignupState,
  useUserAccount
} from '../../../hooks'
import { validateSelectedLocale } from '../../../lib/localeHelpers'
import { LoadingPage } from '../LoadingPage'
import { ErrorPage } from '../ErrorPage'
import { filterStateErrors } from '../../../lib/stateHelpers'

export const UserLocaleProvider: FC = ({ children }) => {
  const userAccountState = useUserAccount()
  const { localization } = useSignupState()
  const { showSkeleton } = useSignupSession()
  const translation = useTranslation()
  const settingsState = useSettings()
  const [userLocale, setUserLocale] = useState<UserLocale>(null)
  const [supportedLanguage, supportedCountry] =
    settingsState?.data?.preferredLocale?.split('_') || []
  const apiStates = [settingsState, userAccountState]
  const apiStatesErrors = filterStateErrors(apiStates)
  const shouldRenderErrorPage = apiStatesErrors.length > 0
  const shouldRenderChildren = Boolean(userLocale)
  const shouldSetUserLocale =
    userLocale === null && translation && supportedCountry

  useEffect(() => {
    if (shouldSetUserLocale) {
      const supportedLocale = { supportedLanguage, supportedCountry }
      const selectedLocale = validateSelectedLocale(
        localization,
        supportedLocale
      )
      setUserLocale(selectedLocale)
      translation.setLocale(selectedLocale.language, selectedLocale.country)
    }
  }, [
    translation,
    shouldSetUserLocale,
    supportedLanguage,
    localization.language,
    supportedCountry,
    localization
  ])

  if (shouldRenderChildren) {
    return (
      <UserLocaleContext.Provider value={userLocale}>
        {children}
      </UserLocaleContext.Provider>
    )
  }

  if (shouldRenderErrorPage) {
    return <ErrorPage stateErrors={apiStatesErrors} />
  }

  return showSkeleton ? null : <LoadingPage />
}
