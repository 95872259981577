import React, { useState } from 'react';
import Button from '@veneer/core/dist/scripts/button';
import InlineNotification from '@veneer/core/dist/scripts/inline_notification';
import TextBox from '@veneer/core/dist/scripts/text_box';
import useMediaQuery from '@veneer/core/dist/scripts/utils/use_media_query';
import primitives from '@veneer/primitives';

import {
  Container,
  InnerContainer,
  CardInfoContainer,
  CardFlagContainer,
  InnerCardInfo,
  P,
  Link,
  CVVEntryContainer,
  CVVEntryInnerContainer,
  CVVInputContainer,
  CVVIcon,
  Notification,
  NotificationMessage,
  mobileDesignBreakpoint
} from './styles';

import { useGetText } from '../../../../../common/hooks/useGetText';
import visaCard from '../../../../../../imgs/credit_card/visa_new_visId.png';
import masterCard from '../../../../../../imgs/credit_card/mastercard_new_visid.png';
import discoverCard from '../../../../../../imgs/credit_card/discover_new_visid.png';
import amexCard from '../../../../../../imgs/credit_card/american_new_visid.png';
import IconCheckmarkCircle from '@veneer/core/dist/scripts/icons/icon_checkmark_circle';
import IconMinusCircle from '@veneer/core/dist/scripts/icons/icon_minus_circle';
import { useDispatch } from '../../../../hooks/useDispatch';
import { setCurrentStepAction } from '../../../../actions/setCurrentStepAction';
import {
  ANALYTICS_EVENT_LABEL,
  BILLING_FORM_STEP
} from '../../../../../common/constants';
import { useYearlyPlanSelectorState } from '../../../../hooks/useYearlyPlanSelectorState';

const VISA = 'visa';
const AMERICAN_EXPRESS = 'american-express';
const DISCOVER = 'discover';
const MASTER_CARD = 'mastercard';

export const BillingCvv: React.FC = () => {
  const getText = useGetText();
  const dispatch = useDispatch();
  const { creditCard, onSubmitCVV, cvvStatus, trackClickEvent } =
    useYearlyPlanSelectorState();

  const isMobileDesign = useMediaQuery(
    `(max-width: ${mobileDesignBreakpoint})`
  );

  const [cvv, setCvv] = useState<string>('');

  const paymentImage = (cardTypeKey: string) => {
    switch (cardTypeKey) {
      case AMERICAN_EXPRESS:
        return amexCard;
      case DISCOVER:
        return discoverCard;
      case MASTER_CARD:
        return masterCard;
      case VISA:
        return visaCard;

      default:
        return;
    }
  };

  const CVVStatusMessage = () => {
    if (cvvStatus === null) {
      return <div data-testid="empty-input-notification" />;
    }

    if (cvvStatus === 'invalid_input') {
      return (
        <Notification data-testid="invalid-input-notification">
          <IconMinusCircle filled color={primitives.color.red7} size={24} />
          <NotificationMessage>
            {getText('plans_selector.modals.billing_cvv.invalid_input')}
          </NotificationMessage>
        </Notification>
      );
    }

    if (cvvStatus === 'error') {
      return (
        <Notification data-testid="error-notification">
          <IconMinusCircle filled color={primitives.color.red7} size={24} />
          <NotificationMessage>
            {getText('plans_selector.modals.billing_cvv.error')}
          </NotificationMessage>
        </Notification>
      );
    }

    return (
      <Notification data-testid="success-notification">
        <IconCheckmarkCircle filled color={primitives.color.green8} size={24} />
        <NotificationMessage>
          {getText('plans_selector.modals.billing_cvv.success', { cvv })}
        </NotificationMessage>
      </Notification>
    );
  };

  const CVVSubmitButton = () => {
    const onClickcvv = (cvv: string) => {
      trackClickEvent(ANALYTICS_EVENT_LABEL, 'cvv-submit-button');
      onSubmitCVV(cvv);
    };

    return (
      <Button
        data-testid="cvv-submit-button"
        data-linkid="ii_plans"
        style={{ width: isMobileDesign ? '100%' : '' }}
        disabled={!cvv}
        onClick={() => onClickcvv(cvv)}
      >
        {getText('plans_selector.modals.billing_cvv.submit')}
      </Button>
    );
  };

  const changePaymentMethodClick = () => {
    trackClickEvent(ANALYTICS_EVENT_LABEL, 'change-plans-billing-cvv');
    if (dispatch) {
      dispatch(setCurrentStepAction(BILLING_FORM_STEP));
    }
  };

  return (
    <div data-testid="yearly-plans-billing-cvv">
      <Container>
        <InlineNotification
          closeButton={false}
          type="help"
          title={getText('plans_selector.modals.billing_cvv.notification')}
        />
        <InnerContainer>
          <CardInfoContainer>
            <CardFlagContainer>
              <img
                alt={creditCard.cardTypeKey}
                src={paymentImage(creditCard.cardTypeKey)}
              />
            </CardFlagContainer>
            <InnerCardInfo>
              <P>{creditCard.cardNumber}</P>
              <P>
                {getText('plans_selector.modals.billing_cvv.expires', {
                  date: creditCard.displayExpirationDate
                })}
              </P>
              <Link
                data-testid="change-payment-method-link"
                data-linkid="ii_plans"
                onClick={changePaymentMethodClick}
              >
                {getText('plans_selector.modals.billing_cvv.change_link')}
              </Link>
            </InnerCardInfo>
          </CardInfoContainer>
          <CVVEntryContainer>
            <CVVEntryInnerContainer>
              <CVVInputContainer>
                <TextBox
                  data-testid="cvv-input"
                  trailingIcon={<CVVIcon />}
                  label="CVV"
                  placeholder="CVV"
                  value={cvv}
                  onChange={setCvv}
                  minLength={3}
                  maxLength={3}
                  inputMode="numeric"
                  pattern="[0-9]{3}"
                />
              </CVVInputContainer>
              {isMobileDesign ? <CVVStatusMessage /> : <CVVSubmitButton />}
            </CVVEntryInnerContainer>
            {isMobileDesign ? <CVVSubmitButton /> : <CVVStatusMessage />}
          </CVVEntryContainer>
        </InnerContainer>
      </Container>
    </div>
  );
};
