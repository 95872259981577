import { useEffect } from 'react'
import { useBillingFormState } from './useBillingFormState'
import { useDispatch } from './useDispatch'
import { fetchCriticalScopesAction } from '../actions'

const fetching: Record<number, boolean> = {}

export const useCriticalScopes = () => {
  const { id, criticalScopes, criticalScopesLoading, criticalScopesError } =
    useBillingFormState()
  const dispatch = useDispatch()

  useEffect(() => {
    if (
      criticalScopes === undefined &&
      !criticalScopesLoading &&
      !criticalScopesError &&
      !fetching[id]
    ) {
      ;(async () => {
        try {
          fetching[id] = true
          await dispatch(fetchCriticalScopesAction())
        } catch {
          // do nothing
        } finally {
          fetching[id] = false
        }
      })()
    }
  }, [id, criticalScopes, criticalScopesLoading, criticalScopesError, dispatch])

  return criticalScopes
}
