import { ConnectAndVerifyType } from '../../types'
import { partialSubscriptionFixture } from './partialSubscriptionFixtures'
import { benefitsFixture } from './benefitsFixtures'

export function connectAndVerifyFixture({
  planMismatch = false,
  planTechnologyMismatch = false,
  paperMismatch = false,
  rafIneligible = false,
  mnsPoobeInfo = {
    enrollConnectRedirectUrl: 'some-enroll-connect-redirect-url',
    localPrinterRedirectUrl: 'some-local-redirect-url',
    exitRedirectUrl: 'some-exit-redirect-url'
  },
  updatedPartialSubscription = partialSubscriptionFixture(),
  updatedEnrollmentBenefits = benefitsFixture()
} = {}): ConnectAndVerifyType {
  return {
    planMismatch,
    planTechnologyMismatch,
    paperMismatch,
    rafIneligible,
    mnsPoobeInfo,
    updatedPartialSubscription,
    updatedEnrollmentBenefits
  }
}
