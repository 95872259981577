/* eslint-disable @typescript-eslint/no-unused-vars */
import AssetsProviderFactory from '../../../assets/AssetsProviderFactory';
import { ConsumerPlansAction } from '../types/consumerPlansAction';
import { ConsumerPlansState } from '../types/consumerPlansState';
import { ConsumerPlansProps } from '../types/consumerPlansProps';

const defaultPlan = {
  id: 0,
  guid: '',
  price: '',
  pages: 0,
  overageBlockPrice: '',
  overageBlockSize: 0,
  program: '',
  rollover: 0,
  frequency: ''
};

const findIdBySavedPlanGUID = (plans, savedPlanGUID, program) => {
  const index = plans[program].findIndex((plan) => plan.guid === savedPlanGUID);
  const plan = index !== -1 ? plans[program][index] : defaultPlan;
  return { plan, index };
};

export const consumerPlansInitialState = ({
  plans,
  savedPlanGUID,
  country,
  language,
  defaultPlanGUIDInk = '',
  defaultPlanGUIDToner = '',
  program,
  showPlanProgramTabs = false,
  onPlanChange,
  trackClickEvent = (linkId: string, linkPlacement: string): null => null,
  renderLearnMore,
  showPriceIncreaseInfo = false,
  isHPX = false
}: ConsumerPlansProps): ConsumerPlansState => {
  const selected = findIdBySavedPlanGUID(plans, savedPlanGUID, program);

  return {
    plans,
    selectedPlan: {
      id: selected.plan.id,
      index: selected.index,
      guid:
        program === 'i_ink_paper'
          ? selected.plan.guid + '_i_ink_paper'
          : selected.plan.guid,
      plan: selected.plan,
      isInkPaperPlan: program === 'i_ink_paper' ? true : false
    },
    getText: AssetsProviderFactory.create(language, country).getText.bind(
      AssetsProviderFactory.create(language, country)
    ),
    program,
    defaultPlanGUIDInk,
    defaultPlanGUIDToner,
    showPlanProgramTabs,
    onPlanChange,
    trackClickEvent,
    renderLearnMore,
    showPriceIncreaseInfo,
    isHPX,
    country
  };
};

export const consumerPlansReducer = (
  state: ConsumerPlansState,
  action: ConsumerPlansAction
) => {
  let newState = state;

  switch (action.type) {
    case 'SET_SELECTED_PLAN':
      newState = {
        ...state,
        selectedPlan: action.selectedPlan || {
          id: 0,
          index: 0,
          guid: '',
          plan: defaultPlan,
          isInkPaperPlan: false
        }
      };
      break;

    case 'SET_SELECTED_PROGRAM':
      newState = {
        ...state,
        program: action.program || ''
      };
      break;

    case 'SET_ASSETS_PROVIDER':
      if (action.language && action.country) {
        newState = {
          ...state,
          getText: AssetsProviderFactory.create(
            action.language,
            action.country
          ).getText.bind(
            AssetsProviderFactory.create(action.language, action.country)
          )
        };
      }
      break;
    case 'SAVE_PLAN':
      newState = {
        ...state,
        isSavingPlan: true
      };
      break;
    case 'SAVE_PLAN_SUCCESS':
      newState = {
        ...state,
        isSavingPlan: false
      };
      break;
    case 'SAVE_PLAN_FAIL':
      newState = {
        ...state,
        isSavingPlan: false
      };
      break;
  }
  return newState;
};
