import { useCallback } from 'react'
import { updatePartialSubscription } from '../stateData/partialSubscription'
import { useGetText, useUserLocale, useDispatch, useSettings } from './index'
import { printerClaimFailureMessage } from '../flows/oobe/shared/ManualPrinterClaim'
import { SignupAction, SignupState } from '../types'
import { AsyncAction } from './useAsyncReducer'

export const useOobeEnrollClaimCallback = (
  completeSignupSession: () =>
    | SignupAction
    | AsyncAction<SignupAction, SignupState, void>
) => {
  const getText = useGetText()
  const { country, language } = useUserLocale()
  const dispatch = useDispatch()
  const { enableHpAppStrings } = useSettings()?.data

  const enrollmentError = useCallback(
    (error) => {
      return error?.response?.data?.error_code === 'enrollment_error'
        ? getText('enroll_step.arn_modal.no_longer_eligible_error_html', {
            country: country.toLowerCase(),
            language: language.toLowerCase()
          })
        : getText('enroll_step.arn_modal.generic_error')
    },
    [country, getText, language]
  )

  const claimPrinter = useCallback(
    async (code: string, confirm_transfer: boolean) => {
      try {
        await dispatch(
          updatePartialSubscription({
            claim_printer: { code, confirm_transfer }
          })
        )
      } catch (error) {
        const { status, data } = error?.response || {}
        if (status !== 401) {
          let errorId = data?.error_id
          if (errorId == 'unsupported_code' && enableHpAppStrings) {
            errorId = errorId?.replace(
              'unsupported_code',
              'unsupported_code_hp_app'
            )
          }

          throw {
            message: getText(printerClaimFailureMessage(errorId)),
            claimConfirmation: data?.error_id === 'claim_confirmation'
          }
        }
      }
    },
    [dispatch, getText, enableHpAppStrings]
  )

  return useCallback(
    async ({
      code,
      confirm_transfer
    }: {
      code: string
      confirm_transfer?: boolean
    }) => {
      await claimPrinter(code, confirm_transfer)

      try {
        await dispatch(completeSignupSession())
      } catch (error) {
        throw {
          message: enrollmentError(error),
          claimConfirmation: false
        }
      }
    },
    [completeSignupSession, claimPrinter, dispatch, enrollmentError]
  )
}
