import { NorthboundImageType } from './types';

export const listDevicePosition = (v1Types: NorthboundImageType['v1Types']) => [
  {
    value: v1Types?.DevicePosition?.LeftFacing || 'LeftFacing',
    label: 'Left Facing'
  },
  {
    value: v1Types?.DevicePosition?.CenterFacing || 'CenterFacing',
    label: 'Center Facing'
  },
  {
    value: v1Types?.DevicePosition?.RightFacing || 'RightFacing',
    label: 'Right Facing'
  }
];

export const listImageSize = (v1Types: NorthboundImageType['v1Types']) => [
  {
    value: v1Types?.ImageSize?.Small || 'Small',
    label: 'Small'
  },
  {
    value: v1Types?.ImageSize?.Large || 'Large',
    label: 'Large'
  }
];
