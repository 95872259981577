import React, { useContext } from 'react'
import classes from './AccountTypePage.module.scss'
import { ConfigContext, PrinterContext, UserContext } from '@/store'
import {
  ANALYTICS,
  CONTENT_STACK_TYPES,
  MULTI_FUNCTION_PRINTER,
  SINGLE_FUNCTION_PRINTER
} from '@/store/Constants'
import useAnalytics from '@/hooks/useAnalytics'
import NextStageButton from '@/components/UI/NextStageButton'
import useContentStack from '@/hooks/useContentStack'
import Card from '@veneer/core/dist/scripts/card'
import { LazyVeneerIcon } from '@/styles/LazyVeneer'

const { SCREENS } = ANALYTICS

const AccountTypePage = () => {
  const configCtx = useContext(ConfigContext)
  const { hasFaxSupport, hasScanSupport } = useContext(PrinterContext)
  const { getSessionId, setAccountType } = useContext(UserContext)
  const { fireScreenDisplayed, fireButtonClick } = useAnalytics(
    SCREENS.ACCOUNT_TYPE
  )

  const { pageData } = useContentStack({
    content_type: CONTENT_STACK_TYPES.account_type,
    additional_params: {
      printer_function_type:
        hasFaxSupport && hasScanSupport
          ? MULTI_FUNCTION_PRINTER
          : SINGLE_FUNCTION_PRINTER
    }
  })

  const relationWithAccountTypeAndAnalyticsButton = {
    BusinessTransactionalSMB: ANALYTICS.BUTTONS.COMPANY_SELECT_BUTTON,
    Personal: ANALYTICS.BUTTONS.PERSONAL_SELECT_BUTTON
  }

  const AccountTypeSelection = ({ cardData }) => {
    const { veneer_icon, header, body, select_button, account_type } = cardData

    return (
      <Card
        className={classes.accountTypeCard}
        content={
          <>
            <div className={classes.cardHeader}>
              <LazyVeneerIcon icon={veneer_icon?.icon} size={32} />
              <h3>{header}</h3>
            </div>
            <div
              className={classes.cardBody}
              dangerouslySetInnerHTML={{ __html: body }}
            />
            <div className={classes.cardFoot}>
              <NextStageButton
                copy={select_button}
                onClick={() => {
                  selectAccountType(account_type.account_type)
                  fireButtonClick(
                    relationWithAccountTypeAndAnalyticsButton[
                      account_type.account_type
                    ]
                  )
                }}
                dataTestId={`button-${account_type.account_type.toLowerCase()}`}
              />
            </div>
          </>
        }
      />
    )
  }

  const selectAccountType = (accountType) => {
    setAccountType(accountType)
    configCtx.nextStage({
      result: 'success',
      output: {
        tenant: {
          type: accountType
        }
      },
      xCorrelationId: getSessionId()
    })
  }

  if (!pageData) {
    return <></>
  }
  fireScreenDisplayed()

  return (
    <div className={classes.accountTypePage} data-testid="account-type-page">
      <h1>{pageData.header}</h1>
      <div className={classes.choices}>
        {(pageData.cards || []).map((card) => {
          return (
            <AccountTypeSelection
              key={card.account_type.account_type}
              cardData={card}
            />
          )
        })}
      </div>
      <span className={classes.observation}>
        {pageData.asterisk_observation}
      </span>
    </div>
  )
}

export default AccountTypePage
