import styled from 'styled-components';
import { getColor } from '../../../common/utils/colorSelector';
import primitives from '@veneer/primitives';
import paperCardImage from '../../../../imgs/paper-card-image.png';
import paperCardMobileImage from '../../../../imgs/paper-card-image-mobile.png';
import redesignPaperCardImage from '../../../../imgs/redesign-paper-card-image.png';
import redesignPaperCardMobileImage from '../../../../imgs/redesign-paper-card-image-mobile.png';
import { breakpointsHelper } from '../../../common/utils/breakpointsHelper';

type PaperCardProps = {
  enableYearlyPlan: boolean;
};
export const PaperCardContainer = styled.div<PaperCardProps>`
  display: flex;
  flex-direction: column;
  width: ${({ enableYearlyPlan }) => (enableYearlyPlan ? '466px' : '100%')};
  min-height: ${({ enableYearlyPlan }) =>
    enableYearlyPlan ? '244px' : '340px'};
  border-radius: 8px;
  border: 1px solid #dbdbdb;
  background-color: #ffffff;
  cursor: pointer;
  margin-top: ${({ enableYearlyPlan }) => (enableYearlyPlan ? '24px' : '')};

  @media (max-width: ${breakpointsHelper.extra_small_575px}) {
    width: 100%;
    margin-right: 0;
    justify-self: center;
    min-height: 0;
  }
`;

export const ImageContainer = styled.div<PaperCardProps>`
  display: flex;
  width: 100%;
  height: ${({ enableYearlyPlan }) => (enableYearlyPlan ? '56px' : '64px')};
  background-color: #e6e6e6;
  border-radius: 8px 8px 0px 0px;
`;

export const PaperImage = styled.div<PaperCardProps>`
  display: flex;
  width: 100%;
  height: 64px;
  background-position: center;
  background-size: cover;
  background-image: url(${paperCardImage});
  background-image: ${({ enableYearlyPlan }) =>
    enableYearlyPlan
      ? `url(${redesignPaperCardImage})`
      : `url(${paperCardImage})`};
  border-radius: 8px 8px 0px 0px;

  @media (max-width: ${breakpointsHelper.extra_small_575px}) {
    background-image: ${(enableYearlyPlan) =>
      enableYearlyPlan
        ? `url(${redesignPaperCardMobileImage})`
        : `url(${paperCardMobileImage})`};
    margin-right: 17px;
  }
`;

export const TitleContainer = styled.div<PaperCardProps>`
  &&& {
    h1 {
      font-size: ${({ enableYearlyPlan }) =>
        enableYearlyPlan ? '24px' : '20px'};
      font-weight: 400;
      color: ${primitives.color.gray12};
      line-height: ${({ enableYearlyPlan }) =>
        enableYearlyPlan ? '32px' : '28px'};
      font-family: ${primitives.typography.family0};
      margin: ${({ enableYearlyPlan }) =>
        enableYearlyPlan ? '16px 16px 8px 16px' : '16px 16px 0 16px'};
      word-break: normal;
    }
  }
`;

export const PriceContainer = styled.div<PaperCardProps>`
  &&& {
    h2 {
      font-size: ${({ enableYearlyPlan }) =>
        enableYearlyPlan ? '16px' : '14px'};
      font-weight: 400;
      color: ${primitives.color.gray12};
      line-height: 20px;
      font-family: ${primitives.typography.family0};
      margin-top: 8px;
      padding-right: 16px;
      padding-left: 16px;
      word-break: normal;
    }
  }
`;

export const Description = styled.span<PaperCardProps>`
  && {
    font-size: ${({ enableYearlyPlan }) =>
      enableYearlyPlan ? '14px' : '12px'};
    font-weight: 400;
    color: ${primitives.color.gray12};
    line-height: ${({ enableYearlyPlan }) =>
      enableYearlyPlan ? '20px' : '16px'};
    font-family: ${primitives.typography.family0};
    margin-top: 4px;
    padding-right: 16px;
    padding-left: 16px;
    word-break: normal;
  }
`;

export const ButtonContainer = styled.div<PaperCardProps>`
  margin-top: ${({ enableYearlyPlan }) => (enableYearlyPlan ? '' : 'auto')};
  padding: ${({ enableYearlyPlan }) =>
    enableYearlyPlan ? '16px 8px' : '16px 16px 0px 16px'};

  button {
    width: ${({ enableYearlyPlan }) => (enableYearlyPlan ? '113px' : '100%')};
    height: ${({ enableYearlyPlan }) => (enableYearlyPlan ? '36px' : '42px')};
    border-radius: 8px;
    font-size: 16px;
    line-height: 20px;

    span {
      white-space: ${({ enableYearlyPlan }) =>
        enableYearlyPlan ? '' : 'white-space'};
    }
  }

  @media (max-width: ${breakpointsHelper.extra_small_575px}) {
    margin-top: 0;
  }
`;

type ColorContainerProps = {
  program: string;
};

export const ColorContainer = styled.div<ColorContainerProps>`
  && {
    width: 100%;
    height: 16px;
    border-radius: 0px 0px 7px 7px;
    background-color: ${(props) => getColor(props.program)};
  }
`;
export const SectionTitle = styled.div`
  &&& {
    h1 {
      font-size: 32px;
      font-weight: 400;
      color: ${primitives.color.gray12};
      line-height: 40px;
      font-family: ${primitives.typography.family0};
      margin-top: 24px;
      word-break: normal;

      @media (max-width: ${breakpointsHelper.extra_small_575px}) {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
`;

export const SectionDescription = styled.div`
  &&& {
    h2 {
      font-size: 18px;
      font-weight: 400;
      color: ${primitives.color.gray12};
      line-height: 24px;
      font-family: ${primitives.typography.family0};
      margin-top: 4px;
      word-break: normal;
    }
  }
`;
