export class SessionData {
  sessionKey: string

  constructor(sessionKey: string) {
    this.sessionKey = sessionKey
  }

  set(data: unknown) {
    const sortKeys = (_: unknown, value: unknown) =>
      value instanceof Object && !Array.isArray(value)
        ? Object.keys(value)
            .sort()
            .reduce((sorted, key) => {
              sorted[key] = value[key]
              return sorted
            }, {})
        : value
    const sessionData = JSON.stringify(data, sortKeys)
    window.localStorage.setItem(this.sessionKey, sessionData)
  }

  get(): Record<string, unknown> {
    return JSON.parse(window.localStorage.getItem(this.sessionKey) || '{}')
  }
}
