import { AxiosResponse } from 'axios'
import { IInstantInkService } from '../../lib/instantInkService'
import { SessionData } from '../../lib/sessionData'
import {
  PartialSubscription,
  CodeType,
  Plan,
  Printer,
  Subscription,
  Settings,
  Benefits,
  Paths,
  DeeplinkInfo,
  Validations,
  Banner,
  PrinterOffer,
  PrinterBenefits,
  ConnectAndVerifyType,
  AddressResponse
} from '../../types'
import { resolve, getMockFixtureOverride } from './mockHelpers'
import { isInkProgram } from '../../lib/helpers'
import {
  codeTypeFixture,
  pathsFixture,
  benefitsFixture,
  deeplinkInfoFixture,
  printerFixture,
  planFixture,
  paperAddonFixture,
  inkPlansFixture,
  tonerPlansFixture,
  smbTonerPlansFixture,
  partialSubscriptionFixture,
  subscriptionFixture,
  validationsFixture,
  printerOfferFixture,
  settingsFixture,
  bannerFixture,
  printerBenefitsFixture,
  connectAndVerifyFixture,
  replacementBenefitsFixture,
  addressResponseFixture
} from '../fixtures'

const smbPlansFixture = smbTonerPlansFixture()
const v3PlansFixture = (technology?: string) =>
  ({ ink: inkPlansFixture(), laser: tonerPlansFixture() }[
    technology?.toLowerCase()
  ] || [...inkPlansFixture(), ...tonerPlansFixture()])

const plansFixture = ({
  flowName,
  technology
}: {
  flowName: string
  technology?: string
}): Plan[] =>
  ({
    v3_web: v3PlansFixture(technology),
    mfe_oobe_enroll: v3PlansFixture(technology),
    mfe_oobe_connect: v3PlansFixture(technology),
    smb_mfe: smbPlansFixture
  }[flowName] || [])

const printersFixture = ({
  allowOfflineEnroll = false,
  allowPartialEnroll = false
}: {
  allowOfflineEnroll?: boolean
  allowPartialEnroll?: boolean
}) => [
  printerFixture({
    id: 1,
    deviceUuid: 'device-uuid-1',
    displayName: 'HP ENVY Pro 6455e',
    technology: 'Ink'
  }),
  printerFixture({
    id: 2,
    deviceUuid: 'device-uuid-2',
    displayName: 'HP LaserJet M23dwe',
    technology: 'Laser'
  }),
  printerFixture({
    id: 3,
    deviceUuid: 'device-uuid-3',
    displayName: 'printer 3',
    signupState: 'offline',
    isAllowedToEnroll: allowOfflineEnroll
  }),
  printerFixture({
    id: 4,
    deviceUuid: 'device-uuid-4',
    displayName: 'printer 4',
    signupState: 'not_registered',
    isAllowedToEnroll: allowPartialEnroll
  }),
  printerFixture({
    id: 5,
    deviceUuid: 'device-uuid-1',
    displayName: 'printer 5',
    signupState: 'offline',
    isAllowedToEnroll: allowOfflineEnroll
  })
]

export class MockInstantInkService implements IInstantInkService {
  sessionData: SessionData
  defaultDelay: number
  partialSub: PartialSubscription

  private getSessionData<T>(key: string): T {
    return this.sessionData.get()?.[key] as T
  }

  constructor(sessionData: SessionData, defaultDelay = 0) {
    this.sessionData = sessionData
    this.defaultDelay = defaultDelay

    const partialSubOverride = getMockFixtureOverride(
      this.sessionData,
      'partialSubscription'
    )
    const { flowName = 'smb_mfe' } = partialSubOverride
    const { technology, signupState } = getMockFixtureOverride(
      this.sessionData,
      'printer'
    )
    const plan = plansFixture({ flowName, technology })[0]
    const printer = technology
      ? printerFixture({ technology, signupState })
      : undefined
    const addons =
      flowName !== 'smb_mfe' &&
      this.getSessionData<boolean>('planHasPaperAddon')
        ? [paperAddonFixture()]
        : []
    this.partialSub = {
      ...partialSubscriptionFixture({
        flowName,
        plan,
        printer,
        addons
      }),
      ...partialSubOverride
    }
  }

  fetchSettings(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    language?: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    country?: string
  ): Promise<AxiosResponse<Settings>> {
    return resolve(
      {
        data: {
          ...settingsFixture(),
          ...getMockFixtureOverride(this.sessionData, 'settings')
        },
        status: 200,
        statusText: 'ok',
        headers: {},
        config: {}
      },
      this.defaultDelay
    )
  }

  fetchPaths(): Promise<AxiosResponse<Paths>> {
    return resolve(
      {
        data: {
          ...pathsFixture(),
          ...getMockFixtureOverride(this.sessionData, 'paths')
        },
        status: 200,
        statusText: 'ok',
        headers: {},
        config: {}
      },
      this.defaultDelay
    )
  }

  fetchDeeplinkInfo(): Promise<AxiosResponse<DeeplinkInfo>> {
    return resolve(
      {
        data: {
          ...deeplinkInfoFixture(),
          ...getMockFixtureOverride(this.sessionData, 'deeplinkInfo')
        },
        status: 200,
        statusText: 'ok',
        headers: {},
        config: {}
      },
      this.defaultDelay
    )
  }

  createPartialSubscription(
    printerUuid: string,
    printerBizModel: string,
    printerSku: string
  ): Promise<AxiosResponse<PartialSubscription>> {
    // return Promise.reject({
    //   response: { data: { error_id: 'GPS002' }, status: 502 },
    //   data: { code: 'enrollment_error' },
    //   status: 201,
    //   statusText: 'created',
    //   headers: {},
    //   config: {}
    // })
    const partialSubOverride = getMockFixtureOverride(
      this.sessionData,
      'partialSubscription'
    )
    const printer = {
      ...printerFixture({
        deviceUuid: printerUuid,
        sku: printerSku
      }),
      ...getMockFixtureOverride(this.sessionData, 'printer')
    }
    const plan = plansFixture({
      flowName: partialSubOverride?.flowName || 'smb_mfe',
      technology: printer.technology
    })[0]
    this.partialSub = {
      ...partialSubscriptionFixture({ printer, plan }),
      flowName: 'smb_mfe',
      ...partialSubOverride
    }
    return resolve(
      {
        data: this.partialSub,
        status: 201,
        statusText: 'created',
        headers: {},
        config: {}
      },
      this.defaultDelay
    )
  }

  fetchPartialSubscription(): Promise<AxiosResponse<PartialSubscription>> {
    return resolve(
      {
        data: this.partialSub,
        status: 200,
        statusText: 'ok',
        headers: {},
        config: {}
      },
      this.defaultDelay
    )
  }

  updatePartialSubscription(
    id: number,
    params: {
      plan_id?: number
      sign_up_completion_code?: string
      printer_id?: number
      special_offers_code?: string
      paper_opt_in?: boolean
      afu_accepted?: boolean
    }
  ): Promise<AxiosResponse<PartialSubscription>> {
    const settingsOverride = getMockFixtureOverride(
      this.sessionData,
      'settings'
    )
    this.partialSub.plan =
      'plan_id' in params
        ? plansFixture({
            flowName: this.partialSub.flowName,
            technology: this.partialSub.printer?.technology
          }).find(({ id }) => params.plan_id === id)
        : this.partialSub.plan
    this.partialSub.printer =
      'printer_id' in params
        ? printersFixture({
            allowOfflineEnroll:
              settingsOverride['enableWebOfflinePrinterEnrollment'],
            allowPartialEnroll: settingsOverride['enableEnrollBeforeRegister']
          }).find(({ id }) => params.printer_id === id)
        : this.partialSub.printer
    this.partialSub.enrollmentKeyCode =
      'special_offers_code' in params
        ? params.special_offers_code
        : this.partialSub.enrollmentKeyCode
    if (isInkProgram(this.partialSub.plan.program)) {
      this.partialSub.addons = [
        paperAddonFixture({
          optedIn:
            'paper_opt_in' in params
              ? params.paper_opt_in
              : !!this.partialSub.addons[0]?.optedIn
        })
      ]
    } else {
      this.partialSub.addons = []
    }
    if (typeof params.afu_accepted === 'boolean') {
      this.partialSub.afuAccepted = params.afu_accepted
    }
    return resolve(
      {
        data: this.partialSub,
        status: 200,
        statusText: 'ok',
        headers: {},
        config: {}
      },
      this.defaultDelay
    )
  }

  createPreenrollment(): Promise<AxiosResponse> {
    // return Promise.reject({
    //   response: {
    //     data: {
    //       error_code: 'expired_promotion_code',
    //       error_params: ''
    //     },
    //     status: 400
    //   },
    //   headers: {},
    //   config: {}
    // })
    return resolve(
      {
        data: {},
        status: 201,
        statusText: 'created',
        headers: {},
        config: {}
      },
      this.defaultDelay
    )
  }

  fetchPlans(): Promise<AxiosResponse<Plan[]>> {
    const plansOverride = getMockFixtureOverride(this.sessionData, 'plans')
    const plans = Array.isArray(plansOverride)
      ? plansOverride.map((plan) => planFixture(plan))
      : plansFixture({
          flowName: this.partialSub.flowName,
          technology: this.partialSub.printer?.technology
        })
    return resolve(
      {
        data: plans,
        status: 200,
        statusText: 'ok',
        headers: {},
        config: {}
      },
      getMockFixtureOverride(this.sessionData, 'delay') !== undefined
        ? getMockFixtureOverride(this.sessionData, 'delay')
        : this.defaultDelay
    )
  }

  fetchBanner(): Promise<AxiosResponse<Banner>> {
    return resolve(
      {
        data: {
          ...bannerFixture(),
          ...getMockFixtureOverride(this.sessionData, 'banner')
        },
        status: 200,
        statusText: 'ok',
        headers: {},
        config: {}
      },
      getMockFixtureOverride(this.sessionData, 'delay') !== undefined
        ? getMockFixtureOverride(this.sessionData, 'delay')
        : this.defaultDelay
    )
  }

  fetchCodeType(): Promise<AxiosResponse<CodeType>> {
    if (this.getSessionData<string>('specialOffersError')) {
      return Promise.reject({
        response: {
          data: {
            error_code: this.getSessionData<string>('specialOffersError'),
            error_params: ''
          },
          status: 400
        },
        headers: {},
        config: {}
      })
    } else {
      return resolve(
        {
          data: {
            ...codeTypeFixture(),
            ...getMockFixtureOverride(this.sessionData, 'codeType')
          },
          status: 200,
          statusText: 'ok',
          headers: {},
          config: {}
        },
        this.defaultDelay
      )
    }
  }

  fetchBenefits(): Promise<AxiosResponse<Benefits>> {
    const benefitBase = this.getSessionData<unknown>('replacement')
      ? replacementBenefitsFixture()
      : benefitsFixture()

    return resolve(
      {
        data: {
          ...benefitBase,
          ...getMockFixtureOverride(this.sessionData, 'benefits')
        },
        status: 200,
        statusText: 'ok',
        headers: {},
        config: {}
      },
      this.defaultDelay
    )
  }

  fetchPrinters(): Promise<AxiosResponse<Printer[]>> {
    const settingsOverride = getMockFixtureOverride(
      this.sessionData,
      'settings'
    )
    return resolve(
      {
        data: printersFixture({
          allowOfflineEnroll:
            settingsOverride['enableWebOfflinePrinterEnrollment'],
          allowPartialEnroll: settingsOverride['enableEnrollBeforeRegister']
        }),
        status: 200,
        statusText: 'ok',
        headers: {},
        config: {}
      },
      getMockFixtureOverride(this.sessionData, 'delay') !== undefined
        ? getMockFixtureOverride(this.sessionData, 'delay')
        : this.defaultDelay
    )
  }

  fetchPrinterBenefits(
    deviceUuid: string
  ): Promise<AxiosResponse<PrinterBenefits>> {
    const printerBenefitsFixtureData = printerBenefitsFixture()

    return resolve(
      {
        data:
          deviceUuid === printerBenefitsFixtureData.deviceUuid
            ? printerBenefitsFixtureData
            : printerBenefitsFixture({
                deviceUuid: 'some-device-uuid',
                freeTrialMonths: 0,
                prepaidBalanceCents: 0,
                prepaidBalanceFormatted: '$0.00'
              }),
        status: 200,
        statusText: 'ok',
        headers: {},
        config: {}
      },
      this.defaultDelay
    )
  }

  createSubscription(): Promise<AxiosResponse<Subscription>> {
    if (this.getSessionData<boolean>('subscriptionCreationFail')) {
      return Promise.reject({
        response: {
          data: { error_id: this.getSessionData<string>('errorId') }
        },
        data: { code: this.getSessionData<string>('errorCode') },
        status: 400,
        statusText: 'bad request',
        headers: {},
        config: {}
      })
    }

    return resolve(
      {
        data: {
          ...subscriptionFixture(),
          ...getMockFixtureOverride(this.sessionData, 'subscription')
        },
        status: 201,
        statusText: 'created',
        headers: {},
        config: {}
      },
      this.getSessionData<number>('subscriptionCreationLoading') ||
        this.defaultDelay
    )
  }

  convertFlipSubscription(): Promise<AxiosResponse<Subscription>> {
    // return Promise.reject({
    //   response: { data: { error_id: 'GPS002' } },
    //   data: { code: 'enrollment_error' },
    //   status: 201,
    //   statusText: 'created',
    //   headers: {},
    //   config: {}
    // })
    return resolve(
      {
        data: {
          ...subscriptionFixture(),
          ...getMockFixtureOverride(this.sessionData, 'subscription')
        },
        status: 202,
        statusText: 'accepted',
        headers: {},
        config: {}
      },
      this.defaultDelay
    )
  }

  replacePrinter(): Promise<AxiosResponse> {
    if (this.getSessionData<boolean>('printerReplacementFail')) {
      return Promise.reject({
        response: {
          data: { error_id: this.getSessionData<string>('errorId') }
        },
        data: { code: this.getSessionData<string>('errorCode') },
        status: 400,
        statusText: 'bad request',
        headers: {},
        config: {}
      })
    }

    return resolve(
      {
        data: {},
        status: 200,
        statusText: 'ok',
        headers: {},
        config: {}
      },
      this.getSessionData<number>('printerReplacementLoading') ||
        this.defaultDelay
    )
  }

  fetchValidations(): Promise<AxiosResponse<Validations>> {
    return resolve(
      {
        data: {
          ...validationsFixture(),
          ...getMockFixtureOverride(this.sessionData, 'validations')
        },
        status: 200,
        statusText: 'ok',
        headers: {},
        config: {}
      },
      this.defaultDelay
    )
  }

  fetchPrinterOffer(): Promise<AxiosResponse<PrinterOffer>> {
    return resolve(
      {
        data: {
          ...printerOfferFixture(),
          ...getMockFixtureOverride(this.sessionData, 'printerOffer')
        },
        status: 200,
        statusText: 'ok',
        headers: {},
        config: {}
      },
      this.defaultDelay
    )
  }

  connectAndVerify(): Promise<AxiosResponse<ConnectAndVerifyType>> {
    return resolve(
      {
        data: {
          ...connectAndVerifyFixture({
            updatedPartialSubscription: this.partialSub,
            updatedEnrollmentBenefits: {
              ...benefitsFixture(),
              ...getMockFixtureOverride(this.sessionData, 'benefits')
            }
          }),
          ...getMockFixtureOverride(this.sessionData, 'connectAndVerify')
        },
        status: 200,
        statusText: 'ok',
        headers: {},
        config: {}
      },
      this.getSessionData<number>('connectAndVerifyLoading') ||
        this.defaultDelay
    )
  }

  updateEnrollTracker(): Promise<AxiosResponse> {
    return resolve(
      {
        data: {},
        status: 200,
        statusText: 'ok',
        headers: {},
        config: {}
      },
      this.defaultDelay
    )
  }

  fetchPrinterClaimStatus(): Promise<AxiosResponse<string>> {
    return resolve(
      {
        data: this.getSessionData<string>('claimStatus') || 'claimed',
        status: 200,
        statusText: 'ok',
        headers: {},
        config: {}
      },
      this.defaultDelay
    )
  }

  fetchAutofillAddresses(): Promise<AxiosResponse<AddressResponse>> {
    return resolve(
      {
        status: 200,
        statusText: 'ok',
        headers: {},
        config: {},
        data: {
          ...addressResponseFixture()
        }
      },
      this.defaultDelay
    )
  }
}
