import { isNative as isNativeFromJWeb } from '../../services/JWeb';
import { getRepositories, getServices } from '../../infra/commonInitializer';
import InitializeContextServices from './initializeContextsServices';
import WriteCookieDataToValueStore from './writeCookieDataToValueStore';
import SetFirstApplicationToken from './setFirstApplicationToken';
import SetSupportSessionToken from './setSupportSessionToken';
import CleanUpEnvironment from './cleanUpEnvironment';
import UsePostRedirectService from './usePostRedirect';
import ServiceWorkerRules from './ServiceWorkerRules';
import EventsBehaviors from './EventsBehaviors';
import RemoveMaxAgeCookieInLoggedIn from './removeMaxAgeCookieInLoggedIn';
import {
  RefreshWhenNativeAuthStatusChanged,
  initialNavigationStateRepository
} from './RefreshWhenNativeAuthStatusChanged';
import { MultipleTabsDeamon } from './MultipleTabsDeamon';
import SetFirstTenantForNative from './SetFirstTenantForNative';
import { internalLogger } from '../../interface/v1/logger';
import { container } from 'tsyringe';
import { ShellFlagsInputType } from '../../infra/commonInitializer/types';
import ForceReloadAfterUserOnboardingPath from './forceReloadAfterUserOnboardingPath';

export default class BeforeInitBehaviors {
  public static async init(): Promise<void> {
    const isNative = await isNativeFromJWeb();

    const repositories = getRepositories();
    const { lastTenantIdsRepository } = repositories;

    const services = getServices();
    const {
      appContext,
      navigationService,
      sessionService,
      authTokenService,
      supportSessionService,
      tenantHandlerService,
      applicationService,
      stateParamHandlerService,
      serviceWorkerService,
      eventService,
      userOnboardingService
    } = services;

    const removeMaxAgeCookieInLoggedIn = new RemoveMaxAgeCookieInLoggedIn();

    const shellFlagsProps =
      container.resolve<ShellFlagsInputType>('ShellFlagsProps');

    const web_setFirstApplicationToken =
      !isNative &&
      new SetFirstApplicationToken({
        sessionService,
        authTokenService,
        supportSessionService
      });

    const web_setSupportSessionToken =
      !isNative &&
      new SetSupportSessionToken({ supportSessionService, authTokenService });

    const initializeContextServices = new InitializeContextServices({
      appContext
    });

    const web_writeCookieDataToValueStore =
      !isNative && new WriteCookieDataToValueStore();

    const cleanUpEnvironment = new CleanUpEnvironment({
      sessionService
    });

    const usePostRedirectService = new UsePostRedirectService({
      stateParamHandlerService
    });

    const events = new EventsBehaviors({
      eventService,
      navigationService
    });

    const serviceWorkerRules = new ServiceWorkerRules({
      serviceWorkerService,
      sessionService
    });

    const native_setFirstTenantForNative =
      isNative &&
      new SetFirstTenantForNative({
        authTokenService,
        tenantHandlerService,
        applicationService
      });

    const native_refreshWhenNativeAuthStatusChanged =
      isNative &&
      shellFlagsProps?.nativeRefreshListener &&
      new RefreshWhenNativeAuthStatusChanged({
        eventService,
        navigationService,
        initialNavigationStateRepository
      });

    //TODO: Currently, the internalLogger is not working here. It should be investigated.
    /* istanbul ignore next */
    console.debug?.(
      `Manifest flag "nativeRefreshListener" status is ${
        shellFlagsProps?.nativeRefreshListener
      }, RefreshWhenNativeAuthStatusChanged is ${
        native_refreshWhenNativeAuthStatusChanged
          ? 'instantiated'
          : 'not instantiated'
      } `
    );

    // A provisory place to the deamon for multitab
    // This also responsible to SetTenant for the first time (On Native)
    const multipleTabsDeamon = new MultipleTabsDeamon({
      repository: lastTenantIdsRepository,
      isNative: isNative
    });

    const forceReloadAfterUserOnboardingPath =
      new ForceReloadAfterUserOnboardingPath({
        navigation: navigationService,
        userOnboardingService
      });

    /**
     *  Execution phase
     */
    internalLogger?.debug?.('Starting ClientOS Behaviors');

    // 1. Cleaners
    removeMaxAgeCookieInLoggedIn?.init?.();
    cleanUpEnvironment?.init?.();

    // 2. Setters
    multipleTabsDeamon.init();

    forceReloadAfterUserOnboardingPath.init();
    await initializeContextServices?.init?.();
    await web_setFirstApplicationToken?.init?.();
    await web_setSupportSessionToken?.init?.();
    await web_writeCookieDataToValueStore?.init?.();
    await native_setFirstTenantForNative?.init?.();
    usePostRedirectService.init?.();
    serviceWorkerRules?.init?.();
    events?.init?.();
    await native_refreshWhenNativeAuthStatusChanged?.init?.();
    internalLogger?.debug?.('ClientOS Behavior initialized.');
  }
}
