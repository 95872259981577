import React, { useContext } from 'react'
import useContentStack from '@/hooks/useContentStack'
import { CONTENT_STACK_TYPES, MULTI_FUNCTION_PRINTER, ANALYTICS } from '@/store/Constants'
import { PrinterContext } from '@/store'
import { useNextStage } from '@/hooks/useNextStage'
import useAnalytics from '@/hooks/useAnalytics'

const { BUTTONS, SCREENS } = ANALYTICS

export function ExitHpCloudConnection({ children, setVisibility, onClose }) {
  const { printerFunctionType } = useContext(PrinterContext)
  const { dispatchDeclinedStage } = useNextStage()

  const { fireButtonClick, fireModalWindowDisplayed } = useAnalytics(
    SCREENS.PRINTER_SETUP_COMPLETE
  )

  const { pageData } = useContentStack({
    content_type:
      printerFunctionType === MULTI_FUNCTION_PRINTER
        ? CONTENT_STACK_TYPES.exit_hp_cloud_connection_mfp
        : CONTENT_STACK_TYPES.exit_hp_cloud_connection_sfp
  })

  fireModalWindowDisplayed()

  const closeClickHandler = () => {
    onClose()
    fireButtonClick(BUTTONS.CLOSE)
    setVisibility(false)
  }
  const exitWithoutConnecting = () => {
    fireButtonClick(BUTTONS.CONTINUE)
    dispatchDeclinedStage()
  }

  return (
    <>
      {children({
        data: pageData,
        buttonClicks: [exitWithoutConnecting],
        onClose: closeClickHandler,
        alignment: {
          content: 'center'
        }
      })}
    </>
  )
}
