import React, { FC, useRef } from 'react'
import { FlowType, SignupProps } from '../../types'
import { SignupProvider } from './signupProvider'
import { FlowManagerRoutes } from '../../flows/shared/FlowManagerRoutes'
import { AbTesting } from '../../flows/shared/AbTesting'
import { SessionData } from '../../lib/sessionData'

const getSessionData = (flow?: string) => {
  const flowSessionKey = `InstantInkSignupSession/${flow || FlowType.SMB}`
  const oldSessionKey = 'InstantInkSignupSession'
  const oldSessionData = localStorage.getItem(oldSessionKey)
  if (oldSessionData) {
    console.warn('Converting old session key to flow session key')
    localStorage.setItem(flowSessionKey, oldSessionData)
    localStorage.removeItem(oldSessionKey)
  }
  return new SessionData(flowSessionKey)
}

export const Signup: FC<SignupProps> = (props) => {
  const sessionData = useRef(getSessionData(props.properties?.flow)).current
  return (
    <SignupProvider signupProps={props} sessionData={sessionData}>
      <AbTesting />
      <FlowManagerRoutes />
    </SignupProvider>
  )
}
