import styled from 'styled-components';

export const formContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
`;

export const container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: left;
`;

export const containerSelect = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: left;
  width: 100%;
`;

export const selectFacingContainer = styled.div`
  display: flex;
  width: 40%;
  gap: 10px;
`;

export const selectSizeContainer = styled.div`
  display: flex;
  width: 40%;
  gap: 10px;
`;

export const textConatiner = styled.div`
  display: flex;
  width: 40%;
`;

export const buttonContainer = styled.div`
  display: flex;
`;

export const imageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const customImageContainer = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 20px;
`;

export const containerResult = styled.div`
  display: flex;
  min-height: 250px;
`;

export const buttonActionContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const buttonAction = styled.div`
  display: flex;
  width: '40%';
  margin-top: 10px;
`;

export const documentContainer = styled.div`
  display: flex;
  width: 'auto';
  align-items: start;
`;
