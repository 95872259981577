import React, { useEffect, useMemo, useReducer } from 'react';
import { YearlyPlanSelectorContext } from './context/yearlyPlanSelectorContext';
import {
  YearlyPlanSelectorInitialState,
  yearlyPlanSelectorReducer
} from './reducers/yearlyPlanSelectorReducer';
import { setAssetsProviderAction } from '../common/actions/setAssetsProviderAction';
import { YearlyPlanSelectorContent } from './components/YearlyPlanSelectorContent';
import { YearlyPlanSelectorProps } from './types/yearlyPlanSelectorProps';
import {
  jarvisPlansReducer,
  jarvisPlansInitialState
} from '../common/reducers/jarvisPlansReducer';
import { JarvisPlansContext } from '../common/context/jarvisPlanContext';
import { setYearlyPlans } from './actions/setYearlyPlans';
import { setCorrespondingYearlyPlan } from './actions/setCorrespondingYearlyPlan';
import { setShowModalAction } from './actions/setShowModalAction';
import { setSelectedYearlyPlanAction } from './actions/setSelectedYearlyPlanAction';
import { setCreditCardAction } from './actions/setCreditCard';
import { setPaymentTypeAction } from './actions/setPaymentType';
import { setDisableCvvForYearlyPlansAction } from './actions/setDisableCvvForYearlyPlans';

export const YearlyPlanSelector: React.FC<YearlyPlanSelectorProps> = (
  props
) => {
  const [jarvisPlansState, jarvisDispatch] = useReducer(
    jarvisPlansReducer,
    jarvisPlansInitialState(props)
  );

  const [yearlyPlanSelectorState, dispatch] = useReducer(
    yearlyPlanSelectorReducer,
    YearlyPlanSelectorInitialState(props)
  );

  useEffect(() => {
    jarvisDispatch(setAssetsProviderAction(props.language, props.country));
  }, [props.language, props.country]);

  const contextValue = useMemo(
    () => ({ yearlyPlanSelectorState, dispatch }),
    [yearlyPlanSelectorState, dispatch]
  );

  const jarvisContextValue = useMemo(
    () => ({ jarvisPlansState, dispatch: jarvisDispatch }),
    [jarvisPlansState, jarvisDispatch]
  );

  useEffect(() => {
    dispatch(setYearlyPlans(props.yearlyPlans));
  }, [props.yearlyPlans]);

  useEffect(() => {
    dispatch(setCorrespondingYearlyPlan(props.correspondingYearlyPlan));
    dispatch(setSelectedYearlyPlanAction(props.correspondingYearlyPlan));
  }, [props.correspondingYearlyPlan]);

  useEffect(() => {
    dispatch(setShowModalAction(props.showModal));
  }, [props.showModal]);

  useEffect(() => {
    dispatch(setCreditCardAction(props.creditCard));
  }, [props.creditCard]);

  useEffect(() => {
    dispatch(setPaymentTypeAction(props.paymentType));
  }, [props.paymentType]);

  useEffect(() => {
    dispatch(
      setDisableCvvForYearlyPlansAction(props.disableCvvFormForYearlyPlan)
    );
  }, [props.disableCvvFormForYearlyPlan]);

  return (
    <JarvisPlansContext.Provider value={jarvisContextValue}>
      <YearlyPlanSelectorContext.Provider value={contextValue}>
        <YearlyPlanSelectorContent data-analyticsid="ii_plans_selector-content" />
      </YearlyPlanSelectorContext.Provider>
    </JarvisPlansContext.Provider>
  );
};

export default YearlyPlanSelector;
