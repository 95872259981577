import React from 'react'
import Button from '@veneer/core/dist/scripts/button'
import { AddressCard } from './AddressCard/AddressCard'
import IconMinusCircle from '@veneer/core/dist/scripts/icons/icon_minus_circle'
import { useState } from 'react'
import {
  useDispatch,
  useGetText,
  useShippingAddresses,
  useShippingData,
  useShippingFormState,
  useSaveText
} from '../../hooks'
import { ShippingFormData } from '../../types'
import {
  handleAssociateAddressWithPrinter,
  saveFormErrorCodeAction,
  saveSelectedAddressAction
} from '../../actions'
import { Spinner } from '../Spinner'
import { isMobile } from '../../lib/screenHelpers'
import { useTheme } from 'styled-components'
import * as Styled from './styles'

export const SelectAddress = ({
  setNewAddressView
}: SelectAddressProps): JSX.Element => {
  const getText = useGetText()
  const { containerSize } = useTheme()
  const saveText = useSaveText()
  const dispatch = useDispatch()
  const shippingAddresses = useShippingAddresses()
  const currentAddress = useShippingData()
  const {
    selectedAddress,
    shippingAddressesLoading,
    formErrorCode,
    addressCount,
    isHpx
  } = useShippingFormState()
  const [saving, setSaving] = useState(false)

  if (shippingAddressesLoading) {
    return <Spinner />
  }

  if (shippingAddresses === undefined) {
    return null
  }

  const defaultAddressId = shippingAddresses.find(
    (address: ShippingFormData) => address.isDefaultAddress
  )?.id
  const disabledSave =
    selectedAddress.id === currentAddress.id && addressCount > 1
  const onSelectAddress = async (address: ShippingFormData) =>
    await dispatch(saveSelectedAddressAction(address))
  const onAddNewAddress = async () => {
    setNewAddressView(true)
    await dispatch(saveFormErrorCodeAction(undefined))
  }
  const onSave = async (address: ShippingFormData) => {
    if (!saving) {
      setSaving(true)
      try {
        await dispatch(handleAssociateAddressWithPrinter(address))
      } finally {
        setSaving(false)
      }
    }
  }

  const renderErrorMessage = () => {
    let message = <></>
    const errorColor = isHpx ? '#FF2A3F' : 'red7'
    if (formErrorCode) {
      message = (
        <Styled.ErrorMessage $isHpx={isHpx}>
          <IconMinusCircle size={24} color={errorColor} filled />
          {getText('shipping_form.generic_error_handler.something_went_wrong')}
        </Styled.ErrorMessage>
      )
    }

    return message
  }

  return (
    <>
      <h4>{getText('shipping_form.title.select_address')}</h4>
      <div>
        {shippingAddresses.map((address: ShippingFormData, index: number) => (
          <AddressCard
            key={index}
            defaultAddressId={defaultAddressId}
            address={address}
            selectedAddressId={selectedAddress.id}
            onSelectAddress={() => onSelectAddress(address)}
            isMobile={isMobile(containerSize)}
          />
        ))}
        <Styled.ButtonsContainer>
          {renderErrorMessage()}
          <Styled.AddButton
            appearance="secondary"
            onClick={() => onAddNewAddress()}
          >
            {getText('shipping_form.select_address.add_new_address')}
          </Styled.AddButton>
          <Button
            disabled={disabledSave}
            loading={saving}
            onClick={() => onSave(selectedAddress)}
          >
            {saveText}
          </Button>
        </Styled.ButtonsContainer>
      </div>
    </>
  )
}

type SelectAddressProps = {
  setNewAddressView: (value: boolean) => void
}
