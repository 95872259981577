import React, { useCallback } from 'react';
import parse, { domToReact } from 'html-react-parser';
import PropTypes from 'prop-types';
import { HyperlinkText, usePreferencesContext } from '@jarvis/react-portal-addons';
import {
  Container,
  DeviceSection,
  DeviceSubtitle,
  Title,
} from './styles';

const SideMenuContent = props => {
  const {
    title, text, textsForSpecificEnvironments, analyticsId,
  } = props;
  const preferences = usePreferencesContext();

  let linkIdCounter = 0;

  const generateLinkDataTestId = useCallback(linkId => {
    const sanitizedText = analyticsId.replace(/[^a-zA-Z0-9\s]/g, '');
    const idPrefix = sanitizedText.trim().replace(/\s+/g, '-').toLowerCase();
    return `${idPrefix}-link-${linkId}`;
  }, [analyticsId]);

  const replacer = ({
    attribs,
    children,
    name,
    onClick,
  }) => {
    if (name === 'a') {
      linkIdCounter += 1;

      return (
        <HyperlinkText
          {...attribs}
          url={attribs.href}
          onClick={onClick}
          data-testid={generateLinkDataTestId(linkIdCounter)}
        >
          {domToReact(children)}
        </HyperlinkText>
      );
    }

    return null;
  };

  const replaceHTMLTag = useCallback(pureText => {
    const htmlTagReplacementList = preferences?.content?.htmlTagReplacementList;

    if (pureText && htmlTagReplacementList && htmlTagReplacementList.length) {
      return htmlTagReplacementList.reduce(
        (replacedText, currentReplacementRule) => replacedText.replaceAll(
          currentReplacementRule.stringRegex,
          currentReplacementRule.stringReplacement,
        ),
        pureText,
      );
    }

    return '';
  }, [preferences?.content?.htmlTagReplacementList]);

  const parseText = unparsedText => parse(unparsedText, {
    replace: domNode => replacer({
      ...domNode,
    }),
  });

  const generateText = pureText => {
    const replacedText = replaceHTMLTag(pureText);
    return parseText(replacedText);
  };

  return (
    <Container
      className="body"
      id="sideMenuContent"
    >
      <Title className="title-small" data-testid="sidenavcontent-title">
        { title }
      </Title>

      {
        text
          ? generateText(text)
          : textsForSpecificEnvironments.map(deviceTexts => (
            <DeviceSection key={deviceTexts.text}>
              <DeviceSubtitle
                className="subtitle-large"
                data-testid="sidenavcontent-device-subtitle"
              >
                {deviceTexts.title}
              </DeviceSubtitle>
              {generateText(deviceTexts.text)}
            </DeviceSection>
          ))
      }
    </Container>
  );
};

SideMenuContent.defaultProps = {
  analyticsId: '',
  text: '',
  title: '',
  textsForSpecificEnvironments: [],
};

SideMenuContent.propTypes = {
  analyticsId: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  textsForSpecificEnvironments: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
  })),
};

export default SideMenuContent;
